import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from '../../../context/UserProvider';
import Filtro from '../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
import { jsPDF } from "jspdf";

const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [acumulados, setacumulados] = useState("-");

    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [tercero, settercero] = useState(false);
    const [vendedor, setvendedor] = useState(false);
    const [caja, setcaja] = useState(false);
    const [formapago, setformapago] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventasterceros, setventasterceros] = useState(modeloInicio)
    const [ventasvendedor, setventasvendedor] = useState(modeloInicio)
    const [ventascaja, setventascaja] = useState(modeloInicio)
    const [ventasformapago, setventasformapago] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [vendedorinicioseleccionado, setvendedorinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [cajainicioseleccionada, setcajainicioseleccionada] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [vendedorfinseleccionado, setvendedorfinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [cajafinseleccionada, setcajafinseleccionada] = useState("-");
    const [sucursal, setsucursal] = useState("-");
    const [mediosdepago, setmediosdepago] = useState(modeloInicio)
    const [usuario, setDataUser] = useState(modeloInicio)
    const [previsualizar, setprevisualizar] = useState(false);

    const [columns, setcolumns] = useState([
        {
            name: 'Concepto',
            selector: row => row.concepto,
            sortable: true,
        },
        {
            name: 'Docu',
            selector: row => row.ndocumento,
            sortable: true,
        },
        {
            name: 'Fecha',
            selector: row => row.fecha,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Vend',
            selector: row => row.vendedor,
            sortable: true,
            grow: 4,
            cell: row => <div>
                { }
                <div>
                    <div
                    >
                        { }
                        {row.vendedor}
                    </div>
                </div>
            </div>,
        },
        {
            name: 'Cliente',
            selector: row => row.cliente,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Nombre',
            selector: row => row.nombrecli,
            sortable: true,
            grow: 4,
            cell: row => <div>
                { }
                <div>
                    <div
                    >
                        { }
                        {row.nombrecli}
                    </div>
                </div>
            </div>,
        }
    ])

    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        buscarmediosdepago();
    }, [])

    const buscarmediosdepago = async () => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Mediodepagoapi/?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var nuevascolumnas = columns.map((data2) => {
                    return data2
                })
                var newColumns = nuevascolumnas;
                data.map((data2) => {
                    var newColumn = {
                        name: data2.nombre,
                        selector: row => data2.nombre == row.formapago ? fm3.from(Number(row.total + "")) : 0,
                        sortable: true,
                        grow: 3,

                    };
                    newColumns.push(newColumn);
                })
                setcolumns(newColumns);


                setmediosdepago(data)
            }
        } catch (error) {
            console.log(error)
        }

    }
    const previsualizarpdf = () => {
        // a0 - a10
        // b0 - b10
        // c0 - c10
        // dl
        // letter
        // government-letter
        // legal
        // junior-legal
        // ledger
        // tabloid
        // credit-card
        const doc = new jsPDF('p', 'pt', 'letter');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        doc.html(document.querySelector("#pruebapdf"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            }
        })
    }
    const buscar = () => {

        setPendiente(true)
        // let options = { year: 'numeric', month: '2-digit', day: '2-digit' };

        // let _fechaInicio = fechaInicio.toLocaleDateString('es-PE', options)
        // let _fechaFin = fechaFin.toLocaleDateString('es-PE', options)
        let dt = JSON.parse(user)
        var coincidencia = ''
        const api = fetch(props.servidor + "/MovimientoApi/MovimientosAfecCaja/" + fechaInicio + "/" + fechaFin + "/" + tipodocumentoinicioseleccionado + "/" + tipodocumentofinseleccionado + "/" + terceroinicioseleccionado + "/" + tercerofinseleccionado + "/" + vendedorinicioseleccionado + "/" + vendedorfinseleccionado + "/" + cajainicioseleccionada + "/" + cajafinseleccionada + '/' + sucursal + '?llave=' + dt.usuario.llaveempresa + '&acumulados=' + acumulados)
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then((dataJson) => {
                var data = dataJson;
                setPendiente(false)
                if (data.length < 1) {
                    Swal.fire(
                        'Opps!',
                        'No se encontraron resultados',
                        'warning'
                    )
                }
                setVentas(data.movimientos);

                // var conceptos = data.reduce((array, venta) => {
                //     if (!array[venta.concepto]) {
                //         array[venta.concepto] = 0;
                //     }
                //     array[venta.concepto] += venta.total;
                //     return array;
                // }, {});

                // const conceptosarray = Object.entries(conceptos).map(([concepto, total]) => ({
                //     concepto,
                //     total,
                // }));
                setventasconceptos(data.concepto)
                setventasterceros(data.terceros)
                setventasvendedor(data.vendedor)
                setventascaja(data.caja)
                setventasformapago(data.formapago)
            }).catch((error) => {
                setVentas([]);
                Swal.fire(
                    'Opps!',
                    'No se pudo encontrar información',
                    'error'
                )
            })
    }


    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const exportarExcel = () => {
        var wb = XLSX.utils.book_new();
        var ws = XLSX.utils.json_to_sheet(ventas);

        XLSX.utils.book_append_sheet(wb, ws, "Reporte");
        XLSX.writeFile(wb, "Reporte Existencia Productos.xlsx")
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + element.total
        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label >Act. Acumulados</Label>
                        <Input type="select" onChange={(e) => {
                            setacumulados(e.target.value)
                        }} name="actuacu" value={acumulados}  >
                            <option value="-"></option>
                            <option value="ventas">Ventas</option>
                            <option value="compras">Compras</option>
                            <option value="entradasespeciales">Entradas Especiales</option>
                            <option value="salidasespeciales">Salidas Especiales</option>
                        </Input>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button style={{ backgroundColor: 'darkmagenta' }} size="sm" block onClick={() => {
                            setfiltros(true)
                        }}>
                            <i className="fa fa-search" aria-hidden="true"></i> Filtros
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={exportarExcel}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Exportar
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="warning" size="sm" block onClick={() => { setprevisualizar(true) }}>
                            <i className="fa fa-file-pdf" aria-hidden="true"></i> PDF
                        </Button>
                    </FormGroup>
                </Col>

            </Row>
            <Row  >
                <Col xs={2} onClick={() => {
                    setconcepto(!concepto)
                    setvendedor(false)
                    settercero(false)
                    setcaja(false)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Concepto
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(false)
                    settercero(!tercero)
                    setcaja(false)
                }}>
                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Cliente
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(!vendedor)
                    settercero(false)
                    setcaja(false)
                }}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Vendedor
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(false)
                    settercero(false)
                    setcaja(!caja)
                }}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Caja
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2} onClick={() => {
                    setconcepto(false)
                    setvendedor(false)
                    settercero(false)
                    setcaja(false)
                    setformapago(!formapago)
                }}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#1e88e5', fontSize: '0.6rem', width: '7rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-8">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Forma Pago
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold" style={{ color: '#FFFFFF' }}></div>
                                </div>
                                <div className="col-4 text-right">
                                    <i className="fa fa-th-list" style={{ color: '#FFFFFF', fontSize: '1rem' }}></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={2}>

                    <div className="card border-left-success shadow" style={{ backgroundColor: '#8bc34a', fontSize: '0.6rem' }}>
                        <div className="card-body p-2">
                            <div className="row no-gutters align-items-center">
                                <div className="col-12">
                                    <div className="text-xs font-weight-bold text-uppercase" style={{ color: '#FFFFFF', fontSize: '0.6rem' }}>
                                        Movimientos Totales
                                    </div>
                                    <div className="h5 mb-0 font-weight-bold " style={{ color: '#FFFFFF' }}>{fm3.from(totaltotal())}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>

            </Row>
            {concepto &&
                ventasconceptos.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Concepto</p>
                            </Col>
                            <Col xs={2}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={2}>
                                <div className="card border-left-success shadow">
                                    <div className="card-body  p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.concepto}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={4} >

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-dollar-sign " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>
                })}
            {tercero &&
                ventasterceros.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={3}>
                                <p style={{ color: 'black' }}>Nit</p>
                            </Col>
                            <Col xs={5}>
                                <p style={{ color: 'black' }}> Razon Social</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}> Total</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={3}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.cliente}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={5}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold" style={{ color: 'black' }}>{item.nombrecli}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>
                            <Col xs={4}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>


                        </Row>
                    </>

                })}
            {vendedor &&
                ventasvendedor.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Vendedor</p>
                            </Col>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Total</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.vendedor == "" ? "Sin Vendedor" : item.vendedor}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                            <Col xs={4}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </>

                })}
            {caja &&
                ventascaja.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Caja</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.caja == "" ? "Sin Caja" : item.caja}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                            <Col xs={4}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </>

                })}
            {formapago &&
                ventasformapago.map((item, index) => {
                    return <>
                        {index == 0 && <Row>
                            <Col xs={4}>
                                <p style={{ color: 'black' }}>Forma Pago</p>
                            </Col>
                        </Row>}
                        <Row >
                            <Col xs={4}>
                                <div className="card border-left-info shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col-auto">
                                                        <div className="h6 mb-0 mr-3 font-weight-bold " style={{ color: 'black' }}>{item.formapago == "" ? "Sin Forma Pago" : item.formapago}</div>
                                                    </div>
                                                    <div className="col">

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-clipboard-list " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Col>

                            <Col xs={4}>

                                <div className="card border-left-primary shadow ">
                                    <div className="card-body p-2">
                                        <div className="row no-gutters align-items-center">
                                            <div className="col mr-2">
                                                <div className="h6 mb-0 font-weight-bold " style={{ color: 'black' }}>{fm.from(item.total)}</div>
                                            </div>
                                            <div className="col-auto">
                                                <i className="fas fa-shopping-basket  " style={{ color: 'black' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </>

                })}
            <Row>
                <Col sm={12}>
                    <Card>

                        <CardBody>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Filtro
                    settipodocumentoinicioseleccionado={(e) => { settipodocumentoinicioseleccionado(e) }}
                    settipodocumentofinseleccionado={(e) => { settipodocumentofinseleccionado(e) }}
                    setterceroinicioseleccionado={(e) => { setterceroinicioseleccionado(e) }}
                    settercerofinseleccionado={(e) => { settercerofinseleccionado(e) }}
                    setvendedorinicioseleccionado={(e) => { setvendedorinicioseleccionado(e) }}
                    setvendedorfinseleccionado={(e) => { setvendedorfinseleccionado(e) }}
                    setcajainicioseleccionada={(e) => { setcajainicioseleccionada(e) }}
                    setcajafinseleccionada={(e) => { setcajafinseleccionada(e) }}
                    sucursal={sucursal}
                    setsucursal={(e) => { setsucursal(e) }}
                    tipodocumentoinicioseleccionado={tipodocumentoinicioseleccionado}
                    tipodocumentofinseleccionado={tipodocumentofinseleccionado}
                    terceroinicioseleccionado={terceroinicioseleccionado}
                    tercerofinseleccionado={tercerofinseleccionado}
                    vendedorinicioseleccionado={vendedorinicioseleccionado}
                    vendedorfinseleccionado={vendedorfinseleccionado}
                    cajainicioseleccionada={cajainicioseleccionada}
                    cajafinseleccionada={cajafinseleccionada}
                    servidor={props.servidor} verModal={filtros} cerrarModal={() => { setfiltros(false) }} />

                <Modal size="lg" isOpen={previsualizar}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdf"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        Cuadre Dia<br />
                                        ---------------------<br />
                                        {"Fecha: " + fechahoy}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (ventasformapago.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            ventasformapago.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td >{item.formapago}</td>
                                                                    <td style={{ textAlign: 'right', color: 'black' }}>{fm.from(item.total)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                                <tr style={{ fontSize: '10px', color: 'black' }} key={9999}>

                                                    <td>{"Total"}</td>
                                                    <td style={{ textAlign: 'right', color: 'black' }}>{fm.from(totaltotal())}</td>



                                                </tr>
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        Iva Discriminado<br />
                                    </Col>

                                </Row> */}
                                {/* <Row>
                                    <Col sm={12}>
                                        <Table size="sm" style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                            <thead>
                                                <tr>
                                                    <th>%IVA</th>
                                                    <th>BASE</th>
                                                    <th>IVA</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (movimientoscajaiva.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Iva</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            movimientoscajaiva.map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.porcentaje}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.porcentaje)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.baseiva)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.iva)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.total)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        Firma Cajero:__________________<br />
                                        C.C:__________________<br />
                                        Firma Supervisor<br />
                                        C.C:__________________<br />
                                        ---------------------<br />
                                        Fin Cuadre<br />

                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            </Row>


        </>
    )
}

export default ReporteVenta;