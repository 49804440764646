import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import Tabs from 'react-bootstrap/Tabs';
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab';
import Buscar from '../componentes/Buscar';

const modeloDivision = {
    codigo: "",
    nombre: "",
};

const Division = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Division, setDivision] = useState(modeloDivision);
    const [Divisiones, setDivisiones] = useState([]);
    const [usuario, setDataUser] = useState([]);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [pendiente, setPendiente] = useState(true);
    const [verModalDivision, setVerModalDivision] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value;
        var _Division = { ...Division };
        _Division[e.target.name] = value;
        setDivision(_Division);
    }

    const obtenerDivisiones = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/DivisionesApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setDivisiones(data);
                setPendiente(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        let dt = JSON.parse(user);
        setDataUser(dt);
        obtenerDivisiones('');
    }, []);

    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button color="primary" size="sm" className="mr-2"
                    onClick={() => abrirEditarModalDivision(row)}
                >
                    <i className="fas fa-pen-alt"></i>
                </Button>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModalDivision = (data) => {
        var enviodata = { ...data };
        var _Division = {
            codigo: enviodata.codigo,
            nombre: enviodata.nombre,
        };
        setDivision(_Division);
        setVerModalDivision(!verModalDivision);
        seteditar(true);
    }

    const cerrarModalDivision = () => {
        setDivision(modeloDivision);
        setVerModalDivision(!verModalDivision);
        seteditar(false);
        setKey('Datos');
    }

    const guardarCambios = async () => {
        let response;
        if (!editar) {
            response = await fetch("" + props.servidor + "/DivisionesApi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Division)
            });
        } else {
            response = await fetch("" + props.servidor + "/DivisionesApi?llave=" + usuario.usuario.llaveempresa + "", {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(Division)
            });
        }

        if (response.ok) {
            await obtenerDivisiones('');
            setDivision(modeloDivision);
            setVerModalDivision(!verModalDivision);
            setKey('Datos');
            Swal.fire(
                'Guardado Exitosamente!',
                'La División fue guardada exitosamente',
                'success'
            );
        } else {
            var textoerrores = await response.text();
            var jsonerrores = JSON.parse(textoerrores);
            var errores = jsonerrores.errors;
            let claves = Object.keys(errores);
            var stringerrores = "";
            for (let i = 0; i < claves.length; i++) {
                let clave = claves[i];
                if (errores[clave].length != 0) {
                    stringerrores = stringerrores + errores[clave][0] + " \n ";
                }
            }
            var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido');
            if (mensaje.includes('not be converted to System.Decimal')) {
                mensaje = 'Hay campos numéricos rellenados con letras';
            }
            Swal.fire(
                'Error al Guardar!',
                mensaje,
                'error'
            );
        }
    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Divisiones
                </CardHeader>
                <CardBody>
                    <div className="col-sm-6">
                        <div className="input-group input-group-sm mb-3">
                            <div className="input-group-prepend">
                                <input type="text" id="buscardataDivisiones" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerDivisiones(document.getElementById('buscardataDivisiones').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalDivision(!verModalDivision);
                                seteditar(false);
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setCrearDivision(false) }}>
                                    <i className="fa fa-times"></i>
                                </Button>
                            </Col>
                        </div>
                    </div>
                    <hr />
                    <DataTable
                        columns={columns}
                        data={Divisiones}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalDivision}>
                <ModalHeader>
                    División
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Codigo</Label>
                                <Input disabled={editar} type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Division.codigo} />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Nombre</Label>
                                <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Division.nombre} />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalDivision}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Division;
