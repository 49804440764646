import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloFatipodoc = {
    codigo: "",
    descripcion: "",
    tipodoc: "factura",
    tdpro: "",
    trasauto: "",
    tipoprod: "",
    actuacu: "",
    comactacu: "",
    rentab: "",
    afecinv: "",
    serial: "",
    descuentoc: "",
    actcosto: "",
    numitem: "",
    despiece: "",
    exportacion: "",
    afecar: "",
    rcauto: "",
    causadoc: "",
    afecaj: "",
    formapago: "",
    afeban: "",
    docban: "",
    muesdescbanc: "",
    asicont: "",
    consuni: "",
    conscom: "0",
    precsug: "",
    descsug: "",
    cupocred: "",
    permexisne: "",
    conpunreor: "",
    facdebcos: "",
    camprecven: "",
    camdesc: "",
    cambioiva: "",
    cambioimp: "",
    confacven: "",
    consecfac: "",
    camdescenc: "",
    camdescpp: "",
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
    pass5: "",
    pass6: "",
    pass7: "",
    pass8: "",
    pass9: "",
    pass10: "",
    lotes: "",
    ivaincluido: "",
    busplu: "",
    ccosto: "",
    centrocosto: "",
    tcostofijo: "",
    comprob: "",
    impto: "",
    tbodegasn: "",
    tbodega: "",
    tbodegafija: "",
    descsn: "",
    descporc: "",
    ivasn: "",
    ivaporc: "",
    rete: "",
    ica: "",
    reteiva: "",
    imptosn: "",
    imptoporc: "",
    captionimpto: "",
    enviacorreo: "",
    ctrlmov: "",
    docauto: "",
    tdpro1: "",
    tdpro2: "",
    filtroart: "",
    facturapap: "pos",
    mvendedor: "",
    captionvend: "",
    docu1: "",
    captiondoc1: "",
    val_doc1: "",
    docu2: "",
    captiondoc2: "",
    observa: "",
    obslinea: "",
    mdevolucio: "",
    mediospago: "",
    puntoventa: "",
    modidoc: "",
    modidocenc: "",
    noimpdupl: "",
    rentabilid: "",
    redondeofac: "",//esto es Sumar al Costo Impo/ICUA
    num_res: "",
    rango_res_i: "",
    rango_res_f: "",
    inicresolu: "",
    vencresolu: "",
    vencresoludias: "",
    prefijo: "",
    plantilladoc: "",
    allinformes: "",
    clave_tec: "",
    ambiente: "2",
    identificadorsw: "",
    pin: "",
    setpruebas: "",
    transfer: "si",
    manejatercerounico: "",
    tercerounico: "",
    centrodecostoobligatorio: "",
    facdebcos2: "Pago"//esto es para el tema de conciliacion bancaria
}
const Fatipodoc = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Fatipodoc, setFatipodoc] = useState(modeloFatipodoc);
    const [Fatipodocs, setFatipodocs] = useState([]);
    const [codigo, setcodigo] = useState([]);
    const [codigos, setcodigos] = useState([]);
    const [descripcion, setdescripcion] = useState([]);
    const [descripcions, setdescripcions] = useState([]);
    const [tipodoc, settipodoc] = useState([]);
    const [tipodocs, settipodocs] = useState([]);
    const [tdpro, settdpro] = useState([]);
    const [tdpros, settdpros] = useState([]);
    const [trasauto, settrasauto] = useState([]);
    const [trasautos, settrasautos] = useState([]);
    const [tipoprod, settipoprod] = useState([]);
    const [tipoprods, settipoprods] = useState([]);
    const [actuacu, setactuacu] = useState([]);
    const [actuacus, setactuacus] = useState([]);
    const [comactacu, setcomactacu] = useState([]);
    const [comactacus, setcomactacus] = useState([]);
    const [rentab, setrentab] = useState([]);
    const [rentabs, setrentabs] = useState([]);
    const [afecinv, setafecinv] = useState([]);
    const [afecinvs, setafecinvs] = useState([]);
    const [serial, setserial] = useState([]);
    const [serials, setserials] = useState([]);
    const [descuentoc, setdescuentoc] = useState([]);
    const [descuentocs, setdescuentocs] = useState([]);
    const [actcosto, setactcosto] = useState([]);
    const [actcostos, setactcostos] = useState([]);
    const [numitem, setnumitem] = useState([]);
    const [numitems, setnumitems] = useState([]);
    const [despiece, setdespiece] = useState([]);
    const [despieces, setdespieces] = useState([]);
    const [exportacion, setexportacion] = useState([]);
    const [exportacions, setexportacions] = useState([]);
    const [afecar, setafecar] = useState([]);
    const [afecars, setafecars] = useState([]);
    const [rcauto, setrcauto] = useState([]);
    const [rcautos, setrcautos] = useState([]);
    const [causadoc, setcausadoc] = useState([]);
    const [causadocs, setcausadocs] = useState([]);
    const [afecaj, setafecaj] = useState([]);
    const [afecajs, setafecajs] = useState([]);
    const [formapago, setformapago] = useState([]);
    const [formapagos, setformapagos] = useState([]);
    const [afeban, setafeban] = useState([]);
    const [afebans, setafebans] = useState([]);
    const [docban, setdocban] = useState([]);
    const [docbans, setdocbans] = useState([]);
    const [muesdescbanc, setmuesdescbanc] = useState([]);
    const [muesdescbancs, setmuesdescbancs] = useState([]);
    const [asicont, setasicont] = useState([]);
    const [asiconts, setasiconts] = useState([]);
    const [consuni, setconsuni] = useState([]);
    const [consunis, setconsunis] = useState([]);
    const [conscom, setconscom] = useState([]);
    const [conscoms, setconscoms] = useState([]);
    const [precsug, setprecsug] = useState([]);
    const [precsugs, setprecsugs] = useState([]);
    const [descsug, setdescsug] = useState([]);
    const [descsugs, setdescsugs] = useState([]);
    const [cupocred, setcupocred] = useState([]);
    const [cupocreds, setcupocreds] = useState([]);
    const [permexisne, setpermexisne] = useState([]);
    const [permexisnes, setpermexisnes] = useState([]);
    const [conpunreor, setconpunreor] = useState([]);
    const [conpunreors, setconpunreors] = useState([]);
    const [facdebcos, setfacdebcos] = useState([]);
    const [facdebcoss, setfacdebcoss] = useState([]);
    const [camprecven, setcamprecven] = useState([]);
    const [camprecvens, setcamprecvens] = useState([]);
    const [camdesc, setcamdesc] = useState([]);
    const [camdescs, setcamdescs] = useState([]);
    const [cambioiva, setcambioiva] = useState([]);
    const [cambioivas, setcambioivas] = useState([]);
    const [cambioimp, setcambioimp] = useState([]);
    const [cambioimps, setcambioimps] = useState([]);
    const [confacven, setconfacven] = useState([]);
    const [confacvens, setconfacvens] = useState([]);
    const [consecfac, setconsecfac] = useState([]);
    const [consecfacs, setconsecfacs] = useState([]);
    const [camdescenc, setcamdescenc] = useState([]);
    const [camdescencs, setcamdescencs] = useState([]);
    const [camdescpp, setcamdescpp] = useState([]);
    const [camdescpps, setcamdescpps] = useState([]);
    const [pass1, setpass1] = useState([]);
    const [pass1s, setpass1s] = useState([]);
    const [pass2, setpass2] = useState([]);
    const [pass2s, setpass2s] = useState([]);
    const [pass3, setpass3] = useState([]);
    const [pass3s, setpass3s] = useState([]);
    const [pass4, setpass4] = useState([]);
    const [pass4s, setpass4s] = useState([]);
    const [pass5, setpass5] = useState([]);
    const [pass5s, setpass5s] = useState([]);
    const [pass6, setpass6] = useState([]);
    const [pass6s, setpass6s] = useState([]);
    const [pass7, setpass7] = useState([]);
    const [pass7s, setpass7s] = useState([]);
    const [pass8, setpass8] = useState([]);
    const [pass8s, setpass8s] = useState([]);
    const [pass9, setpass9] = useState([]);
    const [pass9s, setpass9s] = useState([]);
    const [pass10, setpass10] = useState([]);
    const [pass10s, setpass10s] = useState([]);
    const [ivaincluido, setivaincluido] = useState([]);
    const [ivaincluidos, setivaincluidos] = useState([]);
    const [busplu, setbusplu] = useState([]);
    const [busplus, setbusplus] = useState([]);
    const [ccosto, setccosto] = useState([]);
    const [ccostos, setccostos] = useState([]);
    const [centrocosto, setcentrocosto] = useState([]);
    const [centrocostos, setcentrocostos] = useState([]);
    const [tcostofijo, settcostofijo] = useState([]);
    const [tcostofijos, settcostofijos] = useState([]);
    const [comprob, setcomprob] = useState([]);
    const [comprobs, setcomprobs] = useState([]);
    const [impto, setimpto] = useState([]);
    const [imptos, setimptos] = useState([]);
    const [tbodegasn, settbodegasn] = useState([]);
    const [tbodegasns, settbodegasns] = useState([]);
    const [tbodega, settbodega] = useState([]);
    const [tbodegas, settbodegas] = useState([]);
    const [tbodegafija, settbodegafija] = useState([]);
    const [tbodegafijas, settbodegafijas] = useState([]);
    const [descsn, setdescsn] = useState([]);
    const [descsns, setdescsns] = useState([]);
    const [descporc, setdescporc] = useState([]);
    const [descporcs, setdescporcs] = useState([]);
    const [ivasn, setivasn] = useState([]);
    const [ivasns, setivasns] = useState([]);
    const [ivaporc, setivaporc] = useState([]);
    const [ivaporcs, setivaporcs] = useState([]);
    const [rete, setrete] = useState([]);
    const [retes, setretes] = useState([]);
    const [ica, setica] = useState([]);
    const [icas, seticas] = useState([]);
    const [reteiva, setreteiva] = useState([]);
    const [reteivas, setreteivas] = useState([]);
    const [imptosn, setimptosn] = useState([]);
    const [imptosns, setimptosns] = useState([]);
    const [imptoporc, setimptoporc] = useState([]);
    const [imptoporcs, setimptoporcs] = useState([]);
    const [captionimpto, setcaptionimpto] = useState([]);
    const [captionimptos, setcaptionimptos] = useState([]);
    const [enviacorreo, setenviacorreo] = useState([]);
    const [enviacorreos, setenviacorreos] = useState([]);
    const [ctrlmov, setctrlmov] = useState([]);
    const [ctrlmovs, setctrlmovs] = useState([]);
    const [docauto, setdocauto] = useState([]);
    const [docautos, setdocautos] = useState([]);
    const [tdpro1, settdpro1] = useState([]);
    const [tdpro1s, settdpro1s] = useState([]);
    const [tdpro2, settdpro2] = useState([]);
    const [tdpro2s, settdpro2s] = useState([]);
    const [filtroart, setfiltroart] = useState([]);
    const [filtroarts, setfiltroarts] = useState([]);
    const [facturapap, setfacturapap] = useState([]);
    const [facturapaps, setfacturapaps] = useState([]);
    const [mvendedor, setmvendedor] = useState([]);
    const [mvendedors, setmvendedors] = useState([]);
    const [captionvend, setcaptionvend] = useState([]);
    const [captionvends, setcaptionvends] = useState([]);
    const [docu1, setdocu1] = useState([]);
    const [docu1s, setdocu1s] = useState([]);
    const [captiondoc1, setcaptiondoc1] = useState([]);
    const [captiondoc1s, setcaptiondoc1s] = useState([]);
    const [val_doc1, setval_doc1] = useState([]);
    const [val_doc1s, setval_doc1s] = useState([]);
    const [docu2, setdocu2] = useState([]);
    const [docu2s, setdocu2s] = useState([]);
    const [captiondoc2, setcaptiondoc2] = useState([]);
    const [captiondoc2s, setcaptiondoc2s] = useState([]);
    const [observa, setobserva] = useState([]);
    const [observas, setobservas] = useState([]);
    const [obslinea, setobslinea] = useState([]);
    const [obslineas, setobslineas] = useState([]);
    const [mdevolucio, setmdevolucio] = useState([]);
    const [mdevolucios, setmdevolucios] = useState([]);
    const [mediospago, setmediospago] = useState([]);
    const [mediospagos, setmediospagos] = useState([]);
    const [puntoventa, setpuntoventa] = useState([]);
    const [puntoventas, setpuntoventas] = useState([]);
    const [modidoc, setmodidoc] = useState([]);
    const [modidocs, setmodidocs] = useState([]);
    const [modidocenc, setmodidocenc] = useState([]);
    const [modidocencs, setmodidocencs] = useState([]);
    const [noimpdupl, setnoimpdupl] = useState([]);
    const [noimpdupls, setnoimpdupls] = useState([]);
    const [rentabilid, setrentabilid] = useState([]);
    const [rentabilids, setrentabilids] = useState([]);
    const [redondeofac, setredondeofac] = useState([]);
    const [redondeofacs, setredondeofacs] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(false);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalFatipodoc, setVerModalFatipodoc] = useState(false);
    const [busquedaprecsugs, setbusquedaprecsugs] = useState([]);
    const [verModalprecsug, setVerModalprecsug] = useState(false);

    const [bodega, setbodega] = useState([])
    const [busquedabodegas, setbusquedabodegas] = useState([]);
    const [verModalbodegas, setVerModalbodegas] = useState(false);
    const [bodegas, setbodegas] = useState([]);
    const [Ctcomprobantess, setCtcomprobantess] = useState([]);
    const [Sucursals, setSucursals] = useState([]);


    // const [ccosto, setccosto] = useState(modeloInccostos)
    const [busquedaccostos, setbusquedaccostos] = useState([]);
    const [verModalccostos, setVerModalccostos] = useState(false);
    // const [ccostos, setccostos] = useState([]);


    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Fatipodoc = { ...Fatipodoc }
        _Fatipodoc[e.target.name] = value
        setFatipodoc(_Fatipodoc)
        setRoles([])


    }
    const handleChangeswitch = (e) => {
        let value = e.target.checked
        console.log(e.target)
        var valor = 'no'
        if (value) {
            valor = 'si'
        }
        var _Fatipodoc = { ...Fatipodoc }
        _Fatipodoc[e.target.name] = valor
        setFatipodoc(_Fatipodoc)
        setRoles([])


    }
    const borrartodaslasprecsugs = () => {
        setbusquedaprecsugs([])
    }
    const filtrarprecsugs = async (coincidencia) => {
        var _precsugs = precsugs.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaprecsugs(_precsugs)
    }
    const cerrarModalSeleccionprecsugs = () => {
        setVerModalprecsug(!verModalprecsug);
        setbusquedaprecsugs(precsugs)
    }
    const buscartodasprecsugs = (value) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/ListadePrecioapi/nombres/' + value + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setprecsugs(dataJson)
                    setbusquedaprecsugs(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setprecsugs([])
                    setbusquedaprecsugs([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const obtenerSucursals = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Sucursalapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setSucursals(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarprecsug = (e) => {
        let value = e
        var _Fatipodoc = { ...Fatipodoc }
        _Fatipodoc['precsug'] = value
        setFatipodoc(_Fatipodoc)
        setRoles([])
        cerrarModalSeleccionprecsugs()

    }
    const obtenerFatipodocs = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setFatipodocs(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerRangos = async (coincidencia) => {
        if (Fatipodoc.ambiente == "1" && Fatipodoc.facturapap == "electronica") {
            try {


                let dt = JSON.parse(user)
                let response = await fetch(props.servidor + '/ConsultaRangosApi/' + Fatipodoc.num_res + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.config.clave_certificado + '/' + Fatipodoc.identificadorsw + '?llave=' + dt.usuario.llaveempresa + '');

                if (response.ok) {
                    let data = await response.json()
                    if (data.numero != "" && Fatipodoc.codigo != "") {
                        var tt = modeloFatipodoc
                        var _Fatipodoc = { ...Fatipodoc }
                        _Fatipodoc['clave_tec'] = data.clavetecnica
                        _Fatipodoc['rango_res_i'] = data.desde + ""
                        _Fatipodoc['inicresolu'] = data.fechadesde
                        _Fatipodoc['vencresolu'] = data.fechahasta
                        _Fatipodoc['rango_res_f'] = data.hasta + ""
                        _Fatipodoc['prefijo'] = data.prefijo
                        setFatipodoc(_Fatipodoc)
                        setRoles([])
                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
    const obtenerRangossucursal = async () => {
        if (Fatipodoc.ambiente == "1" && Fatipodoc.facturapap == "electronica") {
            try {
                var suc = Sucursals.find(p => p.nit == Fatipodoc.compañia)
                if (suc) {
                    if (suc.electronica == "si") {
                        var calvecer = suc.opcion1

                        let dt = JSON.parse(user)
                        let response = await fetch(props.servidor + '/ConsultaRangosApi/' + Fatipodoc.num_res + '/' + Fatipodoc.compañia + '/' + calvecer + '/' + Fatipodoc.identificadorsw + '?llave=' + dt.usuario.llaveempresa + '');

                        if (response.ok) {
                            let data = await response.json()
                            if (data.numero != "" && Fatipodoc.codigo != "") {
                                var tt = modeloFatipodoc
                                var _Fatipodoc = { ...Fatipodoc }
                                _Fatipodoc['clave_tec'] = data.clavetecnica
                                _Fatipodoc['rango_res_i'] = data.desde + ""
                                _Fatipodoc['inicresolu'] = data.fechadesde
                                _Fatipodoc['vencresolu'] = data.fechahasta
                                _Fatipodoc['rango_res_f'] = data.hasta + ""
                                _Fatipodoc['prefijo'] = data.prefijo
                                setFatipodoc(_Fatipodoc)
                                setRoles([])
                            }
                        }
                    }
                }

            } catch (error) {
                console.log(error)
            }
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerFatipodocs('');
        buscartodasprecsugs('');
        obtenerCtcomprobantess('');
        obtenerSucursals('');
    }, [])
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'descripcion',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalFatipodoc(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalFatipodoc = (data) => {
        var enviodata = { ...data }
        var _Fatipodoc = {
            codigo: enviodata.codigo,
            descripcion: enviodata.descripcion,
            tipodoc: enviodata.tipodoc,
            tdpro: enviodata.tdpro,
            trasauto: enviodata.trasauto,
            tipoprod: enviodata.tipoprod,
            actuacu: enviodata.actuacu,
            comactacu: enviodata.comactacu,
            rentab: enviodata.rentab,
            afecinv: enviodata.afecinv,
            serial: enviodata.serial,
            descuentoc: enviodata.descuentoc,
            actcosto: enviodata.actcosto,
            numitem: enviodata.numitem,//esto es lo de facturas vencidas a mas de tantos dias que no deje guardar
            despiece: enviodata.despiece,
            exportacion: enviodata.exportacion,
            facdebcos2: enviodata.facdebcos2,
            afecar: enviodata.afecar,
            rcauto: enviodata.rcauto,
            lotes: enviodata.lotes,
            causadoc: enviodata.causadoc,
            afecaj: enviodata.afecaj,
            formapago: enviodata.formapago,
            afeban: enviodata.afeban,
            docban: enviodata.docban,
            muesdescbanc: enviodata.muesdescbanc,
            asicont: enviodata.asicont,
            consuni: enviodata.consuni,
            conscom: enviodata.conscom,
            precsug: enviodata.precsug,
            descsug: enviodata.descsug,
            cupocred: enviodata.cupocred,
            permexisne: enviodata.permexisne,
            conpunreor: enviodata.conpunreor,
            facdebcos: enviodata.facdebcos,
            camprecven: enviodata.camprecven,
            camdesc: enviodata.camdesc,
            cambioiva: enviodata.cambioiva,
            cambioimp: enviodata.cambioimp,
            confacven: enviodata.confacven,
            consecfac: enviodata.consecfac,
            camdescenc: enviodata.camdescenc,
            camdescpp: enviodata.camdescpp,
            pass1: enviodata.pass1,
            pass2: enviodata.pass2,
            pass3: enviodata.pass3,
            pass4: enviodata.pass4,
            pass5: enviodata.pass5,
            pass6: enviodata.pass6,
            pass7: enviodata.pass7,
            pass8: enviodata.pass8,
            pass9: enviodata.pass9,
            pass10: enviodata.pass10,
            ivaincluido: enviodata.ivaincluido,
            busplu: enviodata.busplu,
            ccosto: enviodata.ccosto,
            centrocosto: enviodata.centrocosto,
            tcostofijo: enviodata.tcostofijo,
            comprob: enviodata.comprob,
            impto: enviodata.impto,
            tbodegasn: enviodata.tbodegasn,
            tbodega: enviodata.tbodega,
            tbodegafija: enviodata.tbodegafija,
            descsn: enviodata.descsn,
            descporc: enviodata.descporc,
            ivasn: enviodata.ivasn,
            ivaporc: enviodata.ivaporc,
            rete: enviodata.rete,
            ica: enviodata.ica,
            reteiva: enviodata.reteiva,
            imptosn: enviodata.imptosn,
            imptoporc: enviodata.imptoporc,
            captionimpto: enviodata.captionimpto,
            compañia: enviodata.compañia,
            enviacorreo: enviodata.enviacorreo,
            ctrlmov: enviodata.ctrlmov,
            docauto: enviodata.docauto,
            tdpro1: enviodata.tdpro1,
            tdpro2: enviodata.tdpro2,
            filtroart: enviodata.filtroart,
            facturapap: enviodata.facturapap,
            mvendedor: enviodata.mvendedor,
            captionvend: enviodata.captionvend,
            docu1: enviodata.docu1,
            captiondoc1: enviodata.captiondoc1,
            val_doc1: enviodata.val_doc1,
            docu2: enviodata.docu2,
            captiondoc2: enviodata.captiondoc2,
            observa: enviodata.observa,
            obslinea: enviodata.obslinea,
            mdevolucio: enviodata.mdevolucio,
            mediospago: enviodata.mediospago,
            puntoventa: enviodata.puntoventa,
            modidoc: enviodata.modidoc,
            modidocenc: enviodata.modidocenc,
            noimpdupl: enviodata.noimpdupl,
            rentabilid: enviodata.rentabilid,
            redondeofac: enviodata.redondeofac,//esto es Sumar al Costo Impo/ICUA
            num_res: enviodata.num_res,
            rango_res_i: enviodata.rango_res_i,
            rango_res_f: enviodata.rango_res_f,
            inicresolu: enviodata.inicresolu,
            vencresolu: enviodata.vencresolu,
            vencresoludias: enviodata.vencresoludias,
            prefijo: enviodata.prefijo,
            plantilladoc: enviodata.plantilladoc,
            allinformes: enviodata.allinformes,

            clave_tec: enviodata.clave_tec,
            ambiente: enviodata.ambiente,
            identificadorsw: enviodata.identificadorsw,
            pin: enviodata.pin,
            setpruebas: enviodata.setpruebas,
            manejatercerounico: enviodata.manejatercerounico,
            tercerounico: enviodata.tercerounico,
            centrodecostoobligatorio: enviodata.centrodecostoobligatorio,
        }
        setFatipodoc(_Fatipodoc);
        setVerModalFatipodoc(!verModalFatipodoc);
        seteditar(true)
    }
    const cerrarModalFatipodoc = () => {
        setFatipodoc(modeloFatipodoc)
        setVerModalFatipodoc(!verModalFatipodoc);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Fatipodoc) => {

        let claves = Object.keys(_Fatipodoc);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'numitem' || clave == 'consuni' || clave == 'descporc' || clave == 'ivaporc' || clave == 'imptoporc') {
                _Fatipodoc[clave] = Number(_Fatipodoc[clave])
            }
        }
        return _Fatipodoc
    }
    const verificar = (_Fatipodoc) => {

        let claves = Object.keys(_Fatipodoc);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                if (_Fatipodoc[clave] == '') {
                    return false
                }
            }
        }
        return true
    }

    const utilizarplantilla = async () => {
        var _Fatipodoc = { ...Fatipodoc }
        _Fatipodoc.codigo = ""
        setFatipodoc(_Fatipodoc)
        seteditar(false)
    }

    const guardarCambios = async () => {
        let response;
        console.log(Fatipodoc)
        var _Fatipodoc = { ...Fatipodoc }
        _Fatipodoc = asignarceros(_Fatipodoc)
        var _verificado = verificar(_Fatipodoc)
        if (_verificado) {
            console.log(_Fatipodoc)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Fatipodocapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Fatipodoc)
                })

            } else {
                var editar2 = true
                props.permisosusuario.forEach(element => {
                    if (element.tipo == "editartipodocumentos" && element.acceso[0] == "1") {
                        editar2 = false
                    }
                });
                if (editar2) {
                    response = await fetch("" + props.servidor + "/Fatipodocapi?llave=" + usuario.usuario.llaveempresa + "", {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json;charset=utf-8'
                        },
                        body: JSON.stringify(_Fatipodoc)
                    })
                } else {
                    Swal.fire(
                        'Error!',
                        'No tiene permisos',
                        'error'
                    )
                    return
                }

            }

            if (response.ok) {
                await obtenerFatipodocs('');
                setFatipodoc(modeloFatipodoc)
                setVerModalFatipodoc(!verModalFatipodoc);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Tipo de Documento fue guardado exitosamente',
                    'success'
                )
            } else {
                var textoerrores = await response.text()
                var jsonerrores = JSON.parse(textoerrores)
                var errores = jsonerrores.errors
                let claves = Object.keys(errores);
                var stringerrores = ""
                for (let i = 0; i < claves.length; i++) {
                    let clave = claves[i];
                    if (errores[clave].length != 0) {
                        stringerrores = stringerrores + errores[clave][0] + " \n "
                    }

                }
                var mensaje = stringerrores.replace(/The /g, '').replace(/field /g, '').replace(/is /g, 'es ').replace(/required/g, 'requerido')
                if (mensaje.includes('not be converted to System.Decimal')) {
                    mensaje = 'Hay campos numericos rellenados con letras'
                }
                console.log(stringerrores)
                Swal.fire(
                    'Error al Guardar!',
                    mensaje,
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }


    const filtrarbodegas = async (coincidencia) => {
        var _codigomuns = bodegas.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedabodegas(_codigomuns)
    }
    const cerrarModalbodegas = () => {
        setVerModalbodegas(!verModalbodegas);
        setbusquedabodegas(bodegas)
    }
    const obtenerCtcomprobantess = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Ctcomprobantesapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setCtcomprobantess(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodasbodegas = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Bodegaapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setbodegas(dataJson)
                    setbusquedabodegas(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setbodegas([])
                    setbusquedabodegas([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarbodegas = (e) => {
        let value = e
        console.log(e.target)
        var _Fatipodoc = { ...Fatipodoc }
        _Fatipodoc['tbodega'] = value.codigo
        setFatipodoc(_Fatipodoc)
        setRoles([])
        // console.log(Roles)
        // cerrarModalccostos()
        cerrarModalbodegas()
    }




    const filtrarccostos = async (coincidencia) => {
        var _codigomuns = ccostos.filter((dato) => {
            if (dato.codigo.toUpperCase().includes(coincidencia.toUpperCase()) || dato.nombre.toUpperCase().includes(coincidencia.toUpperCase())) { return dato }

        })
        setbusquedaccostos(_codigomuns)
    }
    const cerrarModalccostos = () => {
        setVerModalccostos(!verModalccostos);
        setbusquedaccostos(ccostos)
    }
    const buscartodasccostos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Ctcentrocostoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setccostos(dataJson)
                    setbusquedaccostos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setccostos([])
                    setbusquedaccostos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarccostos = (e) => {
        let value = e
        console.log(e.target)
        var _Fatipodoc = { ...Fatipodoc }
        _Fatipodoc['centrocosto'] = value.codigo
        setFatipodoc(_Fatipodoc)
        setRoles([])
        // console.log(Roles)
        cerrarModalccostos()
    }
    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Tipos de Documento
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataFatipodocs" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerFatipodocs(document.getElementById('buscardataFatipodocs').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalFatipodoc(!verModalFatipodoc)
                                setFatipodoc(modeloFatipodoc)
                                seteditar(false)
                            }}>Nuevo</Button>
                            <Col sm={1}>
                                <Button style={{ fontSize: '12px', backgroundColor: '#FE0000', color: 'black' }} block onClick={() => { props.setcreartipodocumento(false) }} >
                                    <i className="fa fa-times"></i></Button>
                            </Col>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Fatipodocs}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1000px', width: '100%' }} isOpen={verModalFatipodoc}>
                <ModalHeader>
                    Tipo de Documento
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row className="ml-3">
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Codigo</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="codigo" value={Fatipodoc.codigo} />
                                    </FormGroup>
                                </Col>
                                <Col sm={4}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Descripcion</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descripcion" value={Fatipodoc.descripcion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Ti. Documento</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tipodoc" value={Fatipodoc.tipodoc}  >
                                            <option value="factura">Factura</option>
                                            <option value="compra">Compra</option>
                                            <option value="recibo de caja">Recibo de Caja</option>
                                            <option value="otros">Otros</option>
                                            <option value="remision">Remision</option>
                                            <option value="translado">Translado</option>
                                            <option value="cotizacion">Cotizacion</option>
                                            <option value="bancos">Bancos</option>
                                            <option value="causaciones">Causaciones</option>
                                            <option value="devoluciones">Devoluciones</option>
                                            <option value="contabilidad">Contabilidad</option>
                                            <option value="transladodebancos">Translado de Bancos</option>
                                            <option value="produccion">Produccion</option>
                                            <option value="pedidos">Pedidos</option>
                                            <option value="ordendecompra">Orden de Compra</option>
                                            <option value="bonos">Bonos</option>
                                            <option value="activosfijos">Activos Fijos</option>
                                            <option value="anticipoaproveedores">Anticipo a Proveedores</option>
                                            <option value="radian">Radian</option>
                                            <option value="plan separe">Plan Separe</option>
                                            <option value="nota debito">Nota Debito</option>
                                            <option value="contrato">Contrato</option>
                                            <option value="inventariofisico">Inventario Fisico</option>
                                            <option value="devoluciones compra">Devoluciones Compra</option>

                                        </Input>
                                    </FormGroup>
                                </Col>


                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Act. Acumulados</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="actuacu" value={Fatipodoc.actuacu}  >
                                            <option value="">--</option>
                                            <option value="ventas">Ventas</option>
                                            <option value="compras">Compras</option>
                                            <option value="entradasespeciales">Entradas Especiales</option>
                                            <option value="salidasespeciales">Salidas Especiales</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Accion Acumulado</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="comactacu" value={Fatipodoc.comactacu}  >
                                            <option value="">--</option>
                                            <option value="suma">Suma</option>
                                            <option value="resta">resta</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="ml-3">


                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.rentab == "si") ? true : false}
                                            value={Fatipodoc.rentab}
                                            name="rentab"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Cambiar Precio desde Compras</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Rentabilidad</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="rentab" value={Fatipodoc.rentab}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Afecta Inventario</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="afecinv" value={Fatipodoc.afecinv}  >
                                            <option value="">--</option>
                                            <option value="suma">Suma</option>
                                            <option value="resta">Resta</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.descuentoc == "si") ? true : false}
                                            value={Fatipodoc.descuentoc}
                                            name="descuentoc"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>D. Comercial</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>D. Comercial</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="descuentoc" value={Fatipodoc.descuentoc}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.tipodoc == "compra" || Fatipodoc.tipodoc == "produccion") &&
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Input
                                                type='checkbox'
                                                checked={(Fatipodoc.actcosto == "si") ? true : false}
                                                value={Fatipodoc.actcosto}
                                                name="actcosto"
                                                onClick={(e) => {
                                                    handleChangeswitch(e)
                                                }}
                                            />
                                            <Label check>Afecta Costo</Label>
                                            {/* <Label style={{ fontSize: '13px' }}>Afecta Costo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="actcosto" value={Fatipodoc.actcosto}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                        </FormGroup>
                                    </Col>
                                }
                                {(Fatipodoc.tipodoc == "compra" || Fatipodoc.tipodoc == "produccion") &&
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Input
                                                type='checkbox'
                                                checked={(Fatipodoc.redondeofac == "si") ? true : false}
                                                value={Fatipodoc.redondeofac}
                                                name="redondeofac"
                                                onClick={(e) => {
                                                    handleChangeswitch(e)
                                                }}
                                            />
                                            <Label check>Sumar al Costo Impo/ICUA</Label>
                                            {/* <Label style={{ fontSize: '13px' }}>Redondea Totales</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="redondeofac" value={Fatipodoc.redondeofac}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                        </FormGroup>
                                    </Col>
                                }
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Afecta Cartera</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="afecar" value={Fatipodoc.afecar}  >
                                            <option value="">--</option>
                                            <option value="debito">Debito</option>
                                            <option value="credito">Credito</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.afecar != "" && Fatipodoc.tipodoc == "compra") && <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>--</Label>
                                        <Input type="select" style={{ fontSize: '13px' }} name=""   >
                                            <option value="numerodocumento">Afecta Cartera con Numero del Documento</option>
                                            <option value="documento1">Afecta Cartera con Documento 1</option>
                                        </Input>
                                    </FormGroup>
                                </Col>}
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Dias Vencim. Factura</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="numitem" value={Fatipodoc.numitem} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="ml-3">
                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Serial</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="serial" value={Fatipodoc.serial}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}


                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Despiece</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="despiece" value={Fatipodoc.despiece}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.formapago == "si") ? true : false}
                                            value={Fatipodoc.formapago}
                                            name="formapago"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Forma de Pago</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Forma de Pago</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="formapago" value={Fatipodoc.formapago}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.afeban == "si") ? true : false}
                                            value={Fatipodoc.afeban}
                                            name="afeban"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Afecta Banco</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Afecta Banco</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="afeban" value={Fatipodoc.afeban}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.docban == "si") ? true : false}
                                            value={Fatipodoc.docban}
                                            name="docban"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Doc. Banc.</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Doc. Banc.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="docban" value={Fatipodoc.docban}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.muesdescbanc == "si") ? true : false}
                                            value={Fatipodoc.muesdescbanc}
                                            name="muesdescbanc"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Sumar Items Al Agrupar</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Comen. Banco</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="muesdescbanc" value={Fatipodoc.muesdescbanc}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.asicont == "si") ? true : false}
                                            value={Fatipodoc.asicont}
                                            name="asicont"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Agrupar</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Asicont</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="asicont" value={Fatipodoc.asicont}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.causadoc == "si") ? true : false}
                                            value={Fatipodoc.causadoc}
                                            name="causadoc"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Car. T. Doc.</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Car. T. Doc.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="causadoc" value={Fatipodoc.causadoc}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="ml-3">

                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>RC Automático</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="rcauto" value={Fatipodoc.rcauto}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}

                                <Col sm={2}>
                                    <FormGroup>
                                        {/* <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.afecaj != "nada") ? true : false}
                                            value={Fatipodoc.afecaj}
                                            name="afecaj"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        /> */}
                                        <Label style={{ fontSize: '13px' }}>Afecta Caja</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="afecaj" value={Fatipodoc.afecaj}  >
                                            <option value="nada">Nada</option>
                                            <option value="suma">Suma</option>
                                            <option value="resta">Resta</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >Cons. Unico</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="consuni" value={Fatipodoc.consuni} />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Cons. Compartido</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="conscom" value={Fatipodoc.conscom}  >
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup inline={true}>
                                        <Label>Precio Sugerido</Label>
                                        <InputGroup>
                                            <Input bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "") {
                                                    setbusquedaprecsugs(precsugs)
                                                    setVerModalprecsug(true)

                                                }
                                            }} name="precsug" value={Fatipodoc.precsug} />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    setbusquedaprecsugs(precsugs)
                                                    setVerModalprecsug(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></i>
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Nacional/Extranjero</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="exportacion" value={Fatipodoc.exportacion}  >
                                            <option value="nacional">Nacional</option>
                                            <option value="dolar">Dolar</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Comprobante Conciliacion</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="facdebcos2" value={Fatipodoc.facdebcos2}  >
                                            <option value="Pago">Pago</option>
                                            <option value="Recaudo">Recaudo</option>
                                            <option value="Notas Debito">Notas Debito</option>
                                            <option value="Notas Credito">Notas Credito</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row className="ml-3">

                            </Row>
                            <Row className="ml-3">

                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Desc. Sugerido</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="descsug" value={Fatipodoc.descsug}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                            </Row>

                        </Tab>
                        {<Tab eventKey="Procesos Autorizados" title="Procesos Autorizados">
                            <Row>
                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>C. Cupo Cred.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="cupocred" value={Fatipodoc.cupocred}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Con. Exis. Neg.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="permexisne" value={Fatipodoc.permexisne}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Cont. P. Reor.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="conpunreor" value={Fatipodoc.conpunreor}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>No facturar por debajo de:</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="facdebcos" value={Fatipodoc.facdebcos}  >
                                            <option value="">--</option>
                                            <option value="costopromedio">Costo promedio</option>
                                            <option value="costoultimo">Ultimo Costo</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.camprecven == "si") ? true : false}
                                            value={Fatipodoc.camprecven}
                                            name="camprecven"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Prec. Dig.</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Prec. Dig.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="camprecven" value={Fatipodoc.camprecven}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {/* Restaurante */}
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.camdesc == "si") ? true : false}
                                            value={Fatipodoc.camdesc}
                                            name="camdesc"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Restaurante</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>% Desc. Dig.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="camdesc" value={Fatipodoc.camdesc}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.cambioiva == "si") ? true : false}
                                            value={Fatipodoc.cambioiva}
                                            name="cambioiva"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Impoconsumo Incluido</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>% de Iva al Dig.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="cambioiva" value={Fatipodoc.cambioiva}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.cambioimp == "si") ? true : false}
                                            value={Fatipodoc.cambioimp}
                                            name="cambioimp"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>{Fatipodoc.tipodoc == "recibo de caja" ? "Poner en otros ingresos valor del interes" : "Factura con comision por articulo"}</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>% de imp. al Dig.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="cambioimp" value={Fatipodoc.cambioimp}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.camdescenc == "si") ? true : false}
                                            value={Fatipodoc.camdescenc}
                                            name="camdescenc"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Productos Compuestos</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Desc. Com.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="camdescenc" value={Fatipodoc.camdescenc}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Controlar facturas vencidas CR</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="confacven" value={Fatipodoc.confacven}  >
                                            <option value="">--</option>
                                            <option value="30">30</option>
                                            <option value="60">60</option>
                                            <option value="90">90</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    <FormGroup>
                                        <Input
                                            type='text'
                                            onChange={handleChange}
                                            value={Fatipodoc.consecfac}
                                            name="consecfac"

                                        />
                                        <Label check>Nombre en PDF</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Desc. F. pago</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="camdescpp" value={Fatipodoc.camdescpp}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>

                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.consecfac == "si") ? true : false}
                                            value={Fatipodoc.consecfac}
                                            name="consecfac"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Control Consecutivo</Label>
                                       
                                    </FormGroup>
                                </Col> */}

                                <Col sm={6}>
                                    <FormGroup>
                                        <Input
                                            type='textarea'
                                            onChange={handleChange}
                                            value={Fatipodoc.camdescpp}
                                            name="camdescpp"

                                        />
                                        <Label check>Observacion Fija</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Desc. F. pago</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="camdescpp" value={Fatipodoc.camdescpp}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>

                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Cont. CR Fact. Ven.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="confacven" value={Fatipodoc.confacven}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Cont. cons.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="consecfac" value={Fatipodoc.consecfac}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                            </Row>
                            <Row>

                            </Row>

                        </Tab>}
                        <Tab eventKey="Configuracion del Movimiento" title="Configuracion del Movimiento">
                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.imptosn == "si") ? true : false}
                                            disabled={(Fatipodoc.modidocenc == "si") ? true : false}
                                            value={Fatipodoc.imptosn}
                                            name="imptosn"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Impo Consumo %</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Impo Consumo</Label>
                                       <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="imptosn" value={Fatipodoc.imptosn}  >
                                           <option value="no">No</option>
                                           <option value="si">Si</option>
                                       </Input> */}
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.imptosn == "si") && <>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }} >% Impo Consu</Label>
                                            <Input type="number" bsSize="sm" onChange={handleChange} name="imptoporc" value={Fatipodoc.imptoporc} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }} >Text Impo Cons</Label>
                                            <Input type="text" bsSize="sm" onChange={handleChange} name="captionimpto" value={Fatipodoc.captionimpto} />
                                        </FormGroup>
                                    </Col></>}
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Sucursal</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="compañia" value={Fatipodoc.compañia}  >
                                            <option value={""}>-</option>
                                            {
                                                Sucursals.map((dat) => {
                                                    return <option value={dat.nit}>{dat.nit + " - " + dat.nombre}</option>

                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>


                            </Row>
                            <Row className="ml-3">
                                <Col sm={2}>
                                    <FormGroup>

                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.ccosto == "si") ? true : false}
                                            value={Fatipodoc.ccosto}
                                            name="ccosto"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Incluye CC</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Incluye CC</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ccosto" value={Fatipodoc.ccosto}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.ccosto == "si") && <>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Centro de Costo</Label>
                                            <Input disabled={(Fatipodoc.ccosto == "si") ? false : true} name='centrocosto' value={Fatipodoc.centrocosto} onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    buscartodasccostos('');
                                                    setbusquedaccostos(ccostos)
                                                    setVerModalccostos(true)
                                                }
                                            }} />

                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Input
                                                type='checkbox'
                                                checked={(Fatipodoc.tcostofijo == "si") ? true : false}
                                                value={Fatipodoc.tcostofijo}
                                                name="tcostofijo"
                                                onClick={(e) => {
                                                    handleChangeswitch(e)
                                                }}
                                            />
                                            <Label check>CC Fijo</Label>
                                            {/* <Label style={{ fontSize: '13px' }}>CC Fijo</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tcostofijo" value={Fatipodoc.tcostofijo}  >
                                                <option value="no">No</option>
                                                <option value="si">Si</option>
                                            </Input> */}
                                        </FormGroup>
                                    </Col></>}
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.impto == "si") ? true : false}
                                            value={Fatipodoc.impto}
                                            name="impto"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Incluye IVA Costo</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Incluye IVA Costo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="impto" value={Fatipodoc.impto}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="ml-3">




                            </Row>
                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.tbodegasn == "si") ? true : false}
                                            value={Fatipodoc.tbodegasn}
                                            name="tbodegasn"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Activar Bodega</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Activar Bodega</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tbodegasn" value={Fatipodoc.tbodegasn}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.tbodegasn == "si") && <>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }}>Bodega Escogida</Label>
                                            <Input disabled={(Fatipodoc.tbodegasn == "si") ? false : true} name='tbodega' value={Fatipodoc.tbodega} onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    buscartodasbodegas('');
                                                    setbusquedabodegas(bodegas)
                                                    setVerModalbodegas(true)
                                                }
                                            }} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Input
                                                type='checkbox'
                                                checked={(Fatipodoc.tbodegafija == "si") ? true : false}
                                                value={Fatipodoc.tbodegafija}
                                                name="tbodegafija"
                                                onClick={(e) => {
                                                    handleChangeswitch(e)
                                                }}
                                            />
                                            <Label check>Bodega Fija</Label>
                                            {/* <Label style={{ fontSize: '13px' }}>Bodega Fija</Label>
                                            <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tbodegafija" value={Fatipodoc.tbodegafija}  >
                                                <option value="no">No</option>
                                                <option value="si">Si</option>
                                            </Input> */}
                                        </FormGroup>
                                    </Col></>}
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.ctrlmov == "si") ? true : false}
                                            value={Fatipodoc.ctrlmov}
                                            name="ctrlmov"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Control de Fecha</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Control de Fecha</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ctrlmov" value={Fatipodoc.ctrlmov}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.lotes == "si") ? true : false}
                                            value={Fatipodoc.lotes}
                                            name="lotes"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Maneja Lotes</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Control de Fecha</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ctrlmov" value={Fatipodoc.ctrlmov}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="ml-3">

                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.ivasn == "si") ? true : false}
                                            value={Fatipodoc.ivasn}
                                            name="ivasn"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>IVA</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>IVA</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ivasn" value={Fatipodoc.ivasn}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.ivasn == "si") && <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >% IVA</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="ivaporc" value={Fatipodoc.ivaporc} />
                                    </FormGroup>
                                </Col>}

                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.rete == "si") ? true : false}
                                            value={Fatipodoc.rete}
                                            name="rete"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Retefuente</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Retefuente</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="rete" value={Fatipodoc.rete}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={1}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.ica == "si") ? true : false}
                                            value={Fatipodoc.ica}
                                            name="ica"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Ica</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Ica</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ica" value={Fatipodoc.ica}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.reteiva == "si") ? true : false}
                                            value={Fatipodoc.reteiva}
                                            name="reteiva"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Reteiva</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Reteiva</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="reteiva" value={Fatipodoc.reteiva}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.enviacorreo == "si") ? true : false}
                                            value={Fatipodoc.enviacorreo}
                                            name="enviacorreo"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Enviacorreo</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Enviacorreo</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="enviacorreo" value={Fatipodoc.enviacorreo}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>

                            </Row>

                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.filtroart == "si") ? true : false}
                                            value={Fatipodoc.filtroart}
                                            name="filtroart"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Filtrar solo Articuos con Existencias</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Filtro Artículos</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="filtroart" value={Fatipodoc.filtroart}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>

                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.tdpro1 == "si") ? true : false}
                                            value={Fatipodoc.tdpro1}
                                            name="tdpro1"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Mostrar Existencia</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Doc Defecto</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tdpro1" value={Fatipodoc.tdpro1}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.tdpro2 == "si") ? true : false}
                                            value={Fatipodoc.tdpro2}
                                            name="tdpro2"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Doc Defecto 2</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Doc Defecto 2</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tdpro2" value={Fatipodoc.tdpro2}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.docauto == "si") ? true : false}
                                            value={Fatipodoc.docauto}
                                            name="docauto"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Documento Automatico sin Impresion (Correo inmediato en caso de ser electronica)</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Docum Auto</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="docauto" value={Fatipodoc.docauto}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>

                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.ivaincluido == "si") ? true : false}
                                            value={Fatipodoc.ivaincluido}
                                            name="ivaincluido"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Iva Incluido</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Iva Incluido</Label>
<Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ivaincluido" value={Fatipodoc.ivaincluido}  >
<option value="no">No</option>
<option value="si">Si</option>
</Input> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            {/* <Row className="ml-3">




                            </Row> */}
                            <Row className="ml-3">

                                <Col sm={2}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.descsn == "si") ? true : false}
                                            value={Fatipodoc.descsn}
                                            name="descsn"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Descuento</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Descuento</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="descsn" value={Fatipodoc.descsn}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>

                                <Col sm={3}>
                                    <FormGroup>
                                        {/* <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.facturapap == "si") ? true : false}
                                            value={Fatipodoc.facturapap}
                                            name="facturapap"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Tipo Factura</Label> */}
                                        <Label style={{ fontSize: '13px' }}>Tipo de Factura</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="facturapap" value={Fatipodoc.facturapap}  >
                                            <option value="pos">Pos</option>
                                            <option value="papel">Papel</option>
                                            <option value="electronica">Electronica</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.descsn == "si") && <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }} >% Descuento</Label>
                                        <Input type="number" bsSize="sm" onChange={handleChange} name="descporc" value={Fatipodoc.descporc} />
                                    </FormGroup>
                                </Col>}

                                <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Comprobante</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="comprob" value={Fatipodoc.comprob}  >
                                            <option value="">--</option>
                                            {Ctcomprobantess.map((dato) => {
                                                return <option value={dato.codigo}>{dato.nombre}</option>
                                            })}

                                        </Input>
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.tipodoc == "produccion" || Fatipodoc.tipodoc == "translado" || Fatipodoc.camdescenc == "si") &&
                                    <>
                                        {Fatipodoc.tipoprod == "origen" &&
                                            <Col sm={2}>
                                                <FormGroup>
                                                    <Label style={{ fontSize: '13px' }}> Tipo Produccion</Label>
                                                    <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tdpro" value={Fatipodoc.tdpro}  >
                                                        <option value="">--</option>
                                                        {Fatipodocs.map((dat) => (
                                                            dat.tipoprod == "destino" && <option value={dat.codigo}>{dat.descripcion}</option>
                                                        ))}
                                                        {/* <option value="no">No</option>
                                            <option value="si">Si</option> */}
                                                    </Input>
                                                </FormGroup>
                                            </Col>
                                        }
                                        <Col sm={2}>
                                            <FormGroup>
                                                <Label style={{ fontSize: '13px' }}> O/D Prod</Label>
                                                <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="tipoprod" value={Fatipodoc.tipoprod}  >
                                                    <option value="">--</option>
                                                    <option value="origen">Origen</option>
                                                    <option value="destino">Destino</option>
                                                </Input>
                                            </FormGroup>
                                        </Col></>}
                            </Row>

                        </Tab>
                        <Tab eventKey="Funcionalidades" title="Funcionalidades">
                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.mvendedor == "si") ? true : false}
                                            value={Fatipodoc.mvendedor}
                                            name="mvendedor"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Activar Vendedor</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Activar Vendedor</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mvendedor" value={Fatipodoc.mvendedor}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.mvendedor == "si") && <>
                                    <Col sm={2} className="mt-1">
                                        <FormGroup>
                                            {/* <Label style={{ fontSize: '13px' }} >_</Label> */}
                                            <Input type="text" bsSize="sm" onChange={handleChange} name="captionvend" value={Fatipodoc.captionvend} />
                                        </FormGroup>
                                    </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Input
                                                type='checkbox'
                                            // checked={(Fatipodoc.mvendedor == "si") ? true : false}
                                            // value={Fatipodoc.mvendedor}
                                            // name="mvendedor"
                                            // onClick={(e) => {
                                            //     handleChangeswitch(e)
                                            // }}
                                            />
                                            <Label check>Vendedor Fijo</Label>
                                            {/* <Label style={{ fontSize: '13px' }}>Activar Vendedor</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mvendedor" value={Fatipodoc.mvendedor}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                        </FormGroup>
                                    </Col>
                                </>}



                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.val_doc1 == "si") ? true : false}
                                            value={Fatipodoc.val_doc1}
                                            name="val_doc1"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Documento Unico</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Documento Unico</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="val_doc1" value={Fatipodoc.val_doc1}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.docu1 == "si") ? true : false}
                                            value={Fatipodoc.docu1}
                                            name="docu1"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Activar DOC 1</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Activar DOC 1</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="docu1" value={Fatipodoc.docu1}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.docu1 == "si") && <><Col sm={2}>
                                    <FormGroup>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="captiondoc1" value={Fatipodoc.captiondoc1} />
                                    </FormGroup>
                                </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Input
                                                type='checkbox'
                                            // checked={(Fatipodoc.mvendedor == "si") ? true : false}
                                            // value={Fatipodoc.mvendedor}
                                            // name="mvendedor"
                                            // onClick={(e) => {
                                            //     handleChangeswitch(e)
                                            // }}
                                            />
                                            <Label check>Documento 1 Fijo</Label>
                                            {/* <Label style={{ fontSize: '13px' }}>Activar Vendedor</Label>
                                     <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mvendedor" value={Fatipodoc.mvendedor}  >
                                         <option value="no">No</option>
                                         <option value="si">Si</option>
                                     </Input> */}
                                        </FormGroup>
                                    </Col></>}
                            </Row>
                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.docu2 == "si") ? true : false}
                                            value={Fatipodoc.docu2}
                                            name="docu2"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Activar DOC 2</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Activar DOC 2</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="docu2" value={Fatipodoc.docu2}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {(Fatipodoc.docu2 == "si") && <> <Col sm={2}>
                                    <FormGroup>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="captiondoc2" value={Fatipodoc.captiondoc2} />
                                    </FormGroup>
                                </Col>
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Input
                                                type='checkbox'
                                            // checked={(Fatipodoc.mvendedor == "si") ? true : false}
                                            // value={Fatipodoc.mvendedor}
                                            // name="mvendedor"
                                            // onClick={(e) => {
                                            //     handleChangeswitch(e)
                                            // }}
                                            />
                                            <Label check>Documento 2 Fijo</Label>
                                            {/* <Label style={{ fontSize: '13px' }}>Activar Vendedor</Label>
                                    <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mvendedor" value={Fatipodoc.mvendedor}  >
                                        <option value="no">No</option>
                                        <option value="si">Si</option>
                                    </Input> */}
                                        </FormGroup>
                                    </Col></>}

                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.observa == "si") ? true : false}
                                            value={Fatipodoc.observa}
                                            name="observa"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Observaciones</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Observaciones</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="observa" value={Fatipodoc.observa}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {/* <Col sm={2}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Varias Lineas</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="obslinea" value={Fatipodoc.obslinea}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.mdevolucio == "si") ? true : false}
                                            value={Fatipodoc.mdevolucio}
                                            name="mdevolucio"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Devolucion</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Perm. Devoluc</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mdevolucio" value={Fatipodoc.mdevolucio}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.mediospago == "si") ? true : false}
                                            value={Fatipodoc.mediospago}
                                            name="mediospago"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Mod. Medios de P.</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Mod. Medios de P.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="mediospago" value={Fatipodoc.mediospago}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.puntoventa == "si") ? true : false}
                                            value={Fatipodoc.puntoventa}
                                            name="puntoventa"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>POS con graficos</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Punto de Venta</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="puntoventa" value={Fatipodoc.puntoventa}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.busplu == "si") ? true : false}
                                            value={Fatipodoc.busplu}
                                            name="busplu"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>POS normal</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Punto de Venta</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="puntoventa" value={Fatipodoc.puntoventa}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.pass1 == "si") ? true : false}
                                            value={Fatipodoc.pass1}
                                            name="pass1"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Replicar Cuenta Primer Item</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Punto de Venta</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="puntoventa" value={Fatipodoc.puntoventa}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.pass2 == "si") ? true : false}
                                            value={Fatipodoc.pass2}
                                            name="pass2"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Remision de Venta</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Punto de Venta</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="puntoventa" value={Fatipodoc.puntoventa}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            // checked={(Fatipodoc.puntoventa == "si") ? true : false}
                                            // value={Fatipodoc.puntoventa}
                                            // name="puntoventa"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Cuadro de Medios de Pago</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Punto de Venta</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="puntoventa" value={Fatipodoc.puntoventa}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.pass3 == "si") ? true : false}
                                            value={Fatipodoc.pass3}
                                            name="pass3"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Corresponsal</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Punto de Venta</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="puntoventa" value={Fatipodoc.puntoventa}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.pass4 == "si") ? true : false}
                                            value={Fatipodoc.pass4}
                                            name="pass4"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>PLU con codigo y cantidad</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Punto de Venta</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="puntoventa" value={Fatipodoc.puntoventa}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                {Fatipodoc.pass4 == "si" &&
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label style={{ fontSize: '13px' }} >Cantidad de Digitos del Codigo</Label>
                                            <Input type="text" bsSize="sm" onChange={handleChange} name="pass5" value={Fatipodoc.pass5} />
                                        </FormGroup>
                                    </Col>

                                }

                                <Col sm={3}>
                                    <FormGroup>
                                        {/* <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.modidoc == "si") ? true : false}
                                            value={Fatipodoc.modidoc}
                                            name="modidoc"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>PDF Factura</Label> */}
                                        <Label style={{ fontSize: '13px' }}>PDF Factura</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="modidoc" value={Fatipodoc.modidoc}  >
                                            <option value="electronica 1">Electronica 1</option>
                                            <option value="electronica 2">Electronica 2</option>
                                            <option value="electronica 3">Electronica 3</option>
                                            <option value="electronica 4">Electronica 4</option>
                                            <option value="electronica 5">Electronica 5</option>
                                            <option value="electronica 5 responsable de iva">Electronica 5 responsable de iva</option>

                                            <option value="electronica 6">Electronica 6</option>
                                            <option value="electronica 7">Electronica 7</option>
                                            <option value="electronica 8">Electronica 8</option>

                                            <option value="tirilla 1">Tirilla 1</option>
                                            <option value="tirilla 2">Tirilla 2</option>
                                            <option value="tirilla 4">Tirilla 4</option>
                                            {/* tirilla 4 es mas angosta */}
                                            <option value="tirilla 5">Tirilla 5</option>
                                            <option value="tirilla 6">Tirilla 6</option>
                                            <option value="tirilla 7">Tirilla 7</option>
                                            <option value="tirilla 7 electronica">Tirilla 7 Electronica</option>
                                            <option value="tirilla 8">Tirilla 8</option>
                                            <option value="tirilla 9">Tirilla 9</option>
                                            <option value="tirilla 10">Tirilla 10</option>
                                            <option value="tirilla 11">Tirilla 11</option>
                                            <option value="tirilla 12">Tirilla 12</option> {/* tirilla 1 sin resolucion */}
                                            <option value="tirilla 13">Tirilla 13</option> {/* tirilla 11 con descriopcion adicional */}
                                            <option value="tirilla 14">Tirilla 14</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 15">Tirilla 15</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 16">Tirilla 16</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 17">Tirilla 17</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 18">Tirilla 18</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 19">Tirilla 19</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 1 Electronica">Tirilla 1 Electronica</option>
                                            <option value="tirilla transporte 1">Tirilla transporte 1</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla Electronica transporte 1">Tirilla Electronica transporte 1</option> {/* tirilla 11 con qr */}
                                            <option value="carta 1">Carta 1</option>
                                            <option value="carta 2">Carta 2</option>
                                            <option value="carta 3">Carta 3</option>
                                            <option value="carta 3 lote">Carta 3 lote</option>

                                            <option value="carta 4">Carta 4</option>
                                            <option value="carta 5">Carta 5</option>
                                            <option value="carta 6">Carta 6</option>
                                            <option value="carta 7">Carta 7</option>
                                            <option value="carta 8">Carta 8</option>
                                            <option value="carta 9">Carta 9</option> {/* carta 5 pero mas grande */}

                                            <option value="comanda 1">Comanda 1</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        {/* <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.modidoc == "si") ? true : false}
                                            value={Fatipodoc.modidoc}
                                            name="modidoc"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>PDF Factura</Label> */}
                                        <Label style={{ fontSize: '13px' }}>PDF Factura 2</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="allinformes" value={Fatipodoc.allinformes}  >
                                            <option value="electronica 1">Electronica 1</option>
                                            <option value="electronica 2">Electronica 2</option>
                                            <option value="electronica 3">Electronica 3</option>
                                            <option value="electronica 4">Electronica 4</option>
                                            <option value="electronica 5">Electronica 5</option>
                                            <option value="electronica 5 responsable de iva">Electronica 5 responsable de iva</option>

                                            <option value="electronica 6">Electronica 6</option>
                                            <option value="electronica 7">Electronica 7</option>
                                            <option value="electronica 8">Electronica 8</option>

                                            <option value="tirilla 1">Tirilla 1</option>
                                            <option value="tirilla 2">Tirilla 2</option>
                                            <option value="tirilla 4">Tirilla 4</option>
                                            {/* tirilla 4 es mas angosta */}
                                            <option value="tirilla 5">Tirilla 5</option>
                                            <option value="tirilla 6">Tirilla 6</option>
                                            <option value="tirilla 7">Tirilla 7</option>
                                            <option value="tirilla 8">Tirilla 8</option>
                                            <option value="tirilla 9">Tirilla 9</option>
                                            <option value="tirilla 10">Tirilla 10</option>
                                            <option value="tirilla 11">Tirilla 11</option>
                                            <option value="tirilla 12">Tirilla 12</option> {/* tirilla 1 sin resolucion */}
                                            <option value="tirilla 13">Tirilla 13</option> {/* tirilla 11 con descriopcion adicional */}
                                            <option value="tirilla 14">Tirilla 14</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 15">Tirilla 15</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 16">Tirilla 16</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 17">Tirilla 17</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 18">Tirilla 18</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 19">Tirilla 19</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla 1 Electronica">Tirilla 1 Electronica</option>
                                            <option value="tirilla transporte 1">Tirilla transporte 1</option> {/* tirilla 11 con qr */}
                                            <option value="tirilla Electronica transporte 1">Tirilla Electronica transporte 1</option> {/* tirilla 11 con qr */}
                                            <option value="carta 1">Carta 1</option>
                                            <option value="carta 2">Carta 2</option>
                                            <option value="carta 3">Carta 3</option>
                                            <option value="carta 3 lote">Carta 3 lote</option>

                                            <option value="carta 4">Carta 4</option>
                                            <option value="carta 5">Carta 5</option>
                                            <option value="carta 6">Carta 6</option>
                                            <option value="carta 7">Carta 7</option>
                                            <option value="carta 8">Carta 8</option>
                                            <option value="carta 9">Carta 9</option> {/* carta 5 pero mas grande */}

                                            <option value="comanda 1">Comanda 1</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Plantilla Personalizada</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="plantilladoc" value={Fatipodoc.plantilladoc}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>


                            </Row>
                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.noimpdupl == "si") ? true : false}
                                            value={Fatipodoc.noimpdupl}
                                            name="noimpdupl"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Plan Separe</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Imp. Dupli.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="noimpdupl" value={Fatipodoc.noimpdupl}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.rentabilid == "si") ? true : false}
                                            value={Fatipodoc.rentabilid}
                                            name="rentabilid"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Rent. en Línea</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Rent. en Línea</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="rentabilid" value={Fatipodoc.rentabilid}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.redondeofac == "si") ? true : false}
                                            value={Fatipodoc.redondeofac}
                                            name="redondeofac"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Sumar al Costo Impo/ICUA</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Redondea Totales</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="redondeofac" value={Fatipodoc.redondeofac}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.modidocenc == "si") ? true : false}
                                            disabled={(Fatipodoc.imptosn == "si") ? true : false}
                                            value={Fatipodoc.modidocenc}
                                            name="modidocenc"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>ImpoConsumo Por Valor</Label>
                                        {/* <Label style={{ fontSize: '13px' }}>Mod. Bloq. Enca.</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="modidocenc" value={Fatipodoc.modidocenc}  >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input> */}
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.manejatercerounico == "si") ? true : false}
                                            value={Fatipodoc.manejatercerounico}
                                            name="manejatercerounico"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Maneja Tercero Unico</Label>

                                    </FormGroup>
                                </Col>
                                {Fatipodoc.manejatercerounico == "si" &&
                                    <Col sm={3}>
                                        <FormGroup>
                                            <Input type="text" bsSize="sm" onChange={handleChange} name="tercerounico" value={Fatipodoc.tercerounico} />

                                            <Label check>Tercero Unico</Label>

                                        </FormGroup>
                                    </Col>
                                }
                                <Col sm={3}>
                                    <FormGroup>
                                        <Input
                                            type='checkbox'
                                            checked={(Fatipodoc.centrodecostoobligatorio == "si") ? true : false}
                                            value={Fatipodoc.centrodecostoobligatorio}
                                            name="centrodecostoobligatorio"
                                            onClick={(e) => {
                                                handleChangeswitch(e)
                                            }}
                                        />
                                        <Label check>Centro de Costo Obligatorio</Label>

                                    </FormGroup>
                                </Col>
                            </Row>

                        </Tab>
                        <Tab eventKey="Resolucion" title="Resolucion">
                            <Row className="ml-3">
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Id SoftWare</Label>
                                        <Input onChange={handleChange} value={Fatipodoc.identificadorsw} type="text" bsSize="sm" name="identificadorsw" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Numero</Label>
                                        <Input onBlur={(e) => {
                                            obtenerRangos('')
                                        }} onChange={handleChange} value={Fatipodoc.num_res} type="text" bsSize="sm" name="num_res" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Inicio</Label>
                                        <Input disabled={(Fatipodoc.facturapap == "electronica" && Fatipodoc.ambiente == "1")} onChange={handleChange} value={Fatipodoc.inicresolu} type="date" bsSize="sm" name="inicresolu" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Fin</Label>
                                        <Input disabled={Fatipodoc.facturapap == "electronica" && Fatipodoc.ambiente == "1"} onChange={handleChange} value={Fatipodoc.vencresolu} type="date" bsSize="sm" name="vencresolu" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Prefijo</Label>
                                        <Input disabled={Fatipodoc.facturapap == "electronica" && Fatipodoc.ambiente == "1"} onChange={handleChange} value={Fatipodoc.prefijo} type="text" bsSize="sm" name="prefijo" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Inicio Prefijo</Label>
                                        <Input disabled={Fatipodoc.facturapap == "electronica" && Fatipodoc.ambiente == "1"} onChange={handleChange} value={Fatipodoc.rango_res_i} type="text" bsSize="sm" name="rango_res_i" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fin Prefijo</Label>
                                        <Input disabled={Fatipodoc.facturapap == "electronica" && Fatipodoc.ambiente == "1"} onChange={handleChange} value={Fatipodoc.rango_res_f} type="text" bsSize="sm" name="rango_res_f" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Avisar</Label>
                                        <Input onChange={handleChange} value={Fatipodoc.vencresoludias} type="text" bsSize="sm" name="vencresoludias" />
                                        <Label>Dias Antes</Label>
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label style={{ fontSize: '13px' }}>Ambiente</Label>
                                        <Input type="select" onChange={handleChange} style={{ fontSize: '13px' }} name="ambiente" value={Fatipodoc.ambiente}  >
                                            <option value="1">Produccion</option>
                                            <option value="2">Pruebas</option>
                                        </Input>
                                    </FormGroup>
                                </Col>

                                <Col sm={7}>
                                    <FormGroup>
                                        <Label>Clave Tecnica</Label>
                                        <Input disabled={Fatipodoc.facturapap == "electronica"} onChange={handleChange} value={Fatipodoc.clave_tec} type="text" bsSize="sm" name="clave_tec" />
                                    </FormGroup>
                                </Col>
                                {
                                    Fatipodoc.compañia != "" && Fatipodoc.facturapap == "electronica" &&
                                    <Col sm={2}>
                                        <FormGroup>
                                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                                obtenerRangossucursal()
                                            }} >Sucursal</Button>
                                        </FormGroup>
                                    </Col>
                                }


                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Pin</Label>
                                        <Input onChange={handleChange} value={Fatipodoc.pin} type="text" bsSize="sm" name="pin" />
                                    </FormGroup>
                                </Col>
                                <Col sm={7}>
                                    <FormGroup>
                                        <Label>Set Pruebas</Label>
                                        <Input onChange={handleChange} value={Fatipodoc.setpruebas} type="text" bsSize="sm" name="setpruebas" />
                                    </FormGroup>
                                </Col>
                            </Row>



                        </Tab>
                    </ Tabs >
                    <Buscar tituloencabezado={"Buscar Bodega"} filtrar={(coincidencia) => { filtrarbodegas(coincidencia) }} busqueda={busquedabodegas} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalbodegas} cerrarModal={() => { cerrarModalbodegas() }} seleccionar={(seleccionado) => {
                        seleccionarbodegas(seleccionado)
                    }} />
                    <Buscar tituloencabezado={"Buscar Centro de Costo"} filtrar={(coincidencia) => { filtrarccostos(coincidencia) }} busqueda={busquedaccostos} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalccostos} cerrarModal={() => { cerrarModalccostos() }} seleccionar={(seleccionado) => {
                        seleccionarccostos(seleccionado)
                    }} />
                    <Buscar tituloencabezado={"Buscar Lista de Precio"} filtrar={(coincidencia) => { filtrarprecsugs(coincidencia) }} busqueda={busquedaprecsugs} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalprecsug} cerrarModal={() => { cerrarModalSeleccionprecsugs() }} seleccionar={(seleccionado) => {
                        seleccionarprecsug(seleccionado.codigo)
                    }} />
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="primary" onClick={utilizarplantilla}>Utilizar Plantilla</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalFatipodoc}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Fatipodoc;
