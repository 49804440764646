import React, { useContext } from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"

import { Link, NavLink } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';
import { MensajeContext } from "../context/MensajesProvider";
import { PermisoContext } from "../context/PermisosProvider";

import Icono from '../views/images/logodashboardsinletras.png'
import Swal from 'sweetalert2'
// import { display } from 'html2canvas/dist/types/css/property-descriptors/display';

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}
const modelomodulo = {


}

const NavBar = (props) => {

    const { user, cerrarSession } = useContext(UserContext);
    const { mensajelocal, borrarMensajeslocal } = useContext(MensajeContext)
    const { permisos, borrarPermisos } = useContext(PermisoContext)

    const [Icono2, seticono2] = useState('./images/blanco.png');
    const fileInput = useRef(null)
    const [dataUser, setDataUser] = useState(modelo)
    const [permisosusuario, setpermisosusuario] = useState(modelomodulo)
    const [modulos, setmodulos] = useState({})
    const [logo, setlogo] = useState("")
    const mostrarModalSalir = () => {

        Swal.fire({
            title: 'Esta por salir',
            text: "Desea cerrar sesion?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {
                cerrarSession()
                borrarMensajeslocal()
                borrarPermisos()
            }
        })

    }
    useEffect(() => {
        let dt = JSON.parse(user)
        let dt2 = JSON.parse(permisos)
        setDataUser(dt)
        setpermisosusuario(dt2)
    }, [])


    return (

        <ul className="navbar-nav  sidebar sidebar-dark accordion" style={{ background: '#188BAD' }} id="accordionSidebar">
            <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                <div className="sidebar-brand-icon">

                    <img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Logo" className="img-fluid mt-1" style={{ width: '100px', height: 'auto' }} />
                </div>
                <div className="sidebar-brand-text mx-3" style={{ display: 'flex', flexDirection: 'column' }}>
                    <span style={{ fontSize: '11px' }}>{dataUser.empresa.empresa.nombre}</span>
                    <span onClick={(e) => {
                    }} style={{ fontSize: '6px', textAlign: 'center', marginTop: '8px' }}>{"Generado por : Solifactin "} <img src={Icono} alt="Second Logo" style={{ width: '20px', height: 'auto', marginLeft: '5px' }} /></span>

                </div>
            </Link>

            {/* <div style={{ color: 'white', textAlign: 'center' }}>{dataUser.empresa.empresa.nombre}</div> */}

            {/* <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                <div className="sidebar-brand-icon">
                    <i className="fas fa-desktop"></i> 
                    <img src={Icono} alt="Image" className="img-fluid mt-1" />
                </div>
                <div className="sidebar-brand-text mx-3" >Factin</div>
            </Link> */}

            <hr className="sidebar-divider my-0" />


            {
                (true) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePerfil"
                        aria-expanded="true" aria-controls="collapsePerfil">
                        <i className="fas fa-fw fa-user"></i>
                        <span>{dataUser.usuario.nombre}</span>
                    </a>
                    <div id="collapsePerfil" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href="#" className="collapse-item" onClick={() => { mostrarModalSalir() }}>Salir</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setsoportesringcall(true) }}>✅Tu Solución a un Clic 👆</a>

                            {/* <a onClick={() => { props.setchat(true) }} href="#" className="collapse-item">Chat</a> */}
                            {/* <a onClick={() => { props.setcorreo(true) }} href="#" className="collapse-item">Correo</a> */}
                            {/* <a onClick={() => { props.setconcorreo(true) }} href="#" className="collapse-item">Creacion Correo</a> */}
                        </div>
                    </div>
                </li>
            }






            {
                (dataUser.empresa.modulos.MAESTROS.adicionar || dataUser.empresa.modulos.MAESTROS.buscar) &&
                permisosusuario.MAESTROS && (permisosusuario.MAESTROS.adicionar || permisosusuario.MAESTROS.buscar) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseMaestro"
                        aria-expanded="true" aria-controls="collapseMaestro">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Maestro</span>
                    </a>
                    <div id="collapseMaestro" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded" >
                            <a onClick={() => { props.setempresa(true) }} href="#" className="collapse-item">Empresas</a>
                            <a onClick={() => { props.setgrupo(true) }} href="#" className="collapse-item">Grupos</a>
                            <a onClick={() => { props.setusuario(true) }} href="#" className="collapse-item">Usuarios</a>
                            <a onClick={() => { props.setusuario(true) }} href="#" className="collapse-item">Ring Call</a>
                        </div>
                    </div>
                </li>
            }

            {
                (false) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseRingCall"
                        aria-expanded="true" aria-controls="collapseRingCall">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Ring Call</span>
                    </a>
                    <div id="collapseRingCall" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded" >
                            <a onClick={() => { props.setringcallrecepcion(true) }} href="#" className="collapse-item">Ring Call Recepcion</a>
                            <a onClick={() => { props.setgrupo(true) }} href="#" className="collapse-item">Ring Call Asesor</a>
                            <a onClick={() => { props.setusuario(true) }} href="#" className="collapse-item">Informes</a>
                        </div>
                    </div>
                </li>
            }

            {
                dataUser.empresa.modulos.RESTAURANTE && dataUser.empresa.modulos.RESTAURANTE.adicionar &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseRestaurante"
                        aria-expanded="true" aria-controls="collapseRestaurante">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Restaurante</span>
                    </a>
                    <div id="collapseRestaurante" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded" >
                            <a onClick={() => { props.setrestauranteordendomicilio(true) }} href="#" className="collapse-item">Tomar Pedido</a>
                            <a onClick={() => { props.setrestauranteordenordenes(true) }} href="#" className="collapse-item">Comandas</a>
                            <a onClick={() => { props.setrestauranteordenmesas(true) }} href="#" className="collapse-item">Mesas</a>
                            <a onClick={() => { props.setrestauranteordenordenespormesero(true) }} href="#" className="collapse-item">Ordenes Mesero</a>
                            <a onClick={() => { props.setcierrecajarestaurante(true) }} href="#" className="collapse-item">Cierre Caja R</a>


                        </div>
                    </div>
                </li>
            }
            {(dataUser.empresa.modulos.FACTURA.adicionar || dataUser.empresa.modulos.FACTURA.buscar || dataUser.empresa.modulos.COMPRA.adicionar || dataUser.empresa.modulos.COMPRA.buscar) &&
                permisosusuario.FACTURA && (permisosusuario.FACTURA.adicionar || permisosusuario.FACTURA.buscar || permisosusuario.COMPRA.adicionar || permisosusuario.COMPRA.buscar) &&

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseVenta"
                        aria-expanded="true" aria-controls="collapseVenta">
                        <i className="fas fa-fw fa-tag"></i>
                        <span>Movimientos</span>
                    </a>
                    <div id="collapseVenta" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href="#" className="collapse-item" onClick={() => { props.setventa(true) }}>Nuevo Movimiento</a>
                            <a onClick={() => { props.setrestauranteordendomicilio(true) }} href="#" className="collapse-item">Movimiento Pos</a>

                            {/* <a href="#" className="collapse-item" onClick={() => { props.setventadrogueria(true) }}>Nuevo Movimiento Dro</a> */}

                            {/* <a href="#" className="collapse-item" onClick={() => { props.sethistorialventa(true) }}>Historial</a> */}
                            <a href="#" className="collapse-item" onClick={() => { props.setcierrecaja(true) }}>Cierre Caja</a>
                            {/* <a href="#" className="collapse-item" onClick={() => { props.setradian(true) }}>Radian</a> */}
                            <a href="#" className="collapse-item" onClick={() => { props.setturnos(true) }}>Turnos</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setenviobloquedian(true) }}>Envio Bloque Dian</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setbono(true) }}>Bonos</a>

                            {/* <a href="#" className="collapse-item" onClick={() => { props.setventagasbloque(true) }}>Ventas Gas Bloque</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setventagasbloqueactualizar(true) }}>Actualizar Factura Gas</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setgasparametrofactura(true) }}>Parametrizacion Gas</a> */}

                        </div>
                    </div>
                </li>}
            {(dataUser.empresa.modulos.CARTERA.adicionar || dataUser.empresa.modulos.CARTERA.buscar) &&
                permisosusuario.CARTERA && (permisosusuario.CARTERA.adicionar || permisosusuario.CARTERA.buscar) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCartera"
                        aria-expanded="true" aria-controls="collapseCartera">
                        <i className="fas fa-fw fa-tag"></i>
                        <span>Cartera</span>
                    </a>
                    <div id="collapseCartera" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href="#" className="collapse-item" onClick={() => { props.setctabonos(true) }}>Nuevo Movimiento</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setcruceanticipo(true) }}>Cruce</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setctabonosglobal(true) }}>Global</a>

                        </div>
                    </div>
                </li>}
            {(dataUser.empresa.modulos.TESORERIA.adicionar || dataUser.empresa.modulos.TESORERIA.buscar) &&
                permisosusuario.TESORERIA && (permisosusuario.TESORERIA.adicionar || permisosusuario.TESORERIA.buscar) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTesoreria"
                        aria-expanded="true" aria-controls="collapseTesoreria">
                        <i className="fas fa-fw fa-tag"></i>
                        <span>Tesoreria</span>
                    </a>
                    <div id="collapseTesoreria" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href="#" className="collapse-item" onClick={() => { props.settesoreria(true) }}>Nuevo Movimiento</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setcruceanticipotesoreria(true) }}>Cruce Anticipos Proveedor</a>
                            {/* <a href="#" className="collapse-item" onClick={() => { props.sethistorialventa(true) }}>Historial Cuentas por Pagar</a> */}
                        </div>
                    </div>
                </li>}
            {
                (dataUser.empresa.modulos.CONTABILIDAD.adicionar || dataUser.empresa.modulos.CONTABILIDAD.buscar) &&
                permisosusuario.CONTABILIDAD && (permisosusuario.CONTABILIDAD.adicionar || permisosusuario.CONTABILIDAD.buscar) &&
                // (true) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBancos"
                        aria-expanded="true" aria-controls="collapseBancos">
                        <i className="fas fa-fw fa-box"></i>
                        <span>Contabilidad</span>
                    </a>
                    <div id="collapseBancos" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a onClick={() => { props.setcontabilidad(true) }} href="#" className="collapse-item"  >Nuevo Movimiento</a>
                            <a onClick={() => { props.setmodeloscontables(true) }} href="#" className="collapse-item"  >Modelos Contables</a>
                            <a onClick={() => { props.setplandecuentas(true) }} href="#" className="collapse-item"  >Plan de Cuentas</a>
                            <a onClick={() => { props.setcomprobantescontabilidad(true) }} href="#" className="collapse-item"  >Comprobantes</a>

                            <a onClick={() => { props.setcierreanual(true) }} href="#" className="collapse-item"  >Cierre Anual</a>
                            <a onClick={() => { props.setcociliancionbancaria(true) }} href="#" className="collapse-item"  >Conciliacion Bancaria</a>
                            <a onClick={() => { props.setexogena(true) }} href="#" className="collapse-item"  >Exógena</a>
                            {/* <a href="#" className="collapse-item" onClick={() => { props.setcategoria(true) }}>Categorias</a> */}
                            {/* <a onClick={() => { props.setmodeloarticuloccosto(true) }} href="#" className="collapse-item"  >Modelo Articulo Ccosto</a> */}
                            {/* <a onClick={() => { props.setperiodoscontabilidad(true) }} href="#" className="collapse-item"  >Periodos</a> */}
                            {/* <a onClick={() => { props.setabrircerrarperiodo(true) }} href="#" className="collapse-item"  >Abrir/Cerrar Periodo</a> */}

                        </div>
                    </div>
                </li>
            }
            {(dataUser.empresa.modulos.NOMINA.adicionar || dataUser.empresa.modulos.NOMINA.buscar) &&
                permisosusuario.NOMINA && (permisosusuario.NOMINA.adicionar || permisosusuario.NOMINA.buscar) &&

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseNominaMaestros"
                        aria-expanded="true" aria-controls="collapseNominaMaestros">
                        <i className="fas fa-fw fa-tag"></i>
                        <span>Maestros Nomina</span>
                    </a>
                    <div id="collapseNominaMaestros" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href="#" className="collapse-item" onClick={() => { props.setempleados(true) }}>Empleados</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setcentrosdetrabajo(true) }}>Centros de Trabajo</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setconceptosnomina(true) }}>Conceptos</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setdevdecfijas(true) }}>Deven y Deduc Fijas</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setdivisiones(true) }}>Divisiones</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setoficios(true) }}>Oficios</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setprofesiones(true) }}>Profesiones</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setsecciones(true) }}>Secciones</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setsucursalesnomina(true) }}>Sucursales</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setadministradoranomina(true) }}>Administradoras</a>

                        </div>
                    </div>
                </li>}
            {(dataUser.empresa.modulos.NOMINA.adicionar || dataUser.empresa.modulos.NOMINA.buscar) &&
                permisosusuario.NOMINA && (permisosusuario.NOMINA.adicionar || permisosusuario.NOMINA.buscar) &&

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseNominaProcesos"
                        aria-expanded="true" aria-controls="collapseNominaProcesos">
                        <i className="fas fa-fw fa-tag"></i>
                        <span>Procesos Nomina</span>
                    </a>
                    <div id="collapseNominaProcesos" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href="#" className="collapse-item" onClick={() => { props.setnovedadesnomina(true) }}>Novedades</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setmaestrosnomina(true) }}>Maestros</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setprocesosnomina(true) }}>Procesos</a>
                            <a href="#" className="collapse-item" onClick={() => { }}>Modelos Contables</a>
                            <a href="#" className="collapse-item" onClick={() => { }}>Acumulados</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setprestacionessocialesnomina(true) }}>Prestaciones Sociales</a>
                            <a href="#" className="collapse-item" onClick={() => { }}>Hoja de Vida</a>
                            <a href="#" className="collapse-item" onClick={() => { }}>Envio Dian</a>
                        </div>
                    </div>
                </li>}
            {
                (dataUser.empresa.modulos.ARTICULOS.adicionar || dataUser.empresa.modulos.ARTICULOS.buscar) &&
                permisosusuario.ARTICULOS && (permisosusuario.ARTICULOS.adicionar || permisosusuario.ARTICULOS.buscar) &&

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseInventario"
                        aria-expanded="true" aria-controls="collapseInventario">
                        <i className="fas fa-fw fa-box"></i>
                        <span>Inventario</span>
                    </a>
                    <div id="collapseInventario" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a onClick={() => { props.setproducto(true) }} href="#" className="collapse-item"  >Productos</a>
                            {/* <a href="#" className="collapse-item" onClick={() => { props.setconclasiarticulos(true) }}>Nombre Clasificaciones</a> */}
                            <a href="#" className="collapse-item" onClick={() => { props.setclasiarticulos(true) }}>Clasificaciones</a>
                            {permisosusuario.INVENTARIO.electronico &&
                                <a href="#" className="collapse-item" onClick={() => { props.setinventariofisico(true) }}>Inventario Fisico</a>

                            }
                            {/* <a href="#" className="collapse-item" onClick={() => { }}>Motivos Descuento</a> */}
                        </div>
                    </div>
                </li>
            }
            {
                permisosusuario.TERCEROS && permisosusuario.TERCEROS.adicionar ?
                    <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTerceros"
                            aria-expanded="true" aria-controls="collapseTerceros">
                            <i className="fas fa-fw fa-box"></i>
                            <span>Terceros</span>
                        </a>
                        <div id="collapseTerceros" className="collapse" aria-labelledby="headingUtilities"
                            data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <a onClick={() => { props.setcreartercero(true) }} href="#" className="collapse-item"  >Terceros</a>
                                {/* <a onClick={() => { props.setconclasiterceros(true) }} href="#" className="collapse-item"  >Nombres Clasificaciones</a> */}
                                <a onClick={() => { props.setclasiterceros(true) }} href="#" className="collapse-item"  >Clasificaciones</a>
                                {/* <a onClick={() => { props.settercerosdatosadicionales(true) }} href="#" className="collapse-item"  >Adicionales</a> */}
                                {/* <a onClick={() => { props.settercerosgas(true) }} href="#" className="collapse-item"  >Terceros Gas</a> */}


                                {/* <a href="#" className="collapse-item" onClick={() => { props.setcategoria(true) }}>Categorias</a> */}
                            </div>
                        </div>
                    </li> : !(permisosusuario.TERCEROS) && <li className="nav-item">
                        <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTerceros"
                            aria-expanded="true" aria-controls="collapseTerceros">
                            <i className="fas fa-fw fa-box"></i>
                            <span>Terceros</span>
                        </a>
                        <div id="collapseTerceros" className="collapse" aria-labelledby="headingUtilities"
                            data-parent="#accordionSidebar">
                            <div className="bg-white py-2 collapse-inner rounded">
                                <a onClick={() => { props.setcreartercero(true) }} href="#" className="collapse-item"  >Terceros</a>
                                {/* <a onClick={() => { props.setconclasiterceros(true) }} href="#" className="collapse-item"  >Nombres Clasificaciones</a> */}
                                <a onClick={() => { props.setclasiterceros(true) }} href="#" className="collapse-item"  >Clasificaciones</a>
                                {/* <a onClick={() => { props.settercerosdatosadicionales(true) }} href="#" className="collapse-item"  >Adicionales</a> */}
                                {/* <a onClick={() => { props.settercerosgas(true) }} href="#" className="collapse-item"  >Terceros Gas</a> */}


                                {/* <a href="#" className="collapse-item" onClick={() => { props.setcategoria(true) }}>Categorias</a> */}
                            </div>
                        </div>
                    </li>
            }
            {permisosusuario.INFORMES && permisosusuario.INFORMES.adicionar ?
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReporte"
                        aria-expanded="true" aria-controls="collapseReporte">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Informes</span>
                    </a>
                    <div id="collapseReporte" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href="#" className="collapse-item" onClick={() => { props.setreportes(true) }}>Informes Generales</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setreportescompras(true) }}>Informes Compras</a>
                        </div>
                    </div>
                </li> : !(permisosusuario.INFORMES) && <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseReporte"
                        aria-expanded="true" aria-controls="collapseReporte">
                        <i className="fas fa-fw fa-chart-area"></i>
                        <span>Informes</span>
                    </a>
                    <div id="collapseReporte" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a href="#" className="collapse-item" onClick={() => { props.setreportes(true) }}>Informes Generales</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setreportescompras(true) }}>Informes Compras</a>
                        </div>
                    </div>
                </li>
            }
            {
                (dataUser.empresa.modulos.DATOSBASICOS.adicionar || dataUser.empresa.modulos.DATOSBASICOS.buscar) &&
                permisosusuario.DATOSBASICOS && (permisosusuario.DATOSBASICOS.adicionar || permisosusuario.DATOSBASICOS.buscar) &&

                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsedatosbasicos"
                        aria-expanded="true" aria-controls="collapsedatosbasicos">
                        <i className="fas fa-fw fa-box"></i>
                        <span>Datos Basicos</span>
                    </a>
                    <div id="collapsedatosbasicos" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a onClick={() => { props.setcreartipodocumento(true) }} href="#" className="collapse-item"  >Tipos de Documento</a>
                            <a onClick={() => { props.setcrearvendedor(true) }} href="#" className="collapse-item"  >Vendedores</a>
                            <a onClick={() => { props.setcrearbodega(true) }} href="#" className="collapse-item"  >Bodega</a>
                            <a onClick={() => { props.setcrearcentrodecosto(true) }} href="#" className="collapse-item"  >Centro de Costo</a>
                            <a onClick={() => { props.setbanco(true) }} href="#" className="collapse-item"  >Bancos</a>
                            <a onClick={() => { props.setsucursal(true) }} href="#" className="collapse-item"  >Sucursal</a>
                            <a onClick={() => { props.setcrearlistadeprecio(true) }} href="#" className="collapse-item"  >Lista de Precio</a>
                            <a onClick={() => { props.setcrearpais(true) }} href="#" className="collapse-item"  >Paises</a>
                            <a onClick={() => { props.setcreardepartamento(true) }} href="#" className="collapse-item"  >Departamentos</a>
                            <a onClick={() => { props.setcrearmunicipio(true) }} href="#" className="collapse-item"  >Municipios</a>

                        </div>
                    </div>
                </li>
            }
            {
                (true) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseHerramientas"
                        aria-expanded="true" aria-controls="collapseHerramientas">
                        <i className="fas fa-fw fa-user"></i>
                        <span>Configuracion</span>
                    </a>
                    <div id="collapseHerramientas" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <a onClick={() => { props.setinicio(true) }} href="#" className="collapse-item">Perfil</a>

                            <a href="#" className="collapse-item" onClick={() => { props.setcrearmediodepago(true) }}>Medios de Pago</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setcrearmediodepagodian(true) }}>Medios de Pago Dian</a>
                            <a onClick={() => { props.setreconstruircontabilidad(true) }} href="#" className="collapse-item"  >Reconstruir</a>
                            <a onClick={() => { props.setconplantillainforme(true) }} href="#" className="collapse-item"  >Configuracion Plantilla</a>
                            <a onClick={() => { props.setpantillacont(true) }} href="#" className="collapse-item"  >Plantilla PYG</a>
                            <a onClick={() => { props.setplantillapdf(true) }} href="#" className="collapse-item"  >Plantilla PDF</a>

                            {
                                (dataUser.empresa.modulos.ADMINISTRACION.adicionar || dataUser.empresa.modulos.ADMINISTRACION.buscar) &&
                                permisosusuario.ADMINISTRACION && (permisosusuario.ADMINISTRACION.adicionar || permisosusuario.ADMINISTRACION.buscar) &&
                                <>
                                    <a onClick={() => {
                                        props.setusuariounico(true)
                                        props.setusuario(true)
                                    }} href="#" className="collapse-item">Usuarios</a>
                                    {/* <a onClick={() => { props.setusuario(true) }} href="#" className="collapse-item">Ring Call</a> */}
                                    {/* <a onClick={() => { props.setdashboard(true) }} href="#" className="collapse-item">Historicos</a> */}
                                    <a onClick={() => {
                                        props.setgrupo(true)
                                        props.setusuariounico(true)
                                    }} href="#" className="collapse-item">Grupos</a>
                                    <a onClick={() => {
                                        props.setpermisosdetallado(true)
                                        props.setusuariounico(true)
                                    }} href="#" className="collapse-item">Permisos Detallados</a>
                                </>
                            }

                            <a href="#" className="collapse-item" onClick={() => { props.setimportarexportar(true) }}>Importar/Exportar</a>
                            <a href="#" className="collapse-item" onClick={() => { props.setbackup(true) }}>Copia de Soporte</a>

                        </div>
                    </div>
                </li>
            }
            {/* {
                (dataUser.empresa.modulos.ADMINISTRACION.adicionar || dataUser.empresa.modulos.ADMINISTRACION.buscar) &&
                permisosusuario.ADMINISTRACION && (permisosusuario.ADMINISTRACION.adicionar || permisosusuario.ADMINISTRACION.buscar) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUsuario"
                        aria-expanded="true" aria-controls="collapseUsuario">
                        <i className="fas fa-fw fa-cog"></i>
                        <span>Administracion</span>
                    </a>
                    <div id="collapseUsuario" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded" >
                            <a onClick={() => {
                                props.setusuariounico(true)
                                props.setusuario(true)
                            }} href="#" className="collapse-item">Usuarios</a>
                          
                            <a onClick={() => {
                                props.setgrupo(true)
                                props.setusuariounico(true)
                            }} href="#" className="collapse-item">Grupos</a>
                            <a onClick={() => {
                                props.setpermisosdetallado(true)
                                props.setusuariounico(true)
                            }} href="#" className="collapse-item">Permisos Detallados</a>
                        </div>
                    </div>
                </li>
            } */}







            {/* {
                (dataUser.empresa.modulos.CONTABILIDAD.adicionar || dataUser.empresa.modulos.CONTABILIDAD.buscar) &&
                permisosusuario.CONTABILIDAD && (permisosusuario.CONTABILIDAD.adicionar || permisosusuario.CONTABILIDAD.buscar) &&
                // (true) &&
                <li className="nav-item">
                    <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseProcesosContables"
                        aria-expanded="true" aria-controls="collapseProcesosContables">
                        <i className="fas fa-fw fa-box"></i>
                        <span>Procesos Contables</span>
                    </a>
                    <div id="collapseProcesosContables" className="collapse" aria-labelledby="headingUtilities"
                        data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                         

                        </div>
                    </div>
                </li>
            } */}







            <hr className="sidebar-divider d-none d-md-block" />

            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle"></button>
            </div>

        </ul>
    )
}

export default NavBar;