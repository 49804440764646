import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap";
import Swal from 'sweetalert2';
import { UserContext } from '../context/UserProvider';
import Buscar from '../componentes/Buscar';
import { Android } from "@mui/icons-material";

const modeloAdministradora = {
    codigo: "",
    nit: "",
    razsoc: "",
    contacto: "",
    cuentabancaria: "",
    direccion: "",
    ciudad: "",
    telefono: "",
    celular: "",
    email: "",
    essalud: "",
    conceptosalud: "",
    conceptoupc: "",
    diasperdidoseg: "",
    porcentajeeg: "",
    espension: "",
    conceptopension: "",
    conceptofondosolidario: "",
    conceptoabonovoluntario: "",
    escesantia: "",
    escajadecompensacion: "",
    esarl: "",
    diasperdidosirp: "",
};

const Administradoras = (props) => {
    const { user } = useContext(UserContext);
    const [Administradora, setAdministradora] = useState(modeloAdministradora);
    const [Administradoras, setAdministradoras] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModal, setVerModal] = useState(false);
    const [editar, setEditar] = useState(false);
    const [datosbuscados, setdatosbuscados] = useState([]);
    const [titulobuscar, settitulobuscar] = useState("Conceptos");
    const [verModaltipodocumentos, setverModaltipodocumentos] = useState(false);
    const [nameseleccionado, setnameseleccionado] = useState("");

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAdministradora({ ...Administradora, [name]: value });
    };

    const obtenerAdministradoras = async (coincidencia = "") => {
        try {
            const dt = JSON.parse(user);
            const response = await fetch(`${props.servidor}/AdministradorasApi/${coincidencia}?llave=${dt.usuario.llaveempresa}`);

            if (response.ok) {
                const data = await response.json();
                setAdministradoras(data);
                setPendiente(false);
            }
        } catch (error) {
            console.error(error);
        }
    };


    const obtenerConceptos = async (coincidencia) => {
        try {
            let dt = JSON.parse(user);
            let response = await fetch(props.servidor + '/ConceptosNominaApi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json();
                setdatosbuscados(data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        obtenerAdministradoras();
        obtenerConceptos("");
    }, []);

    const columns = [
        {
            name: 'Código',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'Razón Social',
            selector: row => row.razsoc,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <Button
                    color="primary"
                    size="sm"
                    onClick={() => abrirEditarModal(row)}
                >
                    <i className="fas fa-pen-alt"></i>
                </Button>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            },
        },
    };

    const abrirEditarModal = (data) => {
        setAdministradora({ ...data });
        setVerModal(true);
        setEditar(true);
    };

    const cerrarModal = () => {
        setAdministradora(modeloAdministradora);
        setVerModal(false);
        setEditar(false);
    };

    const guardarCambios = async () => {
        const dt = JSON.parse(user);
        const metodo = editar ? 'PUT' : 'POST';
        try {
            const response = await fetch(`${props.servidor}/AdministradorasApi?llave=${dt.usuario.llaveempresa}`, {
                method: metodo,
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Administradora),
            });

            if (response.ok) {
                await obtenerAdministradoras();
                cerrarModal();
                Swal.fire('Guardado Exitosamente!', 'La administradora fue guardada correctamente.', 'success');
            } else {
                const errorData = await response.json();
                const mensaje = Object.values(errorData.errors || {}).flat().join('\n');
                Swal.fire('Error al Guardar!', mensaje, 'error');
            }
        } catch (error) {
            console.error(error);
            Swal.fire('Error!', 'Ocurrió un error al guardar.', 'error');
        }
    };

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Administradoras
                </CardHeader>
                <CardBody>
                    <div className="d-flex mb-3">
                        <Input
                            type="text"
                            placeholder="Buscar"
                            id="buscardata"
                        />
                        <Button
                            color="primary"
                            className="ml-2"
                            size="sm"
                            onClick={() => obtenerAdministradoras(document.getElementById('buscardata').value)}
                        >
                            Buscar
                        </Button>
                        <Button
                            color="success"
                            className="ml-2"
                            size="sm"
                            onClick={() => {
                                setVerModal(true);
                                setEditar(false);
                            }}
                        >
                            Nuevo
                        </Button>
                    </div>
                    <DataTable
                        columns={columns}
                        data={Administradoras}
                        progressPending={pendiente}
                        pagination
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" style={{ maxWidth: '1600px', width: '95%' }} isOpen={verModal} toggle={cerrarModal}>
                <ModalHeader toggle={cerrarModal}>Administradora</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Código</Label>
                                <Input
                                    type="text"
                                    name="codigo"
                                    value={Administradora.codigo}
                                    onChange={handleChange}
                                    disabled={editar}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>NIT</Label>
                                <Input
                                    type="text"
                                    name="nit"
                                    value={Administradora.nit}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Razón Social</Label>
                                <Input
                                    type="text"
                                    name="razsoc"
                                    value={Administradora.razsoc}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        {/* <Col sm={3}>
                            <FormGroup>
                                <Label>Contacto</Label>
                                <Input
                                    type="text"
                                    name="contacto"
                                    value={Administradora.contacto}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>                        */}
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Cuenta Bancaria</Label>
                                <Input
                                    type="text"
                                    name="cuentabancaria"
                                    value={Administradora.cuentabancaria}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Dirección</Label>
                                <Input
                                    type="text"
                                    name="direccion"
                                    value={Administradora.direccion}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Ciudad</Label>
                                <Input
                                    type="text"
                                    name="ciudad"
                                    value={Administradora.ciudad}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Teléfono</Label>
                                <Input
                                    type="text"
                                    name="telefono"
                                    value={Administradora.telefono}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Celular</Label>
                                <Input
                                    type="text"
                                    name="celular"
                                    value={Administradora.celular}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    value={Administradora.email}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Es Salud</Label>
                                <Input
                                    type="select"
                                    name="essalud"
                                    value={Administradora.essalud}
                                    onChange={handleChange}
                                >
                                    <option value="no">No</option>
                                    <option value="si">Si</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        {Administradora.essalud == "si" &&
                            <>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Concepto Salud</Label>
                                        <Input
                                            type="text"
                                            name="conceptosalud"
                                            value={Administradora.conceptosalud}
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    setnameseleccionado("conceptosalud")
                                                    setverModaltipodocumentos(true)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Concepto UPC</Label>
                                        <Input
                                            type="text"
                                            name="conceptoupc"
                                            value={Administradora.conceptoupc}
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    setnameseleccionado("conceptoupc")
                                                    setverModaltipodocumentos(true)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col></>
                        }

                        <Col sm={3}>
                            <FormGroup>
                                <Label>Días Perdidos Seguridad</Label>
                                <Input
                                    type="text"
                                    name="diasperdidoseg"
                                    value={Administradora.diasperdidoseg}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Porcentaje EG</Label>
                                <Input
                                    type="text"
                                    name="porcentajeeg"
                                    value={Administradora.porcentajeeg}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Es Pensión</Label>
                                <Input
                                    type="select"
                                    name="espension"
                                    value={Administradora.espension}
                                    onChange={handleChange}
                                >  <option value="no">No</option>
                                    <option value="si">Si</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        {Administradora.espension == "si" &&
                            <>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Concepto Pensión</Label>
                                        <Input
                                            type="text"
                                            name="conceptopension"
                                            value={Administradora.conceptopension}
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    setnameseleccionado("conceptopension")
                                                    setverModaltipodocumentos(true)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Concepto Fondo Solidario</Label>
                                        <Input
                                            type="text"
                                            name="conceptofondosolidario"
                                            value={Administradora.conceptofondosolidario}
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    setnameseleccionado("conceptofondosolidario")
                                                    setverModaltipodocumentos(true)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Concepto Abono Voluntario</Label>
                                        <Input
                                            type="text"
                                            name="conceptoabonovoluntario"
                                            value={Administradora.conceptoabonovoluntario}
                                            onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    setnameseleccionado("conceptoabonovoluntario")
                                                    setverModaltipodocumentos(true)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col></>
                        }

                        <Col sm={3}>
                            <FormGroup>
                                <Label>Es Cesantía</Label>
                                <Input
                                    type="select"
                                    name="escesantia"
                                    value={Administradora.escesantia}
                                    onChange={handleChange}
                                >
                                    <option value="no">No</option>
                                    <option value="si">Si</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Es Caja de Compensación</Label>
                                <Input
                                    type="select"
                                    name="escajadecompensacion"
                                    value={Administradora.escajadecompensacion}
                                    onChange={handleChange}
                                >
                                    <option value="no">No</option>
                                    <option value="si">Si</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Es ARL</Label>
                                <Input
                                    type="select"
                                    name="esarl"
                                    value={Administradora.esarl}
                                    onChange={handleChange}
                                >
                                    <option value="no">No</option>
                                    <option value="si">Si</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        {/* <Col sm={3}>
                            <FormGroup>
                                <Label>Días Perdidos IRP</Label>
                                <Input
                                    type="text"
                                    name="diasperdidosirp"
                                    value={Administradora.diasperdidosirp}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col> */}
                    </Row>
                    <Buscar tituloencabezado={titulobuscar} filtrar={(coincidencia) => {
                        // filtrartipodocumentos(coincidencia)
                    }}
                        busqueda={datosbuscados} encabezado={["codigo", "nombre", "", ""]}
                        codigo={"codigo"} nombre={"razsoc"} dato={""} dato2={""}
                        verModal={verModaltipodocumentos} cerrarModal={() => {
                            setverModaltipodocumentos(false)


                        }} seleccionar={(seleccionado) => {
                            var _Admin = { ...Administradora }
                            _Admin[nameseleccionado] = seleccionado.codigo
                            setAdministradora(_Admin)
                            setverModaltipodocumentos(false)
                            setnameseleccionado("")
                        }} />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Administradoras;
