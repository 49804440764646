import React, { useEffect, useState, useContext } from "react";
import DataTable from 'react-data-table-component';
import { InputGroup, Table, Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'
import Tabs from 'react-bootstrap/Tabs'
import { UserContext } from '../context/UserProvider';
import Autosuggest from 'react-autosuggest';
import Tab from 'react-bootstrap/Tab'
import Buscar from '../componentes/Buscar'
const modeloSucursal = {
    nit: "",
    nombre: "",
    descripcion: "",
    telefono: "",
    direccion: "",
    email: "",
    celular: "",
    activo: "si",
    fechaultimopago: "",
    maximafechadeuso: "",
    electronica: "no",
    clavecertificado: "",
    tipopersona: "",
    responsabilidadfiscal: "",
    regimenfiscal: "",
    matriculamercantil: "",
    opcion2: "",
}
const Sucursal = (props) => {
    const { user, cerrarSession } = useContext(UserContext);
    const [Sucursal, setSucursal] = useState(modeloSucursal);
    const [Sucursals, setSucursals] = useState([]);
    const [Codigo, setCodigo] = useState([]);
    const [Codigos, setCodigos] = useState([]);
    const [Nombre, setNombre] = useState([]);
    const [Nombres, setNombres] = useState([]);
    const [idsoftware, setidsoftware] = useState([]);
    const [idsoftwares, setidsoftwares] = useState([]);
    const [numeroresolucion, setnumeroresolucion] = useState([]);
    const [numeroresolucions, setnumeroresolucions] = useState([]);
    const [fechainicio, setfechainicio] = useState([]);
    const [fechainicios, setfechainicios] = useState([]);
    const [fechafin, setfechafin] = useState([]);
    const [fechafins, setfechafins] = useState([]);
    const [prefijoresolucion, setprefijoresolucion] = useState([]);
    const [prefijoresolucions, setprefijoresolucions] = useState([]);
    const [prefijoinicio, setprefijoinicio] = useState([]);
    const [prefijoinicios, setprefijoinicios] = useState([]);
    const [prefijofin, setprefijofin] = useState([]);
    const [prefijofins, setprefijofins] = useState([]);
    const [clavetecnica, setclavetecnica] = useState([]);
    const [clavetecnicas, setclavetecnicas] = useState([]);
    const [pin, setpin] = useState([]);
    const [pins, setpins] = useState([]);
    const [avisar, setavisar] = useState([]);
    const [avisars, setavisars] = useState([]);
    const [codigoenfactura, setcodigoenfactura] = useState([]);
    const [codigoenfacturas, setcodigoenfacturas] = useState([]);
    const [nombreenfactura, setnombreenfactura] = useState([]);
    const [nombreenfacturas, setnombreenfacturas] = useState([]);
    const [resolucionenfactura, setresolucionenfactura] = useState([]);
    const [resolucionenfacturas, setresolucionenfacturas] = useState([]);
    const [fila, setfila] = useState(0);
    const [usuario, setDataUser] = useState([]);
    const [fila2, setfila2] = useState(0);
    const [editar, seteditar] = useState(0);
    const [key, setKey] = useState('Datos');
    const [roles, setRoles] = useState([]);
    const [pendiente, setPendiente] = useState(true);
    const [verModalSucursal, setVerModalSucursal] = useState(false);
    const handleChange = (e) => {
        let value = e.target.value
        console.log(e.target)
        var _Sucursal = { ...Sucursal }
        _Sucursal[e.target.name] = value
        setSucursal(_Sucursal)
        setRoles([])


    }
    const obtenerSucursals = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Sucursalapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setSucursals(data)
                setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        obtenerSucursals('');
    }, [])
    const columns = [
        {
            name: 'nit',
            selector: row => row.nit,
            sortable: true,
        },
        {
            name: 'nombre',
            selector: row => row.nombre,
            sortable: true,
        },
        {
            name: 'descripcion',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: 'telefono',
            selector: row => row.telefono,
            sortable: true,
        },
        {
            name: 'direccion',
            selector: row => row.direccion,
            sortable: true,
        },
        {
            name: 'email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'celular',
            selector: row => row.celular,
            sortable: true,
        },
        {
            name: 'activo',
            selector: row => row.activo,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModalSucursal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const abrirEditarModalSucursal = (data) => {
        var enviodata = { ...data }
        var _Sucursal = {
            nit: enviodata.nit,
            nombre: enviodata.nombre,
            descripcion: enviodata.descripcion,
            telefono: enviodata.telefono,
            direccion: enviodata.direccion,
            email: enviodata.email,
            celular: enviodata.celular,
            activo: enviodata.activo,
            fechaultimopago: enviodata.fechaultimopago,
            maximafechadeuso: enviodata.maximafechadeuso,
            electronica: enviodata.electronica,
            clavecertificado: enviodata.clavecertificado,
            tipopersona: enviodata.tipopersona,
            responsabilidadfiscal: enviodata.responsabilidadfiscal,
            regimenfiscal: enviodata.regimenfiscal,
            matriculamercantil: enviodata.matriculamercantil,
            nombrecomercial: enviodata.nombrecomercial,
            opcion2:enviodata.opcion2
        }
        setSucursal(_Sucursal);
        setVerModalSucursal(!verModalSucursal);
        seteditar(true)
    }
    const cerrarModalSucursal = () => {
        setSucursal(modeloSucursal)
        setVerModalSucursal(!verModalSucursal);
        seteditar(false)
        setKey('Datos')
    }
    const asignarceros = (_Sucursal) => {

        let claves = Object.keys(_Sucursal);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (false) {
                _Sucursal[clave] = Number(_Sucursal[clave])
            }
        }
        return _Sucursal
    }
    const verificar = (_Sucursal) => {

        let claves = Object.keys(_Sucursal);
        for (let i = 0; i < claves.length; i++) {
            let clave = claves[i];
            if (clave == 'Codigo' || clave == 'Nombre' || clave == 'idsoftware' || clave == 'numeroresolucion' || clave == 'fechainicio' || clave == 'fechafin' || clave == 'prefijoresolucion' || clave == 'prefijoinicio' || clave == 'prefijofin' || clave == 'clavetecnica' || clave == 'pin' || clave == 'avisar' || clave == 'codigoenfactura' || clave == 'nombreenfactura' || clave == 'resolucionenfactura') {
                if (_Sucursal[clave] == '') {
                    return false
                }
            }
        }
        return true
    }
    const guardarCambios = async () => {
        let response;
        console.log(Sucursal)
        var _Sucursal = { ...Sucursal }
        _Sucursal = asignarceros(_Sucursal)
        var _verificado = verificar(_Sucursal)
        if (_verificado) {
            console.log(_Sucursal)
            if (!editar) {
                response = await fetch("" + props.servidor + "/Sucursalapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Sucursal)
                })

            } else {
                response = await fetch("" + props.servidor + "/Sucursalapi?llave=" + usuario.usuario.llaveempresa + "", {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(_Sucursal)
                })
            }

            if (response.ok) {
                await obtenerSucursals('');
                setSucursal(modeloSucursal)
                setVerModalSucursal(!verModalSucursal);
                setKey('Datos')
                Swal.fire(
                    'Guardado Exitosamente!',
                    'El Sucursal fue guardado exitosamente',
                    'success'
                )
            } else {
                Swal.fire(
                    'Error al Guardar!',
                    'Sucedio un error al guardar el Sucursal',
                    'error'
                )
            }
        } else {
            Swal.fire(
                'Error al Guardar!',
                'Faltan Datos Por llenar',
                'error'
            )
        }

    }

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#188BAD', color: "white" }}>
                    Lista de Sucursals
                </CardHeader>
                <CardBody>
                    <div class="col-sm-6" >
                        <div class="input-group input-group-sm mb-3" >
                            <div class="input-group-prepend" >

                                <input type="text" id="buscardataSucursals" placeholder='Buscar' />
                            </div>
                            <Button color="primary" className="ml-2" size="sm" onClick={() => obtenerSucursals(document.getElementById('buscardataSucursals').value)}>Buscar</Button>
                            <Button color="success" className="ml-2" size="sm" onClick={() => {
                                setVerModalSucursal(!verModalSucursal)
                                seteditar(false)
                            }}>Nuevo</Button>
                        </div>
                    </div>


                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={Sucursals}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal size="lg" isOpen={verModalSucursal}>
                <ModalHeader>
                    Detalle Sucursals
                </ModalHeader>
                <ModalBody>
                    <Tabs
                        id="controlled-tab-example2"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className=" pestañas"
                    >
                        <Tab eventKey="Datos" title="Datos">
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nit*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nit" value={Sucursal.nit} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nombre*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombre" value={Sucursal.nombre} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Descripcion*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="descripcion" value={Sucursal.descripcion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nombre Comercial</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="nombrecomercial" value={Sucursal.nombrecomercial} />
                                    </FormGroup>
                                </Col>
                                
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Telefono*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="telefono" value={Sucursal.telefono} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Direccion*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="direccion" value={Sucursal.direccion} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Email*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="email" value={Sucursal.email} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Celular*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="celular" value={Sucursal.celular} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Activo*</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="activo" value={Sucursal.activo} >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Fecha Ultimo Pago*</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="fechaultimopago" value={Sucursal.fechaultimopago} />
                                    </FormGroup>
                                </Col> */}
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Maxima Fecha de Uso*</Label>
                                        <Input type="date" bsSize="sm" onChange={handleChange} name="maximafechadeuso" value={Sucursal.maximafechadeuso} />
                                    </FormGroup>
                                </Col> */}
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Electronica*</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="electronica" value={Sucursal.electronica} >
                                            <option value="no">No</option>
                                            <option value="si">Si</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Clave Certificado*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="clavecertificado" value={Sucursal.clavecertificado} />
                                    </FormGroup>
                                </Col> */}
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Matricula Mercantil*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="matriculamercantil" value={Sucursal.matriculamercantil} />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Municipio</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="opcion2" value={Sucursal.opcion2} />
                                    </FormGroup>
                                </Col>
                                
                            </Row>
                            <Row>
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tipo persona*</Label>
                                        <Input type="select" bsSize="sm" onChange={handleChange} name="tipopersona" value={Sucursal.tipopersona} >
                                        <option value={"1"}>Persona Juridica</option>
                                            <option value={"2"}>Persona Natural</option>
                                        </Input>
                                    </FormGroup>
                                </Col> */}
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Responsabilidad Fiscal*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="responsabilidadfiscal" value={Sucursal.responsabilidadfiscal} />
                                    </FormGroup>
                                </Col> */}
                                {/* <Col sm={3}>
                                    <FormGroup>
                                        <Label>Regimen Fiscal*</Label>
                                        <Input type="text" bsSize="sm" onChange={handleChange} name="regimenfiscal" value={Sucursal.regimenfiscal} />
                                    </FormGroup>
                                </Col> */}
                             
                            </Row>

                        </Tab>
                    </ Tabs >
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModalSucursal}>Cerrar</Button>
                </ModalFooter>
            </Modal>

        </>
    )
}
export default Sucursal;
