// import React, { useContext } from "react";
import { Fragment } from "react";
// import { DataContext } from "../../Context/Data";
import ItemCart from "./GrillaVenta";
import MenuCard from "./ProductosMenu";
import React, { UserContext } from "../../context/UserProvider";
import { QRCode } from 'react-qrcode-logo';

import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Container, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { HashLink as Link } from "react-router-hash-link";
import TipodeDocumento from '../../componentes/Buscar2/TipodeDocumento'
import ListaPrecio from '../../componentes/Buscar2/ListaPrecio'
import { useContext, useState, useEffect } from "react";
import Swal from 'sweetalert2'
import Buscar from '../../componentes/Buscar'
import Esperando from '../../componentes/Esperando'
import Comanda from './Impresiones/Comanda'
import Factura from './Impresiones/Factura'
import { FormatMoney } from 'format-money-js'
import { jsPDF } from "jspdf";
import BuscarMovimiento from '../../componentes/Buscarmovimiento'

import '../css/Venta.css'
const tabespacio = '\u00A0';

const municipios = [
    {
        municipio: "Barranquilla",
        departamento: "Atlantico",
        codigo: ""
    },
    {
        municipio: "Barranquilla",
        departamento: "Atlantico",
        codigo: "08001"
    },
    {
        municipio: "Oiba",
        departamento: "Santander",
        codigo: "68500"
    },
    {
        municipio: "Santa Marta",
        departamento: "Magdalena",
        codigo: "47001"
    },
    {
        municipio: "Funza",
        departamento: "Cundinamarca",
        codigo: "25286"
    },
    {
        municipio: "Bogota",
        departamento: "Bogota",
        codigo: "11001"
    },
    {
        municipio: "Popayan",
        departamento: "Cauca",
        codigo: "19001"
    },
    {
        municipio: "FACATATIVÁ",
        departamento: "",
        codigo: "25269"
    },
    {
        municipio: "MADRID",
        departamento: "",
        codigo: "25430"
    },
    {
        municipio: "QUIBDÓ",
        departamento: "CHOCÓ",
        codigo: "27001"
    },
    {
        municipio: "JAMUNDÍ",
        departamento: "VALLE DEL CAUCA",
        codigo: "76364"
    },
    {
        municipio: "ENVIGADO",
        departamento: "ANTIOQUIA",
        codigo: "05226"
    },
    {
        municipio: "PUERTO LIBERTADOR",
        departamento: "CORDOBA",
        codigo: "25580"
    },
    {
        municipio: "TUNJA",
        departamento: "BOYACA",
        codigo: "15001"
    },
    {
        municipio: "SAN GIL",
        departamento: "SANTANDER",
        codigo: "68679"
    },
    {
        municipio: "BUCARAMANGA",
        departamento: "SANTANDER",
        codigo: "68001"
    },
    {
        municipio: "ENVIGADO",
        departamento: "ANTIOQUIA",
        codigo: "05266"
    },
    {
        municipio: "BARRANCAS",
        departamento: "La Guajira",
        codigo: "44078"
    },
    {
        municipio: "DIBULLA",
        departamento: "La Guajira",
        codigo: "44090"
    },
    {
        municipio: "PEREIRA",
        departamento: "Risaralda",
        codigo: "66001"
    },
    {
        municipio: "ATLANTICO",
        departamento: "Galapa",
        codigo: "08296"
    }

]

const modelo = {
    usuario: {
        id: "",
        cedula: "",
        nitempresa: "",
        nombre: "",
        nombreempresa: "",
        contraseña: "",
        llaveempresa: "",
        llaveusuario: "",
        cargo: "",
        departamento: "",
        Eliminado: "",
    },
    empresa: {
        empresa: {
            id: "",
            nit: "",
            nombre: "",
            descripcion: "",
            telefono: "",
            direccion: "",
            email: "",
            celular: "",
            llave: "",
            servidor: "",
            basededatos: "",
            activo: "",
            fechaultimopago: "",
            maximafechadeuso: "",
            Eliminado: "",
        },
        config: {
            carpeta_documentos: "",
            carpeta_bd: "",
            contribuyente_nit: "",
            contribuyente_razon_social: "",
            contribuyente_tipo: "",
            contribuyente_responsabilidad_fiscal: "",
            contribuyente_regimen_fiscal: "",
            contribuyente_matricula_mercantil: "",
            direccion_contribuyente_linea: "",
            direccion_contribuyente_municipio: "",
            direccion_contribuyente_departamento: "",
            direccion_contribuyente_pais: "",
            contribuyente_email: "",
            contribuyente_telefono: "",
            software_en_pruebas: "",
            usar_set_pruebas: "",
            identificador_set_pruebas: "",
            identificador_software: "",
            rango_numero_resolucion: "",
            rango_fecha_resolucion: "",
            rango_vigencia_desde: "",
            rango_vigencia_hasta: "",
            rango_clave_tecnica: "",
            rango_prefijo: "",
            rango_desde: "",
            rango_hasta: "",
            ruta_certificado: "",
            clave_certificado: "",
            encabezado_pdf: "",
            ruta_logo: "",
            smtp_host: "",
            smtp_puerto: "",
            smtp_ssl: "",
            smtp_usuario: "",
            smtp_clave: "",
            sucursal: "",
            pin_software: "",
            especialidad: "",
            Eliminado: "",
        },
        modulos: {
            FACTURA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            INVENTARIO: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CARTERA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            FACTURAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            NOMINAELECTRONICA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            CONTABILIDAD: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            TESORERIA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            COMPRA: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            RADIAN: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            MAESTROS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            ADMINISTRACION: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            },
            DATOSBASICOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }, ARTICULOS: {
                editar: false,
                buscar: false,
                imprimir: false,
                eliminar: false,
                adicionar: false,
                electronico: false,
            }



        }
    }
}
const modelomovimiento = {
    compañia: "",
    concepto: "",
    ndocumento: "",
    fecha: new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2),
    tercero: "cliente",
    cliente: "222222222222",
    nombrecli: "CONSUMIDOR FINAL",
    vendedor: "",
    direccion: "",
    nombreven: "",
    documento1: "",
    documento2: "",
    periodo: "",
    horafecha: "",
    estacion: "",
    nomusuario: "",
    observacion: "",
    anuladopor: "",
    borradopor: "",
    bruto: "",
    iva: "",
    descuento: "",
    despiefac: "",
    retefuente: "",
    reteiva: "",
    ica: "",
    retefte: "",
    impconsumo: "",
    subtotal: "",
    total: "",
    cuotai: "",
    ncuotasi: "",
    diferidasi: "",
    apartirdei: "",
    tipocre: "",
    ncuotasf: "",
    tasa: "",
    diferidas: "",
    apartirde: "",
    fechpost: "",
    banco: "",
    efectivo: "",
    cheque: "",
    debito: "",
    credito: "",
    descuentoc: "",
    beneficiario: "",
    impreso: "",
    anulado: "",
    estado: "",
    modificadopor: "",
    convertcon: "",
    convertdoc: "",
    devolcon: "",
    devoldoc: "",
    concprod: "",
    ndocprod: "",
    conctrasl: "",
    ndoctrasl: "",
    concodcped: "",
    ndocodcped: "",
    moneda: "",
    pendiente: "",
    cree: "",
    fletes: "",
    tcambio: "",
    dsctopp1: "",
    dsctopp2: "",
    dsctopp3: "",
    dsctovapp1: "",
    dsctovapp2: "",
    dsctovapp3: "",
    bancocr: "",
    convdescon: "",
    convdesdoc: "",
    convoricon: "",
    convoridoc: "",
    poretefte: "",
    poreteiva: "",
    porica: "",
    porimptoconsumo: "",
    vlr_dolar: "",
    autorizado: "",
    fpago: "contado",
    mpago: "",
    sucursal: "",
    conceptodevo: "",
    ndocumentodevo: 0,
    cufe: "",
    emitido: "no",
    qr: "",
    famovimiento: [
        // {
        //     item: "",
        //     referencia: "",
        //     descrip: "",
        //     bodega: "",
        //     cantidad: "",
        //     precio: "",
        //     pordescuento: "",
        //     poriva: "",
        //     porimptoconsumo: "",
        //     subtotal: "",
        //     compañia: "",
        //     concepto: "",
        //     nrodocumento: "",
        //     costo: "",
        //     desadicional: "",
        //     unimed: "",
        //     ccosto: "",
        //     fecha: "",
        //     preivainc: "",
        //     codprod: "",
        //     canprod: "",
        //     horafecha: "",
        //     tipoitem: "",
        //     tercero: "",
        //     cliente: "",
        //     vlr_desc: "",
        //     vlr_iva: "",
        //     vlr_base_iva: "",
        //     costodolar: "",
        //     tasa: "",
        //     vlrimptoconsumo: "",
        //     poretefte: "",
        //     vlretefte: "",
        //     porica: "",
        //     vlrica: "",
        //     poreteiva: "",
        //     vlreteiva: "",
        //     cantdig: "",
        // } 
    ]
}

const modeloSucursal = {
    nit: "",
    nombre: "",
    descripcion: "",
    telefono: "",
    direccion: "",
    email: "",
    celular: "",
    activo: "si",
    fechaultimopago: "",
    maximafechadeuso: "",
    electronica: "no",
    clavecertificado: "",
    tipopersona: "",
    responsabilidadfiscal: "",
    regimenfiscal: "",
    matriculamercantil: ""
}
const modeloTerceros = {
    caclientes: {
        tercero: "",
        clase: "",
        codigo: "",
        cedula: "",
        digito: "",
        razsoc: "",
        nom1: "",
        nom2: "",
        ape1: "",
        ape2: "",
        codigomun: "",
        coddepar: "",
        codpais: "",
        email: "",
        contacto: "",
        beneficiario: "",
        repleg: "",
        reg_merc: "",
        direccion: "",
        tel1: "",
        tel2: "",
        tel3: "",
        barrio: "",
        fechnac: "",
        interes: "",
        cupocre: "",
        plazo: "",
        vendedor: "",
        ccosto: "",
        precsug: "",
        actecono: "",
        feching: "",
        fechulne: "",
        regtrib: "",
        resfis: "",
        desccom: "",
        desccom2: "",
        desccom3: "",
        desccom4: "",
        autoreteft: "",
        estado: "",
        salant1: "",
        saldeb1: "",
        salcre1: "",
        venretefuente: "",
        venbasefte: "",
        venporcfte: "",
        veniva: "",
        venbaseiva: "",
        venporciva: "",
        venreteiva: "",
        venbasereteiva: "",
        venporcreteiva: "",
        venreteica: "",
        venbaseica: "",
        venporica: "",
        salact1: "",
        ciudad: "",
        pais: "",
        clas1: "",
        clas2: "",
        clas3: "",
        clas4: "",
        clas5: "",
        reteica: "",
        personat: "",
        auto: "",
        salant2: "",
        saldeb2: "",
        salcre2: "",
        salact2: "",
        brutop1: "",
        brutop2: "",
        ivap: "",
        desctop1: "",
        desctop2: "",
        reteftep: "",
        reteivap: "",
        reteica2p: "",
        imptoconsumop: "",
        brutoa1: "",
        brutoa2: "",
        ivaa: "",
        desctoa1: "",
        desctoa2: "",
        retftea: "",
        reteivaa: "",
        reteicaa: "",
        imptoconsa: "",
        creadopor: "",
        borradopor: "",
        modificpor: "",
        municipio: "",
        cambioimp: "",
        flete: "",
        cumplemes: "",
        cumpledia: "",
        cumpleano: "",
        horafecha: "",
        sexo: "",
        zona: "",
        clas6: "",
        clas7: "",
        clas8: "",
        clas9: "",
        clas10: "",
        autorexpro: "",
        cod_postal: "",
        nompais: "",
        campo: "",
        comretefte: "",
        combasefte: "",
        comporcfte: "",
        comreteiva: "",
        combasereteiva: "",
        comporcreteiva: "",
        comiva: "",
        combaseiva: "",
        comporciva: "",
        comreteica: "",
        combaseica: "",
        comporica: "",
        vencree: "",
        venbacree: "",
        venporcree: "",
        comcree: "",
        combacree: "",
        comporcree: "",
        Eliminado: "",
    },
    direcciones: []
}

const CurrentTab = (props) => {
    //   const { cartValue } = useContext(DataContext);
    //   const [cart, setCart] = cartValue;
    const [abrirtipodedocumento, setabrirtipodedocumento] = useState(false);
    const [abrirbarrio, setabrirbarrio] = useState(false);
    const [vienedeafuera, setvienedeafuera] = useState(props.editarorden);
    const [Movimiento, setMovimiento] = useState(modelomovimiento);
    const [tipodocumento, settipodocumento] = useState([]);
    const [tipodocumentos, settipodocumentos] = useState([]);
    const { user } = useContext(UserContext)
    const [dataUser, setDataUser] = useState(modelo)
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [tercero, settercero] = useState(modeloTerceros)
    const [sucursal, setsucursal] = useState(modeloSucursal)
    const [Roles, setRoles] = useState([]);
    const [verModalvendedors, setVerModalvendedors] = useState(false);
    const [vendedors, setvendedors] = useState([]);
    const [esperando, setesperando] = useState(false);
    const [Mediodepagos, setMediodepagos] = useState([]);
    const [bruto, setbruto] = useState(0)
    const [descuento, setdescuento] = useState(0)
    const [subTotal, setSubTotal] = useState(0)
    const [iva, setiva] = useState(0)
    const [consumo, setconsumo] = useState(0)
    const [reteiva, setreteiva] = useState(0)
    const [retefuente, setretefuente] = useState(0)
    const [reteica, setreteica] = useState(0)
    const [total, setTotal] = useState(0)
    const [total2, setTotal2] = useState(0)
    const [previsualizar, setprevisualizar] = useState(false);
    const [previsualizar2, setprevisualizar2] = useState(false);
    const [previsualizar3, setprevisualizar3] = useState(false);
    const [abrirmediosdepago, setabrirabrirmediosdepago] = useState(false);
    const [visualizartotales, setvisualizartotales] = useState(false);
    const [Movimientos, setMovimientos] = useState([]);
    const [busquedaMovimientos, setbusquedaMovimientos] = useState([]);
    const [previsualizarenviocorreo, setprevisualizarenviocorreo] = useState(false);
    const [correosenvio, setcorreosenvio] = useState(false);
    const [menus, setmenus] = useState([]);
    const [agregueitem, setagregueitem] = useState(false);

    const [listasdeprecio, setlistasdeprecio] = useState(false);
    const [barrio, setbarrio] = useState("");
    const [nombarrio, setnombarrio] = useState("");
    const [fm, setfm] = useState(new FormatMoney({ symbol: '' }));
    const [fm1, setfm1] = useState(new FormatMoney({ symbol: '', decimals: 1 }));
    const [fm2, setfm2] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$', decimals: 2 }));
    const [mostrarimagenes, setmostrarimagenes] = useState(true);
    const [valorescaja, setvalorescaja] = useState([])
    const [verModalMovimientos, setVerModalMovimientos] = useState(false);
    const [certificado, setcertificado] = useState([])

    const previsualizarpdfelectronicadescargar = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();
                doc.save(tipodocumento.descripcion + Movimiento.ndocumento + '.pdf');
                // doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }
    const previsualizarpdfelectronica = () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();

                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            },
            html2canvas: {
                scale: escala
            }
        })
    }
    const previsualizarpdflargo = () => {
        // a0 - a10
        // b0 - b10
        // c0 - c10
        // dl
        // letter
        // government-letter
        // legal
        // junior-legal
        // ledger
        // tabloid
        // credit-card
        const doc = new jsPDF('p', 'pt', [612, 15 * 72]);
        doc.page = 1; // use this as a counter.

        console.log(doc.getFontList())
        doc.html(document.querySelector("#pruebapdfventaelectronica"), {
            callback: function (pdf) {
                doc.autoPrint();
                doc.output('dataurlnewwindow', { filename: 'comprobante.pdf' });
            }
        })
    }
    const reiniciar = () => {

        setSubTotal(0)
        setbruto(0)
        setdescuento(0)
        setconsumo(0)
        setiva(0)
        setretefuente(0)
        setreteica(0)
        setreteiva(0)
        setTotal(0)
        setTotal2(0)
        buscarlistaprecio('')
        setbarrio('')
        setnombarrio('')
        buscartodastipodocumentos2('');
    }
    const convertBlobToBase64 = async (blob) => { // blob data
        return await blobToBase64(blob);
    }
    const blobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const enviocorreofacturaelectronica = async () => {

        const doc = new jsPDF('p', 'pt', 'a4');
        doc.page = 1; // use this as a counter.
        console.log(doc.getFontList())
        var escala = (doc.internal.pageSize.width - 10) / document.getElementById('pruebapdfventaelectronica78787').clientWidth
        doc.html(document.querySelector("#pruebapdfventaelectronica78787"), {
            callback: async function (pdf) {
                var buffer = doc.output('arraybuffer');
                var butes = doc.output();
                var blob = doc.output('blob')
                var reader = new FileReader();
                var base64data = ""
                base64data = await convertBlobToBase64(blob);
                console.log(base64data)
                var pdf = {
                    pdfbase64: base64data,
                    adjuntos: certificado
                }
                let dt = JSON.parse(user)
                var clave = dt.empresa.config.clave_certificado == "" ? "-" : dt.empresa.config.clave_certificado
                var ids = tipodocumento.identificadorsw == "" ? "-" : tipodocumento.identificadorsw
                let response = fetch(props.servidor + '/EnvioApi/Correo/' + correosenvio + '/' + Movimiento.concepto + '/' + (Movimiento.ndocumento + "") + '/' + dt.empresa.empresa.nit + '/' + clave + '/' + ids + '?llave=' + dt.usuario.llaveempresa + '', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(pdf)
                })
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then(async (dataJson) => {
                        // reestablecer();
                        var data2 = dataJson;
                        reiniciar()
                        Swal.fire(
                            'Exito!!',
                            data2.mensaje,
                            'success'
                        )
                        setprevisualizar(false)

                    }).catch(async (error) => {
                        var eerr = await error.json()
                        Swal.fire(
                            'Opps!',
                            'No se pudo crear la venta: ' + eerr.mensaje,
                            'error'
                        )
                        console.log("No se pudo enviar la venta ", eerr.mensaje,)
                    })


            },
            html2canvas: {
                scale: escala
            }
        })


    }


    const base64certificado = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    useEffect(() => {
        try {
            if (vienedeafuera) {
                if (props.movimiento) {
                    seleccionarMovimientos(props.movimiento)

                } else {
                    async function fetchData() {
                        // You can await here
                        var movvv = await buscarmovimientodemesa(props.mesa)

                        seleccionarMovimientos(movvv[0])
                    }
                    fetchData()

                }


            }
        } catch (error) {

        }
    }, [vienedeafuera])

    const buscarmovimientodemesa = async (mesa) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Movimientoapi/movimientodemesa/' + mesa + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }

    const buscartodoslosmovimientos2 = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            var url = props.servidor + '/Movimientoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + ''
            const api = fetch(props.servidor + '/Movimientoapi/optimizado/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setMovimientos(dataJson)
                    setbusquedaMovimientos(dataJson)
                    console.log(dataJson)
                }).catch((error) => {
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos',
                        'error'
                    )
                    setMovimientos([])
                    setbusquedaMovimientos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarMovimientos = async (e) => {

        try {
            var _faencmovi = e.faencmovi
            var _movimiento = _faencmovi
            var tipodocc = await buscarcoincidenciastipodocs(_faencmovi.concepto)
            var sucursal2 = await buscarcoincidenciassucursal(tipodocc.compañia)
            var pagoscaja = await buscarcoincidenciascaja(_faencmovi.concepto, _faencmovi.ndocumento)
            settipodocumento(tipodocc)
            setsucursal(sucursal2)
            setvalorescaja(pagoscaja)
            var tercc = await buscarcoincidenciasterceros(_faencmovi.cliente)
            settercero(tercc[0])

            try {

                let dt = JSON.parse(user)
                var url = props.servidor + '/Movimientoapi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + ''
                const api = fetch(props.servidor + '/Movimientoapi/cuerpo/' + '?llave=' + dt.usuario.llaveempresa + '', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(e)
                })
                    .then((response) => {
                        return response.ok ? response.json() : Promise.reject(response);
                    })
                    .then(async (dataJson) => {
                        _movimiento.famovimiento = dataJson



                        calculartotalesmovimiento(dataJson, _movimiento.convoridoc)
                        setMovimiento(_movimiento)
                    }).catch((error) => {
                        Swal.fire(
                            'Opps!',
                            'No pudieron cargar los movimientos',
                            'error'
                        )
                    })
            } catch (error) {
                console.log(error)
            }

        } catch (error3) {
        }
    }
    const buscarcoincidenciasterceros = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unicocoincidecnias/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var dataj = []
                if (tipodocumento.tipodoc == "factura" || tipodocumento.tipodoc == 'plan separe' || tipodocumento.tipodoc == 'cotizacion' || tipodocumento.tipodoc == "remision") {
                    dataj = data.filter(e => e.tercero == "cliente")

                } else {
                    if (tipodocumento.tipodoc == "compra" || tipodocumento.tipodoc == "ordendecompra") {
                        dataj = data.filter(e => e.tercero == "proveedor")

                    } else {
                        dataj = data

                    }
                }

                return dataj
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    const buscarcoincidenciascaja = async (coincidencia, numero) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Movimientoapi/caja/' + coincidencia + '/' + numero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    const obtenerturnos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/TurnoApi/abiertosporusuario/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            } else {
                return []

            }
        } catch (error) {
            console.log(error)
        }
    }
    const obtenerInarticuloss = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            var coin = ""
            if (coincidencia != "") {
                coin = "coin/"
            }
            let response = await fetch(props.servidor + '/Inarticulosapi/restaurante/' + coin + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setmenus(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const buscarcoincidenciastipodocs = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        buscartodastipodocumentos('');
        buscartodasvendedors('')
        obtenerMediodepagos('')
        buscarlistaprecio('')
        obtenerInarticuloss('')

    }, [])
    const buscarlistaprecio = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ListadePrecioapi/nombres/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                setlistasdeprecio(data)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const totalunidades = () => {
        var tot = 0
        for (let i = 0; i < Movimiento.famovimiento.length; i++) {
            const element = Movimiento.famovimiento[i];
            tot = tot + element.cantidad
        }
        return tot
    }
    const calculartotalesmovimiento = (_enviodatos2, propina) => {
        var totales = {
            bruto: 0,
            descuento: 0,
            subtotal: 0,
            iva: 0,
            consumo: 0,
            reteiva: 0,
            retefuente: 0,
            reteica: 0,
            total: 0,
        }
        _enviodatos2.map((dato, index) => {
            var bruto = 0
            var descuento = 0
            var subtotal = 0
            var iva = 0
            var consumo = 0
            var reteiva = 0
            var retefuente = 0
            var reteica = 0
            var total = 0
            var cantidad = Number(dato.cantidad)
            var precio = Number(dato.precio)
            var pordescuento = Number(dato.pordescuento)
            var poriva = Number(dato.poriva)
            var porimptoconsumo = Number(dato.porimptoconsumo)
            var poretefte = Number(dato.poretefte)
            var porica = Number(dato.porica)
            var poreteiva = Number(dato.poreteiva)
            bruto = (cantidad * precio)
            descuento = bruto * (pordescuento / 100)
            subtotal = bruto - descuento
            iva = subtotal * (poriva / 100)
            consumo = subtotal * (porimptoconsumo / 100)
            reteiva = iva * (poreteiva / 100)
            retefuente = subtotal * (poretefte / 100)
            reteica = subtotal * (porica / 100)
            total = subtotal + iva + consumo - reteiva - retefuente - reteica
            totales.bruto = totales.bruto + bruto
            totales.descuento = totales.descuento + descuento
            totales.subtotal = totales.subtotal + subtotal
            totales.iva = totales.iva + iva
            totales.consumo = totales.consumo + consumo
            totales.reteiva = totales.reteiva + reteiva
            totales.retefuente = totales.retefuente + retefuente
            totales.reteica = totales.reteica + reteica
            totales.total = totales.total + total
        })
        setTotal2(totales.total)
        if (propina) {
            totales.total = totales.total + propina
        }
        setbruto(totales.bruto)
        setdescuento(totales.descuento)
        setSubTotal(totales.subtotal)
        setiva(totales.iva)
        setconsumo(totales.consumo)
        setreteiva(totales.reteiva)
        setretefuente(totales.retefuente)
        setreteica(totales.reteica)
        setTotal(totales.total)
        return totales
    }
    const obtenerMediodepagos = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Mediodepagoapi/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var dataenvio = data.filter(p => p.semanejaencaja == "Si")
                dataenvio = dataenvio.map((dato) => {
                    var dat = {
                        ...dato,
                        ['valor']: 0,
                        ['concepto']: tipodocumento.codigo,
                        ['documento']: Number(Movimiento.ndocumento + ""),
                        ['caja']: props.caja,
                        ['fecha']: fechahoy,
                        ['afecta']: 'debito'
                    }
                    return dat
                })
                setMediodepagos(dataenvio)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const tipodedocumentoexiste = async (e) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/unico/' + e + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                if (data.codigo != '') {
                    return true
                } else {
                    return false
                }

                // setPendiente(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const cerrarModalMovimientos = () => {
        setVerModalMovimientos(false);
        // setbusquedaMovimientos(Movimientos)
    }
    const verificar = (_movimiento) => {
        var faencmovi = _movimiento.faencmovi
        var famovimiento = _movimiento.famovimiento
        var verificado = true
        if (faencmovi.concepto == "" || faencmovi.fecha == "" || faencmovi.cliente == "") {
            return false
        }
        if ((tipodocumento.captionvend != "" && (tipodocumento.mvendedor == "si"))) {
            if (faencmovi.vendedor == "") {
                return false
            }
        }

        famovimiento.map((dato, index) => {
            if (dato.referencia == "" || dato.bodega == "" || dato.cantidad <= 0 || dato.precio <= 0 || dato.concepto == "") {
                verificado = false
            }

        })
        return verificado
    }
    const convertiraelectronica = async (mesa, ndocumento) => {
        try {

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Movimientoapi/convertirrestauranteelectronicadomi/' + mesa + '/' + ndocumento + '?llave=' + dt.usuario.llaveempresa + '&usuario=' + dt.usuario.cedula + '&grupo=' + dt.usuario.cargo + '&nit=' + dt.empresa.empresa.nit + '&clave=' + dt.empresa.config.clave_certificado + '');

            if (response.ok) {
                let data = await response.json()
                var _movv = data.movimiento.faencmovi
                _movv.famovimiento = data.movimiento.famovimiento
                setMovimiento(_movv)
                var tipodocc = await buscarcoincidenciastipodocs(_movv.concepto)
                settipodocumento(tipodocc)
                Swal.fire(
                    'Exito!',
                    'Factura Electronica creada y Enviada',
                    'success'
                )
            } else {
                let data = await response.json()
                if (data.movimiento) {
                    var _movv = data.movimiento.faencmovi
                    _movv.famovimiento = data.movimiento.famovimiento
                    setMovimiento(_movv)
                    var tipodocc = await buscarcoincidenciastipodocs(_movv.concepto)
                    settipodocumento(tipodocc)
                }
                Swal.fire(
                    'Error!',
                    data.mensaje,
                    'error'
                )
            }
        } catch (error) {
            console.log(error)
        }

    }

    const guardarmov = async () => {
        var existe = tipodocumentos.some(p => p.codigo == Movimiento.concepto)
        if (!existe) {
            Swal.fire(
                'Error!',
                'Tipo de Documento no Existe',
                'error'
            )
            return
        }
        if (tipodocumento.afecaj != "nada" && tipodocumento.afecaj != "" && props.mesa == "domicilio") {
            var _Movimiento = { ...Movimiento }
            var efec = total - Number(_Movimiento['cheque'] + "") - Number(_Movimiento['debito'] + "") - Number(_Movimiento['credito'] + "") - Number(_Movimiento['descuentoc'] + "")

            _Movimiento['efectivo'] = (efec >= 0) ? efec : 0
            _Movimiento['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
            setMovimiento(_Movimiento)
            obtenerMediodepagos('')
            setabrirabrirmediosdepago(true)

        } else {
            terminarVenta()
        }
    }

    const buscarhoraminuto = () => {
        // Crear una nueva fecha
        var fecha = new Date();

        // Obtener la hora y los minutos
        var hora = fecha.getHours();
        var minutos = fecha.getMinutes();

        // Convertir la hora a formato de 12 horas
        var am_pm = hora >= 12 ? 'PM' : 'AM';
        hora = hora % 12;
        hora = hora ? hora : 12; // Si es 0, se cambia a 12
        return hora + ":" + (minutos < 10 ? '0' : '') + minutos + " " + am_pm
        // Imprimir la hora y los minutos en formato de 12 horas
        // console.log("Hora: " + hora + ":" + (minutos < 10 ? '0' : '') + minutos + " " + am_pm);
    }
    const guardarmov2 = async () => {
        if (tipodocumento.afecaj != "nada" && tipodocumento.afecaj != "") {
            var _Movimiento = { ...Movimiento }
            var efec = total - Number(_Movimiento['cheque'] + "") - Number(_Movimiento['debito'] + "") - Number(_Movimiento['credito'] + "") - Number(_Movimiento['descuentoc'] + "")

            _Movimiento['efectivo'] = (efec >= 0) ? efec : 0
            _Movimiento['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
            setMovimiento(_Movimiento)
            obtenerMediodepagos('')
            setabrirabrirmediosdepago(true)

        } else {
            terminarVenta()
        }
    }
    const handleChangeformapago = (e) => {
        if (tipodocumento.codigo != "") {

            let value = e.target.value
            console.log(e.target)
            var _Movimiento2 = { ...Movimiento }
            var _Movimiento = Mediodepagos.map((dato) => { return dato })
            _Movimiento = _Movimiento.map((dato) => {
                if (e.target.name == dato.codigo) {
                    var dat = {
                        ...dato,
                        ['valor']: Number(value + "")
                    }
                    return dat
                } else {
                    return dato
                }
            })
            if (e.target.name == 'descuentoc') {
                _Movimiento2['descuentoc'] = Number(value + "")
            }
            var tott = total
            if (e.target.name == "efectivo") {
                _Movimiento2['efectivo'] = Number(value + "")
                tott = Number(value + "")
                var efec = tott + Number(_Movimiento2['descuentoc'] + "")
                _Movimiento.map((dato) => {
                    efec = efec + Number(dato.valor + "")

                })
                efec = efec - total
                _Movimiento2['fletes'] = (efec > 0) ? (efec) : 0

            } else {
                var efec = tott - Number(_Movimiento2['descuentoc'] + "")
                _Movimiento.map((dato) => {
                    efec = efec - Number(dato.valor + "")

                })

                _Movimiento2['efectivo'] = (efec >= 0) ? efec : 0
                _Movimiento2['fletes'] = (efec <= 0) ? (efec * (-1)) : 0
            }

            setMediodepagos(_Movimiento)
            setMovimiento(_Movimiento2)
            setRoles([])
        } else {
            Swal.fire(
                'Opps!',
                'Tiene que seleccionar un Tipo de Documento primero',
                'error'
            )
        }



    }

    const eliminarProducto = (id) => {
        var puedoeliminar = true
        props.permisosusuario.forEach(element => {
            if (element.tipo == "nodejareliminarproductos" && element.acceso[0] == "2") {
                puedoeliminar = false
            }
        });
        if (puedoeliminar) {
            var _Movimiento = { ...Movimiento }
            var _datos = { ...Movimiento }['famovimiento']
            let _enviodatos = _datos.map((dato, index) => {
                if (index == id) {
                } else {
                    return dato
                }
            })
            _Movimiento['famovimiento'] = _enviodatos.filter(p => p != undefined)

            setMovimiento([])
            setMovimiento(_Movimiento)
            calculartotalesmovimiento(_Movimiento['famovimiento'])
            console.log(Movimiento)
            setRoles({ codig: '' })
            console.log(Roles)

        }
        // let listaproductos = productos.filter(p => p.idProducto != id)

        // setProductos(listaproductos)

        // calcularTotal(listaproductos)
    }

    function calcularDigitoVerificacion(myNit) {
        try {
            var vpri,
                x,
                y,
                z;

            // Se limpia el Nit
            myNit = myNit.replace(/\s/g, ""); // Espacios
            myNit = myNit.replace(/,/g, ""); // Comas
            myNit = myNit.replace(/\./g, ""); // Puntos
            myNit = myNit.replace(/-/g, ""); // Guiones

            // Se valida el nit
            if (isNaN(myNit)) {
                console.log("El nit/cédula '" + myNit + "' no es válido(a).");
                return "";
            };

            // Procedimiento
            vpri = new Array(16);
            z = myNit.length;

            vpri[1] = 3;
            vpri[2] = 7;
            vpri[3] = 13;
            vpri[4] = 17;
            vpri[5] = 19;
            vpri[6] = 23;
            vpri[7] = 29;
            vpri[8] = 37;
            vpri[9] = 41;
            vpri[10] = 43;
            vpri[11] = 47;
            vpri[12] = 53;
            vpri[13] = 59;
            vpri[14] = 67;
            vpri[15] = 71;

            x = 0;
            y = 0;
            for (var i = 0; i < z; i++) {
                y = (myNit.substr(i, 1));
                // console.log ( y + "x" + vpri[z-i] + ":" ) ;

                x += (y * vpri[z - i]);
                // console.log ( x ) ;    
            }

            y = x % 11;
            // console.log ( y ) ;

            return (y > 1) ? 11 - y : y;
        } catch (error) {
            return ""
        }
    }
    const buscarnombremun = (codigo) => {
        var muni = municipios.filter(p => p.codigo == codigo)
        if (muni.length != 0) {
            return muni[0].municipio + " - " + muni[0].departamento
        } else {

            return "Barranquilla" + " - " + "Atlantico"
        }
    }
    const previsualizarpdf = () => {
        // a0 - a10
        // b0 - b10
        // c0 - c10
        // dl
        // letter
        // government-letter
        // legal
        // junior-legal
        // ledger
        // tabloid
        // credit-card
        const doc = new jsPDF('p', 'pt', 'letter');
        doc.page = 1; // use this as a counter.

        console.log(doc.getFontList())
        doc.html(document.querySelector("#pruebapdfventaelectronica25555"), {
            callback: function (pdf) {
                setmostrarimagenes(true)
                doc.autoPrint();
                doc.output('dataurlnewwindow', { filename: 'factura.pdf' });
            }
        })
    }
    const print = () => {
        previsualizarpdf()
    }
    const terminarVenta = async () => {
        setesperando(true)
        if (Movimiento.anulado == "anulado" || Movimiento.anulado == "anulada") {
            Swal.fire(
                'Opps!',
                'Ya se anulo',
                'error'
            )
            setesperando(false)
            return
        }
        var existencianegativa = false
        if (tipodocumento.afecinv == "resta") {

            // props.permisosusuario.forEach(element => {
            //     if (element.tipo == "facturarennegativo" && element.acceso[0] == "1") {
            //         var conttt = 0
            //         console.log(conttt + "  primero")

            //         listaexistenciaarticulo.forEach(element => {
            //             var conrestamenora0 = true
            //             console.log(Movimiento)
            //             console.log(Movimiento.famovimiento)
            //             console.log(Movimiento.famovimiento)
            //             if ((element < Movimiento.famovimiento[conttt].cantidad) && (listaarticulo[conttt].artserv == "no" || listaarticulo[conttt].artserv == "venta" || listaarticulo[conttt].artserv == "consumoventa")) {
            //                 existencianegativa = true
            //                 console.log(conttt + "  interno")
            //             }

            //             conttt = conttt + 1
            //             console.log(conttt + "  suma")
            //         });
            //     }
            // });

        }


        if (existencianegativa) {
            Swal.fire(
                'Opps!',
                'No puede facturar con existencia negativa',
                'error'
            )
            setesperando(false)
            return
        }
        // if (!cajaabierta) {
        //     Swal.fire(
        //         'Opps!',
        //         'La caja esta cerrada para este dia',
        //         'error'
        //     )
        //     setesperando(false)
        //     return
        // }
        if (Movimiento.famovimiento.length < 1) {
            Swal.fire(
                'Opps!',
                'No existen productos',
                'error'
            )
            setesperando(false)
            return
        }
        if (Movimiento.cufe != "" && tipodocumento.facturapap == "electronica") {
            Swal.fire(
                'Opps!',
                'Este Documento ya fue emitido a la dian y por lo tanto no se puede editar',
                'error'
            )
            setesperando(false)
            return
        }
        var tipodcoumentoecxiste = await tipodedocumentoexiste(Movimiento.concepto)
        if (!tipodcoumentoecxiste) {
            Swal.fire(
                'Opps!',
                'Este tipo de documento no existe',
                'error'
            )
            setesperando(false)
            return
        }

        if (tipodocumento.captiondoc1 != "" && tipodocumento.tipodoc == "compra" && tipodocumento.transfer == "si") {
            if (Movimiento.documento1 == "") {
                Swal.fire(
                    'Opps!',
                    'Documento 1 no puede estar en blanco',
                    'error'
                )
                setesperando(false)
                return
            }
        }
        var _Mediosdepago = Mediodepagos.map((dato) => { return dato })
        var movimientoguardado = {
            faencmovi: {},
            famovimiento: [],
            caja: [],
            remisiones: [],
        }
        movimientoguardado.caja = _Mediosdepago.map((dato) => {
            var dat = {
                ['caja']: dato.caja,
                ['fecha']: dato.fecha,
                ['concepto']: dato.concepto,
                ['numero']: dato.documento,
                ['formapago']: dato.codigo,
                ['afecta']: dato.afecta,
                ['valor']: dato.valor
            }
            return dat
        })
        var descuento2 = {
            ['caja']: props.caja,
            ['fecha']: Movimiento.fecha,
            ['concepto']: tipodocumento.codigo,
            ['numero']: Number(Movimiento.ndocumento + ""),
            ['formapago']: 'descuento',
            ['afecta']: 'credito',
            ['valor']: Number(Movimiento.descuentoc + "")
        }
        var devolucion = {
            ['caja']: props.caja,
            ['fecha']: Movimiento.fecha,
            ['concepto']: tipodocumento.codigo,
            ['numero']: Number(Movimiento.ndocumento + ""),
            ['formapago']: 'devolucion',
            ['afecta']: 'credito',
            ['valor']: Number(Movimiento.fletes + "")
        }
        var efectivo2 = {
            ['caja']: props.caja,
            ['fecha']: Movimiento.fecha,
            ['concepto']: tipodocumento.codigo,
            ['numero']: Number(Movimiento.ndocumento + ""),
            ['formapago']: 'efectivo',
            ['afecta']: 'debito',

            ['valor']: Number(Movimiento.efectivo + "")
        }
        movimientoguardado.caja.push(descuento2)
        movimientoguardado.caja.push(devolucion)
        movimientoguardado.caja.push(efectivo2)
        movimientoguardado.faencmovi = { ...Movimiento }
        movimientoguardado.faencmovi['ndocumento'] = Number(movimientoguardado.faencmovi.ndocumento)
        movimientoguardado.faencmovi['bruto'] = bruto
        movimientoguardado.faencmovi['iva'] = Number(iva)
        movimientoguardado.faencmovi['descuento'] = Number(descuento)
        movimientoguardado.faencmovi['despiefac'] = Number(movimientoguardado.faencmovi.despiefac)
        movimientoguardado.faencmovi['retefuente'] = Number(retefuente)
        movimientoguardado.faencmovi['reteiva'] = Number(reteiva)
        movimientoguardado.faencmovi['ica'] = Number(reteica)
        movimientoguardado.faencmovi['retefte'] = Number(retefuente)
        movimientoguardado.faencmovi['impconsumo'] = Number(consumo)
        movimientoguardado.faencmovi['subtotal'] = Number(subTotal)
        movimientoguardado.faencmovi['total'] = Number(total)
        movimientoguardado.faencmovi['cuotai'] = Number(movimientoguardado.faencmovi.cuotai)
        movimientoguardado.faencmovi['ncuotasi'] = Number(movimientoguardado.faencmovi.ncuotasi)
        movimientoguardado.faencmovi['diferidasi'] = Number(movimientoguardado.faencmovi.diferidasi)
        movimientoguardado.faencmovi['tipocre'] = Number(movimientoguardado.faencmovi.tipocre)
        movimientoguardado.faencmovi['ncuotasf'] = Number(movimientoguardado.faencmovi.ncuotasf)
        movimientoguardado.faencmovi['tasa'] = Number(movimientoguardado.faencmovi.tasa)
        movimientoguardado.faencmovi['diferidas'] = Number(movimientoguardado.faencmovi.diferidas)
        movimientoguardado.faencmovi['efectivo'] = Number(movimientoguardado.faencmovi.efectivo)
        movimientoguardado.faencmovi['cheque'] = Number(movimientoguardado.faencmovi.cheque)
        movimientoguardado.faencmovi['debito'] = Number(movimientoguardado.faencmovi.debito)
        movimientoguardado.faencmovi['credito'] = Number(movimientoguardado.faencmovi.credito)
        movimientoguardado.faencmovi['descuentoc'] = Number(movimientoguardado.faencmovi.descuentoc)
        movimientoguardado.faencmovi['cree'] = Number(movimientoguardado.faencmovi.cree)
        movimientoguardado.faencmovi['fletes'] = Number(movimientoguardado.faencmovi.fletes)
        movimientoguardado.faencmovi['tcambio'] = Number(movimientoguardado.faencmovi.tcambio)
        movimientoguardado.faencmovi['dsctopp1'] = Number(movimientoguardado.faencmovi.dsctopp1)
        movimientoguardado.faencmovi['dsctopp2'] = Number(movimientoguardado.faencmovi.dsctopp2)
        movimientoguardado.faencmovi['dsctopp3'] = Number(movimientoguardado.faencmovi.dsctopp3)
        movimientoguardado.faencmovi['dsctovapp1'] = Number(movimientoguardado.faencmovi.dsctovapp1)
        movimientoguardado.faencmovi['dsctovapp2'] = Number(movimientoguardado.faencmovi.dsctovapp2)
        movimientoguardado.faencmovi['dsctovapp3'] = Number(movimientoguardado.faencmovi.dsctovapp3)
        movimientoguardado.faencmovi['convdesdoc'] = Number(movimientoguardado.faencmovi.convdesdoc)
        movimientoguardado.faencmovi['convoridoc'] = Number(movimientoguardado.faencmovi.convoridoc)
        movimientoguardado.faencmovi['poretefte'] = Number(movimientoguardado.faencmovi.poretefte)
        movimientoguardado.faencmovi['poreteiva'] = Number(movimientoguardado.faencmovi.poreteiva)
        movimientoguardado.faencmovi['porica'] = Number(movimientoguardado.faencmovi.porica)
        movimientoguardado.faencmovi['porimptoconsumo'] = Number(movimientoguardado.faencmovi.porimptoconsumo)
        movimientoguardado.faencmovi['vlr_dolar'] = Number(movimientoguardado.faencmovi.vlr_dolar)
        movimientoguardado.faencmovi['ndocprod'] = Number(movimientoguardado.faencmovi.ndocprod)
        movimientoguardado.faencmovi['ndoctrasl'] = Number(movimientoguardado.faencmovi.ndoctrasl)
        movimientoguardado.faencmovi['ndocodcped'] = Number(movimientoguardado.faencmovi.ndocodcped)
        movimientoguardado.faencmovi['estacion'] = props.mesa
        movimientoguardado.faencmovi['periodo'] = "abierta"
        movimientoguardado.faencmovi['tercero'] = "cliente"
        movimientoguardado.faencmovi['plazo'] = Number(movimientoguardado.faencmovi.plazo ? movimientoguardado.faencmovi.plazo : "0")
        var datt = new Date(movimientoguardado.faencmovi['fecha'])
        datt = datt.setDate(new Date(movimientoguardado.faencmovi['fecha']).getDate() + Number(movimientoguardado.faencmovi['plazo']))
        datt = new Date(datt)
        datt = datt.toISOString().split('T')[0]
        movimientoguardado.faencmovi['fechpost'] = datt

        movimientoguardado.famovimiento = { ...Movimiento }['famovimiento']
        movimientoguardado.famovimiento = movimientoguardado.famovimiento.map((dato, index) => {
            if (movimientoguardado.faencmovi.cufe != "") {
                Swal.fire(
                    'Opps!',
                    'Factura ya emitida.No se puede editar',
                    'error'
                )
                setesperando(false)
                return
            }
            if (dato.referencia != "") {
                var dat = {
                    ...dato,
                    ['nrodocumento']: movimientoguardado.faencmovi.ndocumento,
                    ['concepto']: movimientoguardado.faencmovi.concepto,
                    ['item']: (index + 1),
                    ['cantidad']: Number(dato.cantidad),
                    ['precio']: Number(dato.precio),
                    ['pordescuento']: Number(dato.pordescuento),
                    ['poriva']: Number(dato.poriva),
                    ['porimptoconsumo']: Number(dato.porimptoconsumo),
                    ['subtotal']: Number(dato.subtotal),
                    ['costo']: Number(dato.costo),
                    ['preivainc']: Number(dato.preivainc),
                    ['canprod']: Number(dato.canprod),
                    ['vlr_desc']: Number(dato.vlr_desc),
                    ['vlr_iva']: Number(dato.vlr_iva),
                    ['vlr_base_iva']: Number(dato.vlr_base_iva),
                    ['costodolar']: Number(dato.costodolar),
                    ['tasa']: Number(dato.tasa),
                    ['vlrimptoconsumo']: Number(dato.vlrimptoconsumo),
                    ['poretefte']: Number(dato.poretefte),
                    ['vlretefte']: Number(dato.vlretefte),
                    ['porica']: Number(dato.porica),
                    ['vlrica']: Number(dato.vlrica),
                    ['poreteiva']: Number(dato.poreteiva),
                    ['vlreteiva']: Number(dato.vlreteiva),
                    ['cantdig']: Number(dato.cantdig),
                    ['fecha']: movimientoguardado.faencmovi.fecha,
                    ['horafecha']: movimientoguardado.faencmovi.horafecha,
                }
                return dat

            }
        })
        movimientoguardado.famovimiento = movimientoguardado.famovimiento.filter(p => p != undefined)
        console.log(Movimiento)
        console.log(modelomovimiento)
        var bodegg = "-"

        delete movimientoguardado.faencmovi.famovimiento;

        let dt = JSON.parse(user)
        var verificado = verificar(movimientoguardado)
        if (!verificado) {
            Swal.fire(
                'Opps!',
                'Datos sin llenar',
                'error'
            )
            setesperando(false)
            return
        }
        var nnn = true
        if (Movimiento.ndocumento != 0) {
            nnn = false
        }
        var turno = false
        props.permisosusuario.forEach(element => {
            if (element.tipo == "manejaturno" && element.acceso[0] == "2") {
                turno = true
            }
        });
        let dt222s = JSON.parse(user)

        if (turno) {
            var turno = await obtenerturnos(dt222s.usuario.cedula)
            if (turno.length == 0) {
                Swal.fire(
                    'Opps!',
                    'No tiene un Turno Abierto',
                    'error'
                )
                setesperando(false)
                return
            }
        } else {
          
        }
        movimientoguardado.faencmovi['modificadopor'] = dt.usuario.cedula
        //identificador_software esto es la variable del costo
        const api = fetch("" + props.servidor + "/Movimientoapi?llave=" + dt.usuario.llaveempresa + "&nuevo=" + nnn + "&bodegg=" + barrio + "&usuario=" + dt.usuario.cedula + "&tipocosto=" + dt.empresa.config.identificador_software + "&llaveusuario=" + dt.usuario.cargo + "", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(movimientoguardado)
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then(async (dataJson) => {

                var data = dataJson;
                var _movv = data.movimiento.faencmovi
                _movv.famovimiento = data.movimiento.famovimiento
                setMovimiento(_movv)
                var pagoscaja = await buscarcoincidenciascaja(_movv.concepto, _movv.ndocumento)
                setvalorescaja(pagoscaja)
                setagregueitem(false)
                if (tipodocumento.facturapap == "electronica") {
                    try {


                        let dt = JSON.parse(user)
                        let response = await fetch(props.servidor + '/EnvioApi/' + data.movimiento.faencmovi.concepto + '/' + (data.movimiento.faencmovi.ndocumento + "") + '/' + dt.empresa.empresa.nit + '/' + dt.empresa.config.clave_certificado + '/' + tipodocumento.identificadorsw + '?llave=' + dt.usuario.llaveempresa + '');

                        if (response.ok) {
                            let data2 = await response.json()
                            // reiniciar()
                            var _movv = data2.movimiento.faencmovi
                            _movv.famovimiento = data2.movimiento.famovimiento
                            setMovimiento(_movv)
                            Swal.fire(
                                '' + data.movimiento.faencmovi.concepto + ' Creado!',
                                'Numero de ' + data.movimiento.faencmovi.concepto + ' : ' + data.movimiento.faencmovi.ndocumento,
                                'success'
                            )
                            setprevisualizar2(true)
                            setesperando(false)
                            // reiniciar()

                        } else {
                            try {
                                var ee = await response.json()
                                Swal.fire(
                                    'Opps!',
                                    'Se creo el ' + Movimiento.concepto + ' con numero: ' + data.movimiento.concepto + ' pero no se logro enviar a la Dian: ' + ee.mensaje,
                                    'error'
                                )
                                // guardarlogdeerrores(ee.mensaje)
                                setesperando(false)
                                // reiniciar()
                            } catch (error) {
                                Swal.fire(
                                    'Opps!',
                                    'Se creo el ' + Movimiento.concepto + ' con numero:' + data.movimiento.concepto + ' pero no se logro enviar a la Dian, error de conexion',
                                    'error'
                                )
                                setesperando(false)
                                // reiniciar()
                            }
                        }
                    } catch (error) {
                        var ee = await error.json()
                        try {
                            Swal.fire(
                                'Opps!',
                                'Se creo el ' + data.movimiento.faencmovi.concepto + ' pero no se logro enviar a la Dian ' + ee,
                                'error'
                            )
                        } catch (error2) {

                        }
                        setesperando(false)
                        // reiniciar()
                    }

                } else {
                    Swal.fire(
                        '' + data.movimiento.faencmovi.concepto + ' Creado!',
                        'Numero de ' + data.movimiento.faencmovi.concepto + ' : ' + data.movimiento.faencmovi.ndocumento,
                        'success'
                    )
                    // reiniciar()

                    setesperando(false)
                }


            }).catch(async (error) => {
                try {
                    var ee = await error.json()
                    Swal.fire(
                        'Opps!',
                        'No se pudo crear el movimiento: ' + ee.mensaje,
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", ee.mensaje)
                    // guardarlogdeerrores(ee.mensaje)
                    setesperando(false)
                } catch (error) {
                    Swal.fire(
                        'Opps!',
                        'No se pudo crear el movimiento: ' + 'Error de conexion',
                        'error'
                    )
                    console.log("No se pudo enviar la venta ", error)
                    // guardarlogdeerrores('Error de conexion')
                    setesperando(false)
                }

            })

    }


    const seleccionarvendedors = (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['vendedor'] = value.codigo
        _Movimiento['nombreven'] = value.nombre
        setMovimiento(_Movimiento)
        setRoles([])
        setVerModalvendedors(false)
    }



    const buscartodasvendedors = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Vendedorapi/nombres/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {
                    setvendedors(dataJson)
                    console.log(dataJson)
                    if (dataJson.length > 0) {
                        // seleccionarvendedors(dataJson[0])

                    }
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    setvendedors([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const cambiarterceros = async (e) => {
        try {
            var tipotercero = "cliente"
            if (tipodocumento.tipodoc == "compra" || tipodocumento.tipodoc == "ordendecompra") {
                tipotercero = "proveedor"

            }

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Caclientesapi/unico/' + e.target.value + '/' + tipotercero + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                var data2 = data.caclientes
                if (data2.codigo != '') {
                    seleccionarterceros(data2)
                } else {
                    // var _Movimiento = { ...Movimiento }
                    // _Movimiento['nombrecli'] = ""
                    // _Movimiento['tercero'] = ""
                    // _Movimiento['direccion'] = ""


                    // setMovimiento(_Movimiento)
                }

                // setPendiente(false)
            }

        } catch (error) {
            console.log(error)
        }
    }
    const seleccionarterceros = async (e) => {
        //si cambio algo aqui lo deberia de cambiar tambien en   ponerterceroenblanco()
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['cliente'] = value.codigo
        _Movimiento['nombrecli'] = value.razsoc
        _Movimiento['tercero'] = value.tercero
        _Movimiento['direccion'] = value.direccion
        _Movimiento['documento2'] = value.email
        _Movimiento['concodcped'] = value.tel1

        var listadeprecio = listasdeprecio.filter(p => p.codigo == value.precsug)
        if (listadeprecio.length > 0) {
            setbarrio(listadeprecio[0].codigo)
            setnombarrio(listadeprecio[0].nombre)
        }


        setMovimiento(_Movimiento)
        settercero(value)

    }
    const seleccionartipodocumentos = async (e) => {
        let value = e
        var _Movimiento = { ...Movimiento }
        _Movimiento['concepto'] = value.codigo
        _Movimiento['fecha'] = fechahoy


        setabrirtipodedocumento(false)
        settipodocumento(value)
        setMovimiento(_Movimiento)
        var sucursal2 = await buscarcoincidenciassucursal(value.compañia)
        setsucursal(sucursal2)

    }
    const buscarcoincidenciassucursal = async (coincidencia) => {
        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Sucursalapi/unico/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()
                return data
            }
        } catch (error) {
            Swal.fire(
                'Opps!',
                'No se pudo buscar los terceros',
                'error'
            )
            return []
        }
    }
    const buscartodastipodocumentos = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/Restaurante/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {

                    settipodocumentos(dataJson)
                    console.log(dataJson)
                    if (dataJson.length > 0) {
                        seleccionartipodocumentos(dataJson[0])
                    }
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }
    const buscartodastipodocumentos2 = (coincidencia) => {
        try {

            let dt = JSON.parse(user)
            const api = fetch(props.servidor + '/Fatipodocapi/Restaurante/' + coincidencia + '?llave=' + dt.usuario.llaveempresa + '')
                .then((response) => {
                    return response.ok ? response.json() : Promise.reject(response);
                })
                .then((dataJson) => {

                    settipodocumentos(dataJson)
                    console.log(dataJson)
                    if (dataJson.length > 0) {
                        seleccionartipodocumentos(dataJson[0])
                        var mmm = { ...modelomovimiento }
                        mmm.concepto = dataJson[0].codigo
                        setMovimiento(mmm)
                    }
                }).catch((error) => {
                    console.log("No se pudo obtener datos, mayor detalle: ", error)
                    settipodocumentos([])
                })
        } catch (error) {
            console.log(error)
        }
    }

    const handleChange = async (e) => {
        if (tipodocumento.codigo != "") {
            let value = e.target.value

            console.log(e.target)
            var _Movimiento = { ...Movimiento }
            _Movimiento[e.target.name] = value
            setMovimiento(_Movimiento)
            setRoles([])
        } else {
            Swal.fire(
                'Opps!',
                'Tiene que seleccionar un Tipo de Documento primero',
                'error'
            )
        }



    }
    return (
        <Fragment>
            <div className="mt-2" style={{ color: 'black' }}>

                {/* <div className="mt-2 mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <h4 style={{ color: 'black' }}>Carta</h4>

                    <div className="text-decoration-none text-dark">
                        <i className="fas fa-shopping-cart align-middle cart-icon"></i>
                        <span className="badge badge-warning" id="lblCartCount">
                            {" "}
                            {2}{" "}
                        </span>
                    </div>

                </div> */}
                <Row>
                    <Col sm={7}>
                        <div className="table-responsive" style={{ height: '800px', overflow: 'auto' }}>
                            <Row>
                                <Col sm={1}>
                                    <FormGroup inline={true}>
                                        <Label>Tipo Doc</Label>
                                        <InputGroup >
                                            <Input onChange={(e) => {
                                                var _Movimiento = { ...Movimiento }
                                                _Movimiento['concepto'] = e.target.value
                                                setMovimiento(_Movimiento)
                                            }} name="concepto" value={Movimiento.concepto} bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter" && e.target.value == "" && Movimiento.famovimiento.length < 1) {
                                                    e.preventDefault()
                                                    buscartodastipodocumentos('');
                                                    setabrirtipodedocumento(true)


                                                } else {
                                                    if (Movimiento.famovimiento.length > 1) {
                                                        Swal.fire(
                                                            'Opps!',
                                                            'Al tener Articulos agregados no puede cambiar el tipo de documento',
                                                            'error'
                                                        )
                                                    }
                                                }
                                            }} />

                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup inline={true}>
                                        <Label>Tercero</Label>
                                        <InputGroup>
                                            <Input onChange={(e) => {
                                                var _Movimiento = { ...Movimiento }
                                                _Movimiento['cliente'] = e.target.value
                                                setMovimiento(_Movimiento)
                                            }} value={Movimiento.cliente} bsSize="sm" onBlur={(e) => {
                                                cambiarterceros(e)
                                            }} name="cliente" />
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Nombre</Label>
                                        <Input onChange={handleChange} value={Movimiento.nombrecli} type="text" bsSize="sm" name="nombrecli" />
                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup inline={true}>
                                        <Label>Barrio</Label>
                                        <InputGroup>
                                            <Input onChange={(e) => {

                                            }} value={nombarrio} bsSize="sm" name="barrio" onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    e.preventDefault()
                                                    setabrirbarrio(true)


                                                } else {

                                                }
                                            }} />
                                        </InputGroup>

                                    </FormGroup>
                                </Col>
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Direccion</Label>
                                        <Input onChange={handleChange} value={Movimiento.direccion} type="text" bsSize="sm" name="direccion" />
                                    </FormGroup>
                                </Col>

                                {(tipodocumento.mvendedor == "si") && <Col sm={2}>
                                    <FormGroup inline={true}>
                                        <Label>{(tipodocumento.captionvend == "") ? 'Vendedor' : tipodocumento.captionvend}</Label>
                                        <InputGroup>
                                            <Input value={Movimiento.vendedor} bsSize="sm" onKeyDown={(e) => {
                                                if (e.key == "Enter") {
                                                    e.preventDefault()
                                                    buscartodasvendedors('');
                                                    setVerModalvendedors(true)

                                                }
                                            }}


                                                name="vendedor" />
                                            <Button color="primary" size="sm" className="mr-2"
                                                onClick={() => {
                                                    buscartodasvendedors('');
                                                    setVerModalvendedors(true)
                                                }}
                                            >
                                                <i className="fas fa-pen-alt"></ i >
                                            </Button>
                                        </InputGroup>

                                    </FormGroup>
                                </Col>}
                                <Col sm={2}>
                                    <FormGroup>
                                        <Label>Email</Label>
                                        <Input onChange={handleChange} value={Movimiento.documento2} type="text" bsSize="sm" name="documento2" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Consecutivo</Label>
                                        <Input disabled onChange={handleChange} value={Movimiento.ndocumento} type="text" bsSize="sm" name="ndocumento" />
                                    </FormGroup>
                                </Col>
                                <Col sm={3}>
                                    <FormGroup>
                                        <Label>Tel</Label>
                                        <Input onChange={handleChange} value={Movimiento.concodcped} type="text" bsSize="sm" name="concodcped" />
                                    </FormGroup>
                                </Col>

                            </Row>
                            <Row>
                                <MenuCard total2={total2} mostrarimagenes={mostrarimagenes} barrio={barrio} calculartotalesmovimiento={(datt, propina) => { calculartotalesmovimiento(datt, propina) }} servidor={props.servidor} tipodocumento={tipodocumento} Movimiento={Movimiento} setMovimiento={(va) => {
                                    setagregueitem(true)
                                    setMovimiento(va)
                                }} />

                            </Row>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <ItemCart
                            permisosusuario={props.permisosusuario}
                            eliminarProducto={eliminarProducto}
                            reiniciar={reiniciar}
                            consumo={consumo}
                            bruto={bruto}
                            total={total}
                            total2={total2}
                            mesa={props.mesa}
                            menus={menus}
                            setmostrarimagenes={setmostrarimagenes}
                            calculartotalesmovimiento={calculartotalesmovimiento}
                            print={() => {
                                if (Movimiento.ndocumento !== 0 && !agregueitem) {
                                    setmostrarimagenes(false);
                                    setprevisualizar(true);
                                }
                            }}
                            print2={() => {
                                if (Movimiento.ndocumento !== 0 && !agregueitem) {
                                    setmostrarimagenes(false);
                                    setprevisualizar2(true);
                                }
                            }}
                            print23={() => {
                                if (Movimiento.ndocumento !== 0 && !agregueitem) {
                                    convertiraelectronica(props.mesa, Movimiento.ndocumento);
                                }
                            }}
                            terminarVenta={guardarmov}
                            terminarVenta2={guardarmov2}
                            abrirbuscar={() => setVerModalMovimientos(true)}
                            servidor={props.servidor}
                            tipodocumento={tipodocumento}
                            Movimiento={Movimiento}
                            setMovimiento={setMovimiento}
                        />

                        <Card className="mb-3 shadow bg-white rounded">
                            <CardBody>
                                <Row>
                                    <Col sm={12}>
                                        <Row className="mb-3">
                                            <Col sm={12}>
                                                <Button
                                                    color="primary"
                                                    size="sm"
                                                    className="w-100 mt-2"
                                                    onClick={() => setVerModalMovimientos(true)}
                                                >
                                                    BUSCAR
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col sm={12}>
                                                <InputGroup size="sm">
                                                    <InputGroupText className="labelmovtotalmediopago">
                                                        El Total es:
                                                    </InputGroupText>
                                                    <Input disabled className="inputmovtotal" value={fm3.from(total)} />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <Button
                                                    className="w-100"
                                                    style={{ fontSize: '12px', backgroundColor: '#F5F5DC', color: 'black' }}
                                                    onClick={() => {
                                                        if (!previsualizar) {
                                                            setprevisualizar3(true);
                                                        }
                                                    }}
                                                >
                                                    <i className="fa fa-paper-plane"></i> PDF 2
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>

                    </Col>
                </Row>

                <Button
                    color="primary"
                    size="sm"
                    className="mt-2 not-allowed"
                    // disabled={check.includes(0)}
                    onClick={() => {
                        setvisualizartotales(true)
                        // props.print()
                        // addToOrders();
                        // if (userData.adminCheck === true) {
                        //   // reservedCase(table.id);
                        // }
                        // refreshPage();
                    }}
                >
                    Ver Totales
                </Button>

            </div>
            <TipodeDocumento tituloencabezado={"Buscar Tipo de Documento"} abrir={abrirtipodedocumento} servidor={props.servidor} setabrirbanco={(ab) => { setabrirtipodedocumento(ab) }} seleccionar={(value) => {
                setabrirtipodedocumento(false)
            }} />
            <ListaPrecio tituloencabezado={"Buscar Barrio"} abrir={abrirbarrio} servidor={props.servidor} setabrirbanco={(ab) => { setabrirbarrio(ab) }} seleccionar={(value) => {
                setabrirbarrio(false)
                setbarrio(value.codigo)
                setnombarrio(value.nombre)
            }} />
            <Buscar tituloencabezado={"Buscar Vendedor"} filtrar={(coincidencia) => { }} busqueda={vendedors} encabezado={["Codigo", "Nombre"]} codigo={"codigo"} nombre={"nombre"} dato={""} dato2={""} verModal={verModalvendedors} cerrarModal={() => { setVerModalvendedors(false) }} seleccionar={(seleccionado) => {
                seleccionarvendedors(seleccionado)
            }} />
            <Esperando verModal={esperando} />
            <Comanda setmostrarimagenes={(a) => {
                setmostrarimagenes(a)
            }} previsualizar={previsualizar} user={user} Movimiento={Movimiento} dataUser={dataUser} totalunidades={() => { totalunidades() }} setprevisualizar={() => {

                setprevisualizar(false)
            }} />
            {tipodocumento.facturapap == "electronica" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar3}>

                    <ModalBody id={"pruebapdfventaelectronica78787"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + "-" + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + (tercero && tercero.tel1)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + (tercero && tercero.pais)}<br />
                                        {"Ciudad: " + (tercero && tercero.municipio) + "-" + (tercero && tercero.nompais)}<br />
                                        {"Direccion: " + (tercero && tercero.direccion)}<br />
                                        {"Email: " + (tercero && tercero.email)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar3(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.facturapap == "pos" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar3}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' : tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar3(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "" &&
                <Modal size="lg" isOpen={previsualizar2} onOpened={() => {
                    previsualizarpdf()
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica25555"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Arial, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    {/* <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                    {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                </Col> */}
                                    {/* <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
    
                                </Col> */}
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                        {"Telefono: " + JSON.parse(user).empresa.empresa.telefono}<br />
                                        {JSON.parse(user).empresa.empresa.email}<br />

                                        {"Tiquete POS No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {Movimiento.cliente}<br />
                                        {"Domiciliario: " + Movimiento.nombreven}<br />
                                        {"Direccion: " + Movimiento.direccion}<br />
                                        {"Mesa: " + Movimiento.estacion}<br />

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                            <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                {"Unidades:" + totalunidades()}
    
                            </Col>
    
                        </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto + consumo)}</th>
                                                </tr>
                                                {
                                                    consumo != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"ImpoConsumo:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(consumo)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Propina:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(Movimiento.convoridoc)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '8px', color: 'black' }}>

                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                                {/* <Row style={{ marginTop: '40px' }}>
                                <Col sm={2} >
    
                                </Col>
    
                                <Col sm={7} style={{ border: '1px solid black', width: '300px', color: 'white' }}>
    
                                </Col>
                                <Col sm={2} >
    
                                </Col>
    
    
                            </Row >
                            <Row >
                                <Col sm={3} >
    
                                </Col>
    
                                <Col sm={7} style={{ color: 'black' }}>
                                    FIRMA
                                </Col>
                                <Col sm={1} >
    
                                </Col>
    
    
                            </Row >
                            <Row>
                                <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
    
    
    
                                </Col>
    
                            </Row> */}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>



                        <Button size="sm" color="primary" id="botonfactura1enrestaurante" onClick={() => {
                            setprevisualizar2(false)
                            print()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setmostrarimagenes(true)
                            setprevisualizar2(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            }
            {tipodocumento.modidoc == "tirilla 1" &&
                <Modal size="lg" isOpen={previsualizar2} onOpened={() => {
                    previsualizarpdf()
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica25555"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Arial, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    {/* <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                    {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                </Col> */}
                                    {/* <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
    
                                </Col> */}
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                        {"Telefono: " + JSON.parse(user).empresa.empresa.telefono}<br />
                                        {JSON.parse(user).empresa.empresa.email}<br />

                                        {"Tiquete POS No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {Movimiento.cliente}<br />
                                        {"Domiciliario: " + Movimiento.nombreven}<br />
                                        {"Direccion: " + Movimiento.direccion}<br />
                                        {"Mesa: " + Movimiento.estacion}<br />

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto + consumo)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(consumo)}</th>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Propina:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(Movimiento.convoridoc)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '8px', color: 'black' }}>

                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                                {/* <Row style={{ marginTop: '40px' }}>
                                <Col sm={2} >
    
                                </Col>
    
                                <Col sm={7} style={{ border: '1px solid black', width: '300px', color: 'white' }}>
    
                                </Col>
                                <Col sm={2} >
    
                                </Col>
    
    
                            </Row >
                            <Row >
                                <Col sm={3} >
    
                                </Col>
    
                                <Col sm={7} style={{ color: 'black' }}>
                                    FIRMA
                                </Col>
                                <Col sm={1} >
    
                                </Col>
    
    
                            </Row >
                            <Row>
                                <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
    
    
    
                                </Col>
    
                            </Row> */}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>



                        <Button size="sm" color="primary" id="botonfactura1enrestaurante" onClick={() => {
                            setprevisualizar2(false)
                            print()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setmostrarimagenes(true)
                            setprevisualizar2(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            }
            {tipodocumento.modidoc == "tirilla 2" &&
                <Modal size="lg" isOpen={previsualizar2} onOpened={() => {
                    previsualizarpdf()
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica25555"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Arial, monospace', fontWeight: 'bold', color: 'black' }}>
                                    {/* <Col sm={12} style={{ textAlign: 'center', fontSize: '14px', fontWeight: 'normal' }}>
                                    {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                </Col> */}
                                    {/* <Col sm={12} style={{ textAlign: 'center', fontSize: '14px', color: 'black' }}>
    
                                </Col> */}
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '14px', color: 'black', fontWeight: 'bold' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion}<br />
                                        {"Telefono: " + JSON.parse(user).empresa.empresa.telefono}<br />
                                        {"Correo: " + JSON.parse(user).empresa.empresa.email}<br />

                                        {"Tiquete POS No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {tercero && tercero.caclientes ? tercero.caclientes.clase == "13" ? "Cedula: " + Movimiento.cliente : "Nit: " + Movimiento.cliente : tercero.clase == "13" ? "Cedula: " + Movimiento.cliente : "Nit: " + Movimiento.cliente}<br />

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '14px', color: 'black', borderColor: 'black;', fontWeight: 'bold' }}>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '14px', color: 'black', fontWeight: 'bold' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                            <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                {"Unidades:" + totalunidades()}
    
                            </Col>
    
                        </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '14px', color: 'black', fontWeight: 'bold' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto + consumo)}</th>
                                                </tr>

                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(0)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"TOTAL:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '8px', color: 'black' }}>

                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                                {/* <Row style={{ marginTop: '40px' }}>
                                <Col sm={2} >
    
                                </Col>
    
                                <Col sm={7} style={{ border: '1px solid black', width: '300px', color: 'white' }}>
    
                                </Col>
                                <Col sm={2} >
    
                                </Col>
    
    
                            </Row >
                            <Row >
                                <Col sm={3} >
    
                                </Col>
    
                                <Col sm={7} style={{ color: 'black' }}>
                                    FIRMA
                                </Col>
                                <Col sm={1} >
    
                                </Col>
    
    
                            </Row >
                            <Row>
                                <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
    
    
    
                                </Col>
    
                            </Row> */}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>



                        <Button size="sm" color="primary" id="botonfactura1enrestaurante" onClick={() => {
                            setprevisualizar2(false)
                            print()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setmostrarimagenes(true)
                            setprevisualizar2(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            }
            {tipodocumento.modidoc == "tirilla 4" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }}>
                            <Col sm={2}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'bold' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + dataUser.usuario.nombre}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody style={{ color: 'black' }}>
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 5" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }}>
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + dataUser.usuario.nombre}<br />
                                        {Movimiento.nombreven != "" && "Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '12px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Uni</th>
                                                    <th style={{ width: '10%' }}>Tot</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '12px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional.length <= 20 ? item.desadicional : item.desadicional.substring(0, 19) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '12px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody style={{ color: 'black' }}>
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 6" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + dataUser.usuario.nombre}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip + " " + item.desadicional}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}
                                        {"Generado por Solifactin S.A.S"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 7" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        No responsable de Iva

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.codigo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {"GARANTIA Por Defecto del Producto"}<br />
                                        {"Presentando el tiquete y el empaque Original"}<br />
                                        {"De lunes a viernes de 9:00am a 5:00pm"}<br />
                                        {"Siguenos en nuestras redes sociales"}<br />
                                        {"@fixmyapples"}<br />


                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}

            {tipodocumento.modidoc == "tirilla 8" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }}>
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.codigo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>

                                    <Col sm={7} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={2} >

                                    </Col>


                                </Row >
                                <Row >
                                    <Col sm={3} >

                                    </Col>

                                    <Col sm={7} style={{ color: 'black' }}>
                                        FIRMA
                                    </Col>
                                    <Col sm={1} >

                                    </Col>


                                </Row >
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>



                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}

            {tipodocumento.modidoc == "tirilla 9" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + dataUser.usuario.nombre}<br />
                                        {"Cod. Vendedor: " + Movimiento.vendedor}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}
                                        {"Generado por Solifactin S.A.S"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 10" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + sucursal.nombrecomercial} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {/* {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br /> */}
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        No responsable de Iva

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.codigo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {"GARANTIA Por Defecto del Producto"}<br />
                                        {"Presentando el tiquete y el empaque Original"}<br />
                                        {"De lunes a viernes de 9:00am a 5:00pm"}<br />
                                        {"Siguenos en nuestras redes sociales"}<br />
                                        {"@fixmyapples"}<br />


                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}



            {tipodocumento.modidoc == "tirilla 11" &&
                <Modal size="lg" isOpen={previsualizar2} onOpened={() => {
                    previsualizarpdf()
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica25555"} >
                        <Row style={{ color: 'black' }} >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Arial, monospace' }}>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Kelpt A2 Medio, monospace', color: 'black' }} >

                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal != 'R-99-PN' && (JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal == 'O-47' && 'Régimen simple de tributación')}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {/* No responsable de Iva */}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.prefijo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '11px', color: 'black' }}>
                                        {tipodocumento.facturapap == "electronica" ? "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res : "Resolución DIAN POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '30%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.referencia + " - " + item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from((item.precio * (1 + (item.poriva / 100))))}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                {retefuente > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Fte:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(retefuente)}</th>
                                                    </tr>
                                                }
                                                {reteiva > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteiva)}</th>
                                                    </tr>
                                                }
                                                {reteica > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Ica:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteica)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar2(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar2(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}

            {tipodocumento.modidoc == "tirilla 12" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '8px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Factura de Venta No: " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"-------------------------------------"}<br />
                                        {"Cajero: " + dataUser.usuario.nombre}<br />
                                        {"Vendedor: " + Movimiento.nombreven}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '8px', color: 'black' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '8px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '8px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '8px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '8px', color: 'black' }}>
                                        {"Tel:3187653564 - 3218561024"}<br />
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 13" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }} >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace' }}>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal != 'R-99-PN' && (JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal == 'O-47' && 'Régimen simple de tributación')}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {/* No responsable de Iva */}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.prefijo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '11px', color: 'black' }}>
                                        {tipodocumento.facturapap == "electronica" ? "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res : "Resolución DIAN POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '30%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.referencia + " - " + item.descrip + " - " + item.desadicional}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from((item.precio * (1 + (item.poriva / 100))))}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                {retefuente > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Fte:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(retefuente)}</th>
                                                    </tr>
                                                }
                                                {reteiva > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteiva)}</th>
                                                    </tr>
                                                }
                                                {reteica > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Ica:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteica)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}

            {tipodocumento.modidoc == "tirilla 14" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row style={{ color: 'black' }} >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace' }}>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', fontFamily: 'Kelpt A2 Medio, monospace' }} >

                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal != 'R-99-PN' && (JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal == 'O-47' && 'Régimen simple de tributación')}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {/* No responsable de Iva */}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.prefijo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '11px', color: 'black' }}>
                                        {tipodocumento.facturapap == "electronica" ? "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res : "Resolución DIAN POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '30%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.referencia + " - " + item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from((item.precio * (1 + (item.poriva / 100))))}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                {retefuente > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Fte:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(retefuente)}</th>
                                                    </tr>
                                                }
                                                {reteiva > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteiva)}</th>
                                                    </tr>
                                                }
                                                {reteica > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Ica:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteica)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 15" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={3}>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {"xxxxx"}<br />
                                        {"PARA QUEJAS Y RECLAMOS"}<br />
                                        {JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Servido por " + dataUser.usuario.nombre}<br />
                                        {Movimiento.ndocumento}<br />
                                        {"-------------------------------------"}<br />
                                        {(Movimiento.nombrecli != "222222222" && Movimiento.nombrecli != "222222222222") && "Cliente:" + Movimiento.nombrecli}<br />
                                        {"-------------------------------------"}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                {iva != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right' }}>{"Iva:"}</th>
                                                        <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(iva)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}
                                        {"Generado por Solifactin S.A.S"}
                                        {"Tel:3187653564 - 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 16" &&
                <Modal size="lg" isOpen={previsualizar2}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace', fontWeight: 'lighter', color: 'black' }}>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {sucursal && sucursal.nit != "" ? sucursal.nombrecomercial : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + (sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit) + " - " + calcularDigitoVerificacion(sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit)}<br />

                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.codigo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm" className="bordetabla" >
                                            <thead className="bordetabla">
                                                <tr className="bordetabla" style={{ fontSize: '10px', color: 'black', borderColor: 'black;' }}>
                                                    <th style={{ width: '10%', color: 'black' }}>Cant</th>
                                                    <th style={{ width: '20%', color: 'black' }}>Descr</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%', color: 'black' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.precio * item.cantidad)}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row> */}
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(descuento)}</th>
                                                    </tr>
                                                }
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(subTotal)}</th>
                                                    </tr>
                                                }
                                                {iva != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(iva)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>

                                        {"Observacion: " + tipodocumento.camdescpp + " " + Movimiento.observacion}<br />


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>

                                        {/* {"GARANTIA Por Defecto del Producto"}<br />
                                        {"Presentando el tiquete y el empaque Original"}<br />
                                        {"De lunes a viernes de 9:00am a 5:00pm"}<br />*/}
                                        {JSON.parse(user).empresa.empresa.nit == "22300927" &&
                                            "Siguenos en nuestras redes sociales"}<br />
                                        {JSON.parse(user).empresa.empresa.nit == "22300927" &&
                                            "@angelsthperfumeria"}<br />


                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar(false)
                            previsualizarpdflargo()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}

            {tipodocumento.modidoc == "tirilla 17" &&
                <Modal size="lg" isOpen={previsualizar2} onOpened={() => {
                    previsualizarpdf()
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica25555"} >
                        <Row style={{ color: 'black' }} >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Kelpt A2 Medio, monospace' }}>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Kelpt A2 Medio, monospace', color: 'black' }} >

                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {/* No responsable de Iva */}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.prefijo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {"Tel: " + Movimiento.cliente}<br />
                                        {Movimiento.direccion != "" && "Direccion Domicilio: " + Movimiento.direccion}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '11px', color: 'black' }}>
                                        {tipodocumento.num_res != "" && tipodocumento.facturapap == "electronica" ? "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res : tipodocumento.num_res != "" && "Resolución DIAN POS No. " + tipodocumento.num_res}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && ".Valido hasta " + tipodocumento.vencresolu}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}{tipodocumento.num_res != "" && <br />}
                                        {tipodocumento.num_res != "" && Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}{tipodocumento.num_res != "" && <br />}
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '30%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.referencia + " - " + item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from((item.precio * (1 + (item.poriva / 100))))}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr style={{ lineHeight: '0.5' }}  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr> */}
                                                <tr style={{ lineHeight: '0.5' }} >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr style={{ lineHeight: '0.5' }} >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                {retefuente > 0 &&
                                                    <tr style={{ lineHeight: '0.5' }} >
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Fte:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(retefuente)}</th>
                                                    </tr>
                                                }
                                                {reteiva > 0 &&
                                                    <tr style={{ lineHeight: '0.5' }} >
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteiva)}</th>
                                                    </tr>
                                                }
                                                {reteica > 0 &&
                                                    <tr style={{ lineHeight: '0.5' }} >
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Ica:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteica)}</th>
                                                    </tr>
                                                }
                                                <tr style={{ lineHeight: '0.5' }} >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar2(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar2(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "tirilla 18" &&
                <Modal size="lg" isOpen={previsualizar2} onOpened={() => {
                    previsualizarpdf()
                }}>
                    <ModalHeader>
                        Opciones
                    </ModalHeader>
                    <ModalBody id={"pruebapdfventaelectronica25555"} >
                        <Row style={{ color: 'black' }} >
                            <Col sm={4}>
                                <Row style={{ fontFamily: 'Arial, monospace' }}>

                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'bold', fontFamily: 'Kelpt A2 Medio, monospace', color: 'black' }} >

                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal != 'R-99-PN' && (JSON.parse(user).empresa.config.contribuyente_responsabilidad_fiscal == 'O-47' && 'Régimen simple de tributación')}<br />

                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {/* No responsable de Iva */}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.consecfac + " No: " + (sucursal && sucursal.nit != "" ? sucursal.descripcion : tipodocumento.prefijo) + " " + Movimiento.ndocumento}<br />
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"----------------------------"}<br />
                                        {"Cliente: " + Movimiento.nombrecli}<br />
                                        {(tercero && tercero.clase == "31" ? "Nit: " : "Cedula: ") + Movimiento.cliente}<br />
                                        {"Vendedor: " + Movimiento.nombreven}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '11px', color: 'black' }}>
                                        {tipodocumento.facturapap == "electronica" ? "Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res : "Resolución DIAN POS No. " + tipodocumento.num_res}<br />
                                        {" de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f}<br />
                                        {".Valido hasta " + tipodocumento.vencresolu}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Table size="sm">
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '30%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Vr.Unit</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }} >{item.referencia + " - " + item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from((item.precio * (1 + (item.poriva / 100))))}</td>
                                                                    <td style={{ textAlign: 'left', color: 'black' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades()}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(bruto)}</th>
                                                </tr>
                                                {/* <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Descuento:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(descuento)}</th>
                                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(subTotal)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(iva)}</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(consumo)}</th>
                                                </tr>
                                                {retefuente > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Fte:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(retefuente)}</th>
                                                    </tr>
                                                }
                                                {reteiva > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Iva:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteiva)}</th>
                                                    </tr>
                                                }
                                                {reteica > 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{"Rete Ica:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(reteica)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black' }}>{tabespacio + fm3.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} sty le={{ textAlign: 'center', fontSize: '10px' }}>
                                        <Row>
                                            <Col sm={3}>
                                                -----
                                            </Col>
                                            <Col sm={6} style={{ fontSize: '13px', color: 'black' }}>
                                                Formas de Pago
                                            </Col>
                                            <Col sm={3}>
                                                -----
                                            </Col></Row>
                                        {valorescaja.map((dato) => {
                                            return <>
                                                <Row style={{ color: 'black', fontSize: '10px' }}>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {dato.formapago.toUpperCase()}
                                                    </Col>
                                                    <Col sm={4} style={{ fontSize: '10px' }}>

                                                    </Col>
                                                    <Col sm={4} style={{ textAlign: 'left' }}>
                                                        {fm.from(dato.valor)}
                                                    </Col></Row>
                                            </>
                                        })}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px' }}>
                                        {"Gracias Por Su Compra"}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tel:3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            setprevisualizar2(false)
                            previsualizarpdf()
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar2(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>}
            {tipodocumento.modidoc == "electronica 1" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + "-" + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 2" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {sucursal.nombrecomercial && sucursal.nombrecomercial.includes('.') ? <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                            {<img src={"imagen/" + sucursal.nombrecomercial} alt="Image" style={{ width: '200px', borderRadius: '10px' }} />}<br />
                                        </Col> :
                                            <img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Nit: " + sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {"Email: " + sucursal && sucursal.nit != "" ? sucursal.email : JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 3" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {sucursal && sucursal.nit != "" ? sucursal.nombre : JSON.parse(user).empresa.config.encabezado_pdf}<br />
                                        {"Nit: " + sucursal && sucursal.nit != "" ? sucursal.nit : JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + (sucursal && sucursal.nit != "" ? sucursal.direccion : JSON.parse(user).empresa.empresa.direccion) + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + (sucursal && sucursal.nit != "" ? sucursal.telefono : JSON.parse(user).empresa.empresa.telefono) + " - " + (sucursal && sucursal.nit != "" ? sucursal.celular : JSON.parse(user).empresa.empresa.celular)}<br />
                                        {"Email: " + sucursal && sucursal.nit != "" ? sucursal.email : JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : tipodocumento.tipodoc == "cotizacion" ? 'COTIZACION' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {/* //eectronica mas grande en letra */}
            {tipodocumento.modidoc == "electronica 4" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : tipodocumento.tipodoc == "cotizacion" ? 'COTIZACION' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />

                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '12px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '12px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '12px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '12px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '12px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 5" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '350px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '17px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 6" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + "-" + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Responsable de IVA - No Autorretenedor, No gran Contribuyente"}<br />

                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + tercero ? tercero.cedula : Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 7" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '250px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '17px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "electronica 8" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit + "-" + calcularDigitoVerificacion(JSON.parse(user).empresa.empresa.nit)}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />
                                        {"Cufe: "}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(0, Movimiento.cufe.length / 2)}<br />
                                        {Movimiento.cufe && Movimiento.cufe.substring(Movimiento.cufe.length / 2, Movimiento.cufe.length)}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA ELECTRONICA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO ELECTRONICA" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ELECTRONICA' : 'DOCUMENTO SOPORTE ELECTRONICO'}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />

                                        <QRCode value={Movimiento.qr ? Movimiento.qr : ""} />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                        {"Email: " + tercero.email}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha + " " + buscarhoraminuto()}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.descrip + "  " + item.desadicional}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN Facturación Electrónica No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)
                            setcertificado([])
                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 1" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' : tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? (item.descrip + " " + item.desadicional) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {/* el señor edwin tiene esto para mostrar el nombre de otra cosa que no sea el real y ocultar el nit y correo */}
            {tipodocumento.modidoc == "carta 2" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.config.carpeta_bd}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ?
                                            "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' :
                                                tipodocumento.tipodoc == "cotizacion" ? 'Cotizacion' :
                                                    tipodocumento.tipodoc == "plan separe" ? 'Plan Separe' :
                                                        tipodocumento.tipodoc == "cotizacion" ? 'Cotizacion' :
                                                            tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {Movimiento.observacion}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio)}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 3" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre.replace(' ', tabespacio)}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Anterior:"}</th>
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(total)}</th> */}
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldocliente())}</th> */}

                                                </tr>
                                                {/* <tr>
                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

                                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Total:"}</th>
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total + totalsaldocliente())}</th> */}
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }

            {tipodocumento.modidoc == "carta 4" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.config.carpeta_bd.toUpperCase()}<br />
                                        {"NIT: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Regimen Simplificado: "}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Correo: " + JSON.parse(user).empresa.empresa.email}<br />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ?
                                            "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' :
                                                tipodocumento.tipodoc == "cotizacion" ? 'Cotizacion' :
                                                    tipodocumento.tipodoc == "plan separe" ? 'Plan Separe' :
                                                        tipodocumento.tipodoc == "cotizacion" ? 'Cotizacion' :
                                                            tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />
                                        {<img src={"imagen/" + dataUser.empresa.config.contribuyente_matricula_mercantil} alt="Image" style={{ width: '100px', borderRadius: '50px' }} />}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left', whiteSpace: 'pre-line' }} >{item.referencia + ' - '}{item.desadicional && item.desadicional != "" ? (item.descrip + "\n" + item.desadicional) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {Movimiento.observacion}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio)}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 5" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>

                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }

                                                {/* <tr>
                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 6" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? 'FACTURA DE VENTA' : tipodocumento.tipodoc == "devoluciones" ? "NOTA CREDITO" : tipodocumento.tipodoc == "nota debito" ? 'NOTA DEBITO ' : tipodocumento.descripcion}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />
                                        {<img src={"imagen/" + dataUser.empresa.config.contribuyente_matricula_mercantil} alt="Image" style={{ width: '100px', borderRadius: '50px' }} />}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Foto</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>

                                                                    <td style={{ textAlign: 'left' }} >{item.referencia + " - "}{item.desadicional && item.desadicional != "" ? (item.descrip + " " + item.desadicional) : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {Movimiento.observacion}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio)}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Subtotal:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Base:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(subTotal)}</th>
                                                </tr>
                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Neto:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>
                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronicadescargar()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 7" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {JSON.parse(user).empresa.config.carpeta_bd.toUpperCase()}<br />
                                        {"NIT: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Regimen Simplificado: "}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Correo: " + JSON.parse(user).empresa.empresa.email}<br />


                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.consecfac}<br />
                                        {((tipodocumento.tipodoc == "devoluciones" || tipodocumento.tipodoc == "nota debito") ? tipodocumento.codigo : tipodocumento.prefijo) + '-' + Movimiento.ndocumento}<br />
                                        {<img src={"imagen/" + dataUser.empresa.config.contribuyente_matricula_mercantil} alt="Image" style={{ width: '100px', borderRadius: '50px' }} />}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />
                                    </Col>

                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '20%' }}>Equipo</th>
                                                    <th style={{ width: '20%' }}>Descripcion</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left', whiteSpace: 'pre-line' }} >{item.descrip}</td>
                                                                    <td style={{ textAlign: 'left', whiteSpace: 'pre-line' }} >{item.desadicional + ' - '}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>


                                </Row>
                                <Row>

                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {Movimiento.observacion}

                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio)}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Col sm={6}>
                            <FormGroup>
                                {certificado.map((data) => {
                                    return <><Label>{data.nombre}</Label><Label>{tabespacio + tabespacio + tabespacio + tabespacio}</Label></>

                                })}
                            </FormGroup>
                        </Col>
                        <Col sm={3}>
                            <FormGroup>
                                <Label>Adjuntar</Label>
                                <Input bsSize="sm" type={"file"} onChange={async (e) => {
                                    var archivoxml = e.target.files[0]
                                    var cerbase64 = await base64certificado(archivoxml)
                                    var adjuntos = certificado.map((data) => {
                                        return data
                                    })
                                    adjuntos.push({
                                        nombre: archivoxml.name,
                                        adjunto: cerbase64
                                    })
                                    setcertificado(adjuntos)

                                }} >


                                </Input>
                            </FormGroup>
                        </Col>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 8" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>
                                        {JSON.parse(user).empresa.empresa.nombre.replace(' ', tabespacio)}<br />
                                        {"Nit: " + JSON.parse(user).empresa.empresa.nit}<br />
                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '10px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '10px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '10px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}<br />


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '15px', color: 'black' }}>

                                        {"El valor a pagar despues de la Fecha seria:"}<br />
                                        {" 6 AL 10:" + fm2.from((total * 1.01))}<br />
                                        {" 11 AL 15:" + fm2.from(((total * 1.01) * 1.005))}<br />
                                        {"16 AL 28:" + fm2.from((((total * 1.01) * 1.005) * 1.02))}<br />

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '10px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Anterior:"}</th>
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(total)}</th> */}
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldocliente())}</th> */}

                                                </tr>
                                                {/* <tr>
                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

                </tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Saldo Total:"}</th>
                                                    {/* <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total + totalsaldocliente())}</th> */}
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '10px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '10px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            {tipodocumento.modidoc == "carta 9" &&

                <Modal size="lg" style={{ maxWidth: '1000px', width: '95%' }} isOpen={previsualizar2}>

                    <ModalBody id={"pruebapdfventaelectronica"} >
                        <Row >
                            <Col sm={12}>
                                <Row>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal' }}>
                                        {<img src={"imagen/" + dataUser.empresa.config.ruta_logo} alt="Image" style={{ width: '200px', borderRadius: '50px' }} />}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black', fontFamily: 'Arial,monospace' }}>

                                        {"Direccion: " + JSON.parse(user).empresa.empresa.direccion + " " + buscarnombremun(JSON.parse(user).empresa.config.direccion_contribuyente_municipio)}<br />
                                        {"Telefonos: " + JSON.parse(user).empresa.empresa.telefono + " - " + JSON.parse(user).empresa.empresa.celular}<br />
                                        {"Email: " + JSON.parse(user).empresa.empresa.email.replace(' ', tabespacio)}<br />

                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {tipodocumento.descripcion + '-' + Movimiento.ndocumento}<br />




                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Señores: " + Movimiento.nombrecli}<br />
                                        {"Nit: " + Movimiento.cliente}<br />
                                        {"Telefono: " + tercero.tel1}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'left', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Departamento: " + tercero.pais}<br />
                                        {"Ciudad: " + tercero.municipio + "-" + tercero.nompais}<br />
                                        {"Direccion: " + tercero.direccion}<br />
                                    </Col>
                                    <Col sm={4} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        {"Fecha: " + Movimiento.fecha}<br />
                                        {"Fecha Vencimiento: " + Movimiento.fechpost}<br />

                                        {Movimiento.documento1 != "" && tipodocumento.captiondoc1 + ": " + Movimiento.documento1}<br />
                                        {Movimiento.documento2 != "" && tipodocumento.captiondoc2 + ": " + Movimiento.documento2}<br />

                                    </Col>
                                </Row>
                                <Row >
                                    <Col sm={12}>
                                        <Table size="sm" className='custom-table'>
                                            <thead>
                                                <tr style={{ fontSize: '13px', color: 'black' }}>
                                                    <th style={{ width: '1%' }}>Item</th>
                                                    <th style={{ width: '20%' }}>Descr</th>
                                                    <th style={{ width: '10%' }}>Cant</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>
                                                    <th style={{ width: '10%' }}>iva</th>
                                                    <th style={{ width: '10%' }}>Vr.Total</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (Movimiento.famovimiento.length < 1) ? (
                                                        <tr>
                                                            <td colSpan="15">Sin Datos</td>
                                                        </tr>
                                                    ) :
                                                        (
                                                            Movimiento.famovimiento.map((item, index) => (
                                                                <tr style={{ fontSize: '13px', color: 'black' }} key={item.item}>

                                                                    <td style={{ textAlign: 'left' }} >{index + 1}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.desadicional && item.desadicional != "" ? item.desadicional : item.descrip}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.cantidad}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.precio * 1)}</td>
                                                                    <td style={{ textAlign: 'left' }} >{item.poriva}</td>
                                                                    <td style={{ textAlign: 'left' }}>{fm.from(item.subtotal)}</td>



                                                                </tr>
                                                            ))
                                                        )


                                                }
                                            </tbody>
                                        </Table>

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Items:" + Movimiento.famovimiento.length + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {"Unidades:" + totalunidades() + tabespacio + tabespacio + tabespacio + tabespacio}
                                        {tipodocumento.tipodoc == "devoluciones" ? "Factura devolucion:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                        {tipodocumento.tipodoc == "nota debito" ? "Factura Nota:" + tipodocumento.prefijo + Movimiento.ndocumentodevo : ""}
                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'left', fontSize: '13px', color: 'black' }}>
                                        {"Observaciones:"}<br />
                                        {tipodocumento.camdescpp.replace(' ', tabespacio) + tabespacio + Movimiento.observacion}

                                    </Col>

                                </Row>
                                <Row>
                                    <Col sm={12} style={{ fontSize: '13px', color: 'black' }}>
                                        <Table size="sm">
                                            <thead>

                                            </thead>
                                            <tbody >
                                                <tr  >
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total Ventas:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(bruto)}</th>
                                                </tr>
                                                {descuento != 0 &&
                                                    <tr>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Descuento:"}</th>
                                                        <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(descuento)}</th>
                                                    </tr>
                                                }

                                                {(tipodocumento.ivasn != "no" && iva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(iva)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.imptosn != "no" && consumo != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Impo Consumo:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(consumo)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.rete != "no" && retefuente != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Fte:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(retefuente)}</th>
                                                </tr>

                                                }
                                                {(tipodocumento.reteiva != "no" && reteiva != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Iva:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteiva)}</th>
                                                </tr>

                                                }

                                                {(tipodocumento.ica != "no" && reteica != 0) && <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Rte Ica:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(reteica)}</th>
                                                </tr>

                                                }

                                                {/* <tr>
    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Abono:"}</th>
    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{fm2.from(totalsaldoclientehoy())}</th>

</tr> */}
                                                <tr>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{"Total:"}</th>
                                                    <th style={{ textAlign: 'right', color: 'black', fontWeight: 'normal' }}>{tabespacio + fm2.from(total)}</th>
                                                </tr>


                                            </tbody>
                                        </Table>


                                    </Col>

                                </Row>

                                <Row style={{ marginTop: '40px' }}>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black', width: '300px', color: 'white' }}>

                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ border: '1px solid black' }}>

                                    </Col>
                                    <Col sm={3} >

                                    </Col>
                                </Row >
                                <Row >
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '13px', color: 'black' }} >
                                        {JSON.parse(user).empresa.empresa.nombre}<br />
                                    </Col>
                                    <Col sm={1} >

                                    </Col>
                                    <Col sm={2} style={{ fontSize: '13px', color: 'black' }} >
                                        ACEPTADA, FIRMA Y SELLO
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', fontWeight: 'normal', color: 'black' }}>
                                        <br />
                                        {tipodocumento.num_res != "" && "Resolución DIAN No. " + tipodocumento.num_res + " de " + tipodocumento.inicresolu + ".Rango autorizado del " + tipodocumento.prefijo + tipodocumento.rango_res_i + " al " + tipodocumento.prefijo + tipodocumento.rango_res_f + ".Valido hasta " + tipodocumento.vencresolu}


                                    </Col>
                                    <Col sm={12} style={{ textAlign: 'center', fontSize: '13px', color: 'black' }}>
                                        {tipodocumento.tipodoc == "factura" ? "Gracias Por Su Compra" : tipodocumento.tipodoc == "compra" ? "Gracias por su Venta" : ""}<br />
                                        {"Generado por Solifactin S.A.S"}<br />
                                        {"Tenemos las mejores soluciones a su alcance"}<br />
                                        {"www.factin.com.co"}<br />
                                        {"Cel: 3187653564 - 3218561024"}<br />


                                    </Col>

                                </Row>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizarenviocorreo(true)
                            setcorreosenvio(tercero.email)
                        }}>Enviar Correo</Button>
                        <Button size="sm" color="primary" onClick={() => {

                            previsualizarpdfelectronica()
                            // setprevisualizarelectronica(false)
                        }}>PDF</Button>
                        <Button size="sm" color="primary" onClick={() => {
                            setprevisualizar(false)

                        }}>Cerrar</Button>
                    </ModalFooter>
                </Modal>

            }
            <Modal size="lg" isOpen={abrirmediosdepago}>
                <ModalHeader>
                    Caja
                </ModalHeader>
                <ModalBody>
                    <Row >
                        <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                            <InputGroupText className="labelmovtotalmediopago">El Total es:</InputGroupText>
                            <Input disabled className="inputmovtotal" value={fm3.from(total)} />
                        </InputGroup>
                        <InputGroup size="sm" >
                            <InputGroupText className="labelmovtotalmediopago">Efectivo:</InputGroupText>
                            <Input onFocus={(e) => {
                                e.target.value = (Movimiento.efectivo + "").replace(/\,/g, '')
                            }} onBlur={(e) => {
                                e.target.value = fm.from(Number(Movimiento.efectivo + ""))
                            }} value={Movimiento.efectivo + ""} name="efectivo" onChange={handleChangeformapago} className="inputmovtotal" />
                        </InputGroup>
                        {
                            Mediodepagos.map((dato) => {
                                return <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                                    <InputGroupText className="labelmovtotalmediopago">{dato.nombre}:</InputGroupText>
                                    <Input onFocus={(e) => {
                                        e.target.value = (dato.valor + "").replace(/\,/g, '')
                                    }} onBlur={(e) => {
                                        e.target.value = fm.from(Number(dato.valor))
                                    }} value={dato.valor} name={dato.codigo} onChange={handleChangeformapago} className="inputmovtotal" />
                                </InputGroup>
                            })
                        }

                        <InputGroup size="sm">
                            <InputGroupText className="labelmovtotalmediopago">Descuento:</InputGroupText>
                            <Input disabled={tipodocumento.tipodoc == "factura" && tipodocumento.mdevolucio == "si"} onFocus={(e) => {
                                e.target.value = (Movimiento.descuentoc + "").replace(/\,/g, '')
                            }} onBlur={(e) => {
                                e.target.value = fm.from(Number(Movimiento.descuentoc))
                            }} className="inputmovtotal" value={Movimiento.descuentoc} name="descuentoc" onChange={handleChangeformapago} />
                        </InputGroup>
                        <InputGroup size="sm">
                            <InputGroupText className="labelmovtotalmediopago">Devolucion:</InputGroupText>
                            <Input onFocus={(e) => {
                                e.target.value = (Movimiento.fletes + "").replace(/\,/g, '')
                            }} onBlur={(e) => {
                                e.target.value = fm.from(Number(Movimiento.fletes))
                            }} disabled className="inputmovtotal" value={fm.from(Number(Movimiento.fletes + ""))} onChange={handleChangeformapago} />
                        </InputGroup>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {
                        // cambiardatosconimpuesto()
                        setabrirabrirmediosdepago(false)
                        // var efec = total - Number(Movimiento['cheque'] + "") - Number(Movimiento['debito'] + "") - Number(Movimiento['credito'] + "") - Number(Movimiento['descuentoc'] + "")
                        // if (efec > 0) {
                        //     Swal.fire(
                        //         'Opps!',
                        //         'Falta Dinero',
                        //         'error'
                        //     )
                        // } else {

                        // }
                        setesperando(true)

                        terminarVenta()


                    }}>Listo</Button>
                    <Button size="sm" color="danger" onClick={() => {
                        // cambiardatosconimpuesto()
                        setabrirabrirmediosdepago(false)

                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal size="lg" isOpen={visualizartotales}>

                <ModalBody>
                    <Row >
                        <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                            <InputGroupText className="labelmovtotalmediopago">Propina:</InputGroupText>
                            <Input disabled className="inputmovtotal" value={fm3.from(Movimiento.convoridoc)} />
                        </InputGroup>
                        <InputGroup size="sm" style={{ marginTop: '-6px' }}>
                            <InputGroupText className="labelmovtotalmediopago">El Total es:</InputGroupText>
                            <Input disabled className="inputmovtotal" value={fm3.from(total)} />
                        </InputGroup>





                    </Row>
                </ModalBody>
                <ModalFooter>

                    <Button size="sm" color="danger" onClick={() => {
                        // cambiardatosconimpuesto()
                        setvisualizartotales(false)

                    }}>Cerrar</Button>
                </ModalFooter>
            </Modal>
            <BuscarMovimiento tituloencabezado={"Buscar Movimientos"} filtrar={(coincidencia) => { buscartodoslosmovimientos2(coincidencia) }} busqueda={busquedaMovimientos} encabezado={["Concepto", "Consecutivo", "Tercero", "Nombre", "Fecha", "Doc 1", "Total"]} sub={"faencmovi"} codigo={"concepto"} nombre={"ndocumento"} dato={"cliente"} dato2={"nombrecli"} dato3={"fecha"} dato5={"documento1"} dato6={"total"} verModal={verModalMovimientos} cerrarModal={() => { cerrarModalMovimientos() }} seleccionar={(seleccionado) => {
                seleccionarMovimientos(seleccionado)
                setVerModalMovimientos(false)
            }} />
            <Modal size="lg" isOpen={previsualizarenviocorreo}>
                <ModalHeader>
                    Correo
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <Input value={correosenvio} onChange={(dato) => {
                                setcorreosenvio(dato.target.value)
                            }}></Input>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={() => {
                        enviocorreofacturaelectronica()
                        setprevisualizarenviocorreo(false)
                        setcorreosenvio("")
                    }}>Enviar</Button>
                    <Button size="sm" color="primary" onClick={() => {
                        setprevisualizarenviocorreo(false)
                        setcorreosenvio("")
                    }}>cerrar</Button>
                </ModalFooter>
            </Modal>
        </Fragment>
    );
};

export default CurrentTab;
