import { Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row, Table, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import Swal from 'sweetalert2'
import DataTable from 'react-data-table-component';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "../context/UserProvider";
import Esperando from '../componentes/Esperando'
import BarradeCarga from '../componentes/BarradeCarga'

// import Filtro from '../../../componentes/filtro';
import * as XLSX from "xlsx"
import { FormatMoney } from 'format-money-js'
const tabespacio = '\u00A0';

const modeloInicio = [{
    referencia: "",
    descripcion: "",
    bodega: "",
    existencia: ""

}]

const ReporteVenta = (props) => {
    const [fechaInicio, setFechaInicio] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechaFin, setFechaFin] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [fechahoy, setfechahoy] = useState(new Date().getFullYear() + "-" + ("0" + (new Date().getMonth() + 1)).slice(-2) + "-" + ("0" + new Date().getDate()).slice(-2));
    const [esperando, setesperando] = useState(false);
    const [barracarga, setbarracarga] = useState(false);
    const [tiempocarga, settiempocarga] = useState(0);

    const [pendiente, setPendiente] = useState(false);
    const [concepto, setconcepto] = useState(false);
    const [tercero, settercero] = useState(false);
    const [vendedor, setvendedor] = useState(false);
    const [caja, setcaja] = useState(false);
    const [formapago, setformapago] = useState(false);
    const [filtros, setfiltros] = useState(false);
    const [ventas, setVentas] = useState(modeloInicio)
    const [ventasconceptos, setventasconceptos] = useState(modeloInicio)
    const [ventasterceros, setventasterceros] = useState(modeloInicio)
    const [ventasvendedor, setventasvendedor] = useState(modeloInicio)
    const [ventascaja, setventascaja] = useState(modeloInicio)
    const [ventasformapago, setventasformapago] = useState(modeloInicio)
    const { user, cerrarSession } = useContext(UserContext);
    const [fm, setfm] = useState(new FormatMoney({ symbol: '', decimals: 2 }));
    const [fm3, setfm3] = useState(new FormatMoney({ symbol: '$ ', decimals: 2 }));
    const [tipodocumentoinicioseleccionado, settipodocumentoinicioseleccionado] = useState("-");
    const [vendedorinicioseleccionado, setvendedorinicioseleccionado] = useState("-");
    const [terceroinicioseleccionado, setterceroinicioseleccionado] = useState("-");
    const [cajainicioseleccionada, setcajainicioseleccionada] = useState("-");
    const [tipodocumentofinseleccionado, settipodocumentofinseleccionado] = useState("-");
    const [vendedorfinseleccionado, setvendedorfinseleccionado] = useState("-");
    const [tercerofinseleccionado, settercerofinseleccionado] = useState("-");
    const [cajafinseleccionada, setcajafinseleccionada] = useState("-");
    const [mediosdepago, setmediosdepago] = useState(modeloInicio)
    const [tiposdedocumentoseleccionados, settiposdedocumentoseleccionados] = useState([])
    const [usuario, setDataUser] = useState(modeloInicio)
    const columns = [
        {
            name: 'Codigo',
            selector: row => row.codigo,
            sortable: true,
        },
        {
            name: 'descripcion',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => {
                            var lista = tiposdedocumentoseleccionados.map((dato) => {
                                return dato
                            })

                            if (lista.includes(row.codigo)) {
                                lista = lista.filter(p => p != row.codigo)
                            } else {
                                lista.push(row.codigo)
                            }
                            settiposdedocumentoseleccionados(lista)
                        }}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                </>
            ),
        },
    ];


    const conditionalRowStyles2 = [

        {
            when: row => tiposdedocumentoseleccionados.includes(row.codigo),
            style: {
                backgroundColor: '#188BAD',
                color: 'white'

            }
        }
    ];
    useEffect(() => {
        let dt = JSON.parse(user)
        setDataUser(dt)
        buscar();
    }, [])



    const buscar = async () => {

        try {


            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/Fatipodocapi/solocontable/' + '?llave=' + dt.usuario.llaveempresa + '');

            if (response.ok) {
                let data = await response.json()


                setVentas(data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };
    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const rrr = () => {
        let dt = JSON.parse(user)
        const api = fetch(props.servidor + '/ContabilidadApi/reconstruir/' + fechaInicio + '/' + fechaFin + '?llave=' + dt.usuario.llaveempresa + '', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify({
                datos: tiposdedocumentoseleccionados,
            })
        })
            .then((response) => {
                return response.ok ? response.json() : Promise.reject(response);
            })
            .then(async (dataJson) => {
                var fff = dataJson
            }).catch((error) => {

            })
    }

    const ReconstruirTesoreria = async () => {
        setesperando(true)
        try {

            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    datos: tiposdedocumentoseleccionados,
                }),
            };

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ContabilidadApi/reconstruirtesoreria/' + fechaInicio + '/' + fechaFin + '?llave=' + dt.usuario.llaveempresa + '', requestOptions);

            if (response.ok) {
                let data = await response.json()
                setesperando(false)

                Swal.fire(
                    'Exito!',
                    'Reconstruccion Exitosa',
                    'success'
                );
                setesperando(false)

            } else {
                console.error('Error en la solicitud:', response.status, response.statusText);
                setesperando(false)

                // Leer el cuerpo de la respuesta si está disponible
                try {
                    const errorData = await response.json();
                    console.error('Detalles del error:', errorData);
                    Swal.fire(
                        'Opps!',
                        errorData.mensaje,
                        'error'
                    );
                } catch (error) {
                    console.error('No se pudo leer el cuerpo de la respuesta:', error);
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos: ' + error,
                        'error'
                    );
                    setesperando(false)

                }

                // Aquí puedes manejar el error de acuerdo a tus necesidades
            }
        } catch (error) {
            console.log(error)
            setesperando(false)

        }
    }
    const Reconstruir = async () => {
        try {

            let requestOptions2 = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    datos: tiposdedocumentoseleccionados,
                }),
            };
            let dt22 = JSON.parse(user)
            let response2 = await fetch(props.servidor + '/ContabilidadApi/buscarcantidadmovimientosreconstruccion/' + fechaInicio + '/' + fechaFin + '?llave=' + dt22.usuario.llaveempresa + '', requestOptions2);

            if (response2.ok) {
                let data2 = await response2.json()
                console.log(data2.cantidad)
                setbarracarga(true)
                if (data2.cantidad<200) {
                settiempocarga(data2.cantidad * 3)
                    
                }else{
                    settiempocarga(data2.cantidad * 4)

                }
            }
        } catch (error) {
            console.log(error)
            return
        }

        try {

            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({
                    datos: tiposdedocumentoseleccionados,
                }),
            };

            let dt = JSON.parse(user)
            let response = await fetch(props.servidor + '/ContabilidadApi/reconstruir/' + fechaInicio + '/' + fechaFin + '?llave=' + dt.usuario.llaveempresa + '', requestOptions);

            if (response.ok) {
                let data = await response.json()
                setbarracarga(false)
                settiempocarga(0)

                Swal.fire(
                    'Exito!',
                    'Reconstruccion Exitosa',
                    'success'
                );
                setbarracarga(false)
                settiempocarga(0)

            } else {
                console.error('Error en la solicitud:', response.status, response.statusText);
                setbarracarga(false)
                settiempocarga(0)

                // Leer el cuerpo de la respuesta si está disponible
                try {
                    const errorData = await response.json();
                    console.error('Detalles del error:', errorData);
                    Swal.fire(
                        'Opps!',
                        errorData.mensaje,
                        'error'
                    );
                } catch (error) {
                    console.error('No se pudo leer el cuerpo de la respuesta:', error);
                    Swal.fire(
                        'Opps!',
                        'No pudieron cargar los movimientos: ' + error,
                        'error'
                    );
                    setbarracarga(false)
                    settiempocarga(0)

                }

                // Aquí puedes manejar el error de acuerdo a tus necesidades
            }
        } catch (error) {
            console.log(error)
            setbarracarga(false)
            settiempocarga(0)

        }
    }
    const totaltotal = () => {
        var tot = 0
        for (let i = 0; i < ventas.length; i++) {
            const element = ventas[i];
            tot = tot + element.total
        }
        return tot
    }
    const totalporconcepto = (codigo) => {
        var tot = 0
        var conceptos = ventas.filter(p => p.concepto == codigo)
        for (let i = 0; i < conceptos.length; i++) {
            const element = conceptos[i];
            tot = tot + element.total
        }
        return tot
    }
    return (
        <>
            <Row>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Inicio:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaInicio}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e.target.value)}></Input>

                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>Fecha Fin:</Label>
                        <Input bsSize="sm" type="date" className="form-control form-control-sm"
                            selected={fechaFin}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e.target.value)}></Input>


                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="primary" size="sm" block onClick={buscar}>
                            <i className="fa fa-search" aria-hidden="true"></i> Buscar
                        </Button>
                    </FormGroup>
                </Col>

                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={Reconstruir}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Reconstruir
                        </Button>
                    </FormGroup>
                </Col>
                <Col sm={2}>
                    <FormGroup>
                        <Label style={{ fontSize: '13px' }}>{tabespacio}</Label>
                        <Button color="success" size="sm" block onClick={ReconstruirTesoreria}>
                            <i className="fa fa-file-excel" aria-hidden="true"></i> Reconstruir Tesoreria
                        </Button>
                    </FormGroup>
                </Col>

            </Row>

            <Row>
                <Col sm={12}>
                    <Card>

                        <CardBody>
                            <Row>

                                <Col sm="12">
                                    <DataTable
                                        progressPending={pendiente}
                                        columns={columns}
                                        data={ventas}
                                        customStyles={customStyles}
                                        pagination
                                        paginationComponentOptions={paginationComponentOptions}
                                        conditionalRowStyles={conditionalRowStyles2}
                                    />


                                </Col>

                            </Row>

                        </CardBody>
                    </Card>
                </Col>
                <Esperando verModal={esperando} />
                <BarradeCarga verModal={barracarga} tamaño="sm" tiempo={tiempocarga} />

            </Row>


        </>
    )
}

export default ReporteVenta;