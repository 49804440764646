import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Progress } from "reactstrap";

const Buscar = (props) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (props.verModal) {
            setProgress(0); // Reinicia el progreso cada vez que el modal se abre
            const interval = setInterval(() => {
                setProgress((prevProgress) => {
                    if (prevProgress >= 100) {
                        clearInterval(interval); // Detén el intervalo al completar
                        return 100;
                    }
                    return prevProgress + (100 / props.tiempo); // Incremento proporcional a N segundos
                });
            }, 1000); // Actualización cada segundo
            return () => clearInterval(interval); // Limpia el intervalo al desmontar
        }
    }, [props.verModal, props.tiempo]);

    return (
        <>
            <Modal
                size={props.tamaño === "sm" ? "sm" : ""}
                isOpen={props.verModal}
            >
                <ModalHeader style={{ backgroundColor: '#188BAD', color: 'white' }}>
                    Cargando...
                </ModalHeader>
                <ModalBody>
                    <Progress value={progress}>{Math.round(progress)}%</Progress>
                    {progress >= 100 ? (
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            ¡Carga completada!
                        </div>
                    ) : (
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            Por favor, espera...
                        </div>
                    )}
                </ModalBody>
            </Modal>
        </>
    );
};

export default Buscar;
